import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MUIDataTable from "mui-datatables";
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';
import {LiveHelp,TransferWithinAStation,SportsHandball,AccessibilityNew,SmsFailed,DirectionsRun} from '@material-ui/icons/'
import Paper  from '@material-ui/core/Paper';
import Plot from 'react-plotly.js';
import Grid from "@material-ui/core/Grid";

import {resident_data} from '../../../../data/resident_data';

/*
Resdient Notes has a table of the resident notes summary
*/

// style the component
const useStyles = makeStyles({
  root: {
    margin: 'auto',
    padding: 10,
    '& a':{
        textDecoration: 'none',
        color: '#000',
    },
    '& tr:hover .AlertIconWrapper':{
        color: '#f00',
        borderRadius:6,
        border:'1px solid #c00',
        backgroundColor: 'rgba(230,200,200, .3)',
    },
    '& .svg-container':{
        margin: '0 auto 10px !important',
    },
    '& > div > div':{
        margin: 'auto',
    }
  },
  AlertIconWrapper:{
    padding: '2px 5px',
    border:'1px solid #ccc',
    borderRadius:6,
    
  },
  AlertIcon:{
    fill: '#c00',
    verticalAlign: 'middle',
    minWidth: 36,
    minHeight: 36,
    width: 36,
    height: 36,
    padding: 2,
    marginLeft: 8,
    marginRight: 10,
  },
  AlertIconTitle:{
    fontWeight: 'bold',
    color: '#c00',
    display: 'inline-block',
    margin: '0 3px',
    verticalAlign: 'middle',
    fontSize: '1.2em',
  }
});

// provide table interface for active alerts
export default function ResidentActivityDetails(props) {
  const classes = useStyles();
  const selectedActivity = {props};
  

  function ConvertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr)
  
    let result =  array.map(it => {
      return Object.values(it)
    })

	return result
  }

  // load the data from the chart sub section
  // replace this with a call to api via ajax
  const data = resident_data.activity.activity_details.table[props.selectedActivity].data;
  
  

  // list of columns for datatable
   const columns = resident_data.activity.activity_details.table[props.selectedActivity].headers;

    // set options for datatable
   const options = {
    filterType: 'checkbox',
  };

  // render the component
  return (
   <div className={classes.root}>

    <h2>Activity Details: {props.selectedActivity}</h2>
    <hr/>
   
    <Plot style={{margin:'auto'}}
        data={[
          {
            x: [1, 2, 3,4,5],
            y: [2, 6, 3,2,4],
            type: 'scatter',
            mode: 'lines+markers',
            marker: {color: '#6c7'},
          },
          {type: 'bar', x: [1, 2, 3,4,5], y: [2, 6, 3,2,4], marker: {color: '#8ac'}},
        ]}
        layout={ {width: 650, height: 300} }
      />

    <MUIDataTable
    title={"Vitals Details"}
    data={data}
    columns={columns}
    options={options}
    /> 
   </div>
  );
}



/*

   // format name column data
  function ResidentName(value){
    
  let result =( <Link to='/residents/chart' > <h3>{value}</h3> </Link> );

    return result
  }

  // provide dynamic icon for placement based on icon name
   function DynamicIcon(icon){
    const icons = {
      'Leave Room': TransferWithinAStation,
      'Leave Building': DirectionsRun,
      'Call Help': SmsFailed,
      'Standing Up': AccessibilityNew,
      'Fall': SportsHandball,
    };

    //let  DynamicIcon = icons[{icon}]
    let DynamicIcon = icons[icon];
    let result = ( 
        <React.Fragment>
            <Link to='/residents/chart'>
            <div className={classes.AlertIconWrapper + ' AlertIconWrapper '}>
                <DynamicIcon className={classes.AlertIcon}/>
                <span className={classes.AlertIconTitle}>
                    {icon}
                </span>
            </div>
            </Link>
        </React.Fragment>
    )
    return result
  }

*/

