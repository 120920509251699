import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {BrowserRouter as Router , Switch, Route, Link} from 'react-router-dom';


import { Formik, Form } from "formik";
import * as yup from "yup";

let VitalsSchema = yup.object().shape({
  VitalType: yup.string().required("This field is required."),
  VitalValue: yup.string().required("This field is required."),
  RecordDate: yup.string().required("This field is required."),
 
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    '& h2, h3, h4':{
      textAlign: 'left !important',
      color: '#999 !important',
    }
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  
}));

export default function FormAddVitalRecord(props) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="lg">
      <Link to={'/residents/'}  >
          <Button color="primary">Back to Vitals Admin</Button>
      </Link>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Vitals Form
        </Typography>
        <Formik
          initialValues={{
            VitalType: "",
            VitalValue: "",
            RecordDate: "",
          }}
          validationSchema={VitalsSchema}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>

                {/*
                VitalsType	VitalsDesc	icon
                */}
              <Grid item xs={12}><h3>Vitals Info</h3></Grid>
                
              <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.VitalType && touched.VitalType}
                    autoComplete="VitalType"
                    name="VitalType"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="VitalType"
                    label="VitalsType"
                    autoFocus
                    helperText={
                      errors.VitalType && touched.VitalType
                        ? errors.VitalType
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.VitalValue && touched.VitalValue}
                    autoComplete="VitalValue"
                    name="VitalValue"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="VitalValue"
                    label="VitalValue"
                    autoFocus
                    helperText={
                      errors.VitalValue && touched.VitalValue
                        ? errors.VitalValue
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.RecordDate && touched.RecordDate}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="RecordDate"
                    label="icon"
                    name="RecordDate"
                    autoComplete="RecordDate"
                    helperText={
                      errors.RecordDate && touched.RecordDate
                        ? errors.RecordDate
                        : null
                    }
                  />
                </Grid>
               

              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
