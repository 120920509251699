import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import DemoTable from './DemoTable'

const admin_menu_data = [
    {
        title: 'Personel',
        link:'',
        desc:'',
        data:[

        ]
    },
    {  
      title: 'Calendar',
      link:'',
      desc:'',
    },
    {
        title: 'Electrcity',
        link:'',
        desc:'',
    },
    {
        title: 'Water',
        link:'',
        desc:'',
    },
    {
        title: 'HVAC',
        link:'',
        desc:'',
    },
    {
        title: 'Security',
        link:'',
        desc:'',
    },
    {
        title: 'Telcom',
        link:'',
        desc:'',
    },
   
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    '& h1': { color: '#8795a5',},
    '& header': {
      background: '#ffffff',
      background: '-moz-linear-gradient(top,  #ffffff 0%, #e6eff4 100%)',
      background: '-webkit-linear-gradient(top,  #ffffff 0%,#e6eff4 100%)',
      background: 'linear-gradient(to bottom,  #ffffff 0%,#e6eff4 100%)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#e6eff4",GradientType=0 )',
      color: '#8795a5',
    },
  },
  bar: {
    background: '#5592de',
  },
  tabpanel:{
      '& h2':{
        color: '#000000',
        textAlign:'left',
      }
  }
}));

export default function FacilitySection() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" >
        <Tabs 
        textColor="#ffffff"
        TabIndicatorProps={{
            style: {
              backgroundColor: "#88bbee"
            }
          }}
        value={value} onChange={handleChange} aria-label="tabs sample: residents">
        {admin_menu_data.map((item, index) =>
            <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab>
            )}
          
        </Tabs>
      </AppBar>
      <h1>FACILITY SECTION</h1>
      {/*
      <div className={classes.tabpanel}>
      <TabPanel value={value} index={0} >
        <h2>Residents</h2>
        <DemoTable admin_section='residents' />
      </TabPanel>
      
      <TabPanel value={value} index={1}>
        <h2>User</h2>
        <DemoTable admin_section='users' />
      </TabPanel>
      
      <TabPanel value={value} index={2}>
      <h2>Clinicians</h2>
        <DemoTable admin_section='clinicians' />
      </TabPanel>
      
      <TabPanel value={value} index={3}>
      <h2>Pharmacies</h2>
        <DemoTable admin_section='pharmacies' />
      </TabPanel>
      
      <TabPanel value={value} index={4}>
      <h2>Prescriptions</h2>
        <DemoTable admin_section='prescriptions' />
      </TabPanel>
      
      <TabPanel value={value} index={5}>
      <h2>Labs</h2>
        <DemoTable admin_section='labs' />
      </TabPanel>
      
      <TabPanel value={value} index={6}>
      <h2>Activity</h2>
        <DemoTable admin_section='activities' />
      </TabPanel>
      
      <TabPanel value={value} index={7}>
      <h2>Vitals</h2>
        <DemoTable admin_section='vitals' />
      </TabPanel>
      </div>
      */}

    </div>
  );
}