import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {BrowserRouter as Router , Switch, Route, Link} from 'react-router-dom';


import { Formik, Form } from "formik";
import * as yup from "yup";

let ActivitiesSchema = yup.object().shape({
  ActivityType: yup.string().required("This field is required."),
  ActivityDesc: yup.string().required("This field is required."),
  ActivityIcon: yup.string().required("This field is required."),
  
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    '& h2, h3, h4':{
      textAlign: 'left !important',
      color: '#999 !important',
    }
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  
}));

export const AdminActivityForm = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="lg">
     
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Activities Form
        </Typography>
        <Formik
          initialValues={{
            ActivityType: "",
            ActivityDesc: "",
            ActivityIcon: "",
          }}
          validationSchema={ActivitiesSchema}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>

                {/*
                ActivityType	ActivityDesc	icon
                */}
              <Grid item xs={12}><h3>Activity Info</h3></Grid>
                
              <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.ActivityType && touched.ActivityType}
                    autoComplete="ActivityType"
                    name="ActivityType"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="ActivityType"
                    label="ActivityType"
                    autoFocus
                    helperText={
                      errors.ActivityType && touched.ActivityType
                        ? errors.ActivityType
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.ActivityDesc && touched.ActivityDesc}
                    autoComplete="ActivityDesc"
                    name="ActivityDesc"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="ActivityDesc"
                    label="ActivityDesc"
                    
                    helperText={
                      errors.ActivityDesc && touched.ActivityDesc
                        ? errors.ActivityDesc
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.ActivityIcon && touched.ActivityIcon}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="ActivityIcon"
                    label="ActivityIcon"
                    name="ActivityIcon"
                    autoComplete="ActivityIcon"
                    helperText={
                      errors.ActivityIcon && touched.ActivityIcon
                        ? errors.ActivityIcon
                        : null
                    }
                  />
                </Grid>
               

              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
