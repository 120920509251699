import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MUIDataTable from "mui-datatables";
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';
import {LiveHelp,TransferWithinAStation,SportsHandball,AccessibilityNew,SmsFailed,DirectionsRun,CheckCircle} from '@material-ui/icons/'
import Paper  from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import ResidentDefaultF from './../../images/residents/resident_avatar_female.png'
import ResidentDefaultM from './../../images/residents/resident_avatar_male.png'
import ResidentDefault from './../../images/residents/resident_avatar.png'

import Woman01 from './../../images/residents/woman_01.png'
import Woman02 from './../../images/residents/woman_02.png'
import Woman03 from './../../images/residents/woman_03.png'
import Woman04 from './../../images/residents/woman_04.png'
import Woman05 from './../../images/residents/woman_05.png'
import Woman06 from './../../images/residents/woman_06.png'
import Woman07 from './../../images/residents/woman_07.png'

/*

Residents list table

- change resident link to include ID, use ID as lookup

- create json view models for the resident data for the detail pages to lookup

- create an ajax request for loading the json data as placeholder for API call

*/

// style the component
const useStyles = makeStyles({
  root: {
    margin: 'auto',
    padding: 10,
    '& a':{
        textDecoration: 'none',
        color: '#000',
    },
    '& tr:hover ':{
        cursor:'pointer',
    },
    '& tr:hover .AlertIconWrapper':{
        borderRadius:6,
        border:'1px solid',
    },
    '& tr:hover .AlertIconWrapper.icon_red':{
        color: '#f00',
        border:'1px solid #c00',
        backgroundColor: 'rgba(230,200,200, .3)',
    },
    '& tr:hover .AlertIconWrapper.icon_green':{
        color: '#090',
        border:'1px solid #4c6',
        backgroundColor: 'rgba(100,200,120, .3)',
    },
    '& .MuiTableCell-root':{
        padding: '6px 6px',
    },
  },
  AlertIconWrapper:{
    padding: '2px 5px',
    border:'1px solid #ccc',
    borderRadius:6,
    boxShadow: '1px 2px 2px #ccc',
    whiteSpace: 'nowrap',
    maxWidth: 220,
  },
  AlertIcon:{
    verticalAlign: 'middle',
    minWidth: 36,
    minHeight: 36,
    width: 36,
    height: 36,
    padding: 2,
    marginLeft: 8,
    marginRight: 10,
    '&.icon_red':{
        fill: '#c00',
    },
    '&.icon_green':{
        fill: '#4a6',
    },
  },
  AlertIconTitle:{
    fontWeight: 'bold',
    display: 'inline-block',
    margin: '0 3px',
    verticalAlign: 'middle',
    fontSize: '1.2em',
    '&.icon_red':{
        color: '#c00',
    },
    '&.icon_green':{
        color: '#4a6',
    },
  },
  ResidentPhoto:{
      maxWidth: 50,
      maxHeight: 50,
      display:'block',
      '& img':{
          width: '100%',
      }
  }
});

// provide table interface for active alerts
export default function ResidentsDatatable(props) {
    const [selectedResidentID, setSelectedResidentID] = React.useState(props.residentID);
    
    const {setResidentID, apiData, isLoaded} = props
    const [photoIndex, setPhotoIndex] = React.useState(0);
    

    // style the resident table
  const classes = useStyles();
  const data = [
    [Woman01,"Meena","R100","Fall","Fall Risk","Assistance","F","1928-12-04","8581234567"],
    [Woman02,"Imogene","R101","Leave Room","Fall Risk","Assistance","F","1940-05-15","7609295555"],
    [Woman03,"Emily","R102","Call Help","Emergency","Assistance","F","1946-12-31","7602315162"],
    [Woman04,"Smrithi","R103","Standing Up","Fall Risk","Assistance","F","1950-01-01","9195732132"],
    [Woman05,"Gilda","R104","OK","-","Assistance","F","1950-12-12","7601052999"],
    [Woman06,"Janet","R105","OK","-","Assistance","F","1940-03-25","8581293600"],
    [Woman07,"Erika","R106","OK","-","Assistance","F","0000-00-00","7603442121"],
   ];

   const photo_resident_map = {
    "R100": Woman01,
    "R101": Woman02,
    "R102": Woman03,
    "R103": Woman04,
    "R104": Woman05,
    "R105": Woman06,
    "R106": Woman07,
   }
   const resident_photos = [
    Woman01,
    Woman02,
    Woman03,
    Woman04,
    Woman05,
    Woman06,
    Woman07,
   ]
   // Data Table Sample Data
   /*
   const data = [
    ["Meena","R100","Fall","Fall Risk","Assistance","F","1928-12-04","8581234567"],
    ["Imogene","R101","Leave Room","Fall Risk","Assistance","F","1940-05-15","7609295555"],
    ["Emily","R102","Call Help","Emergency","Assistance","F","1946-12-31","7602315162"],
    ["Smrithi","R103","Standing Up","Fall Risk","Assistance","F","1950-01-01","9195732132"],
    ["Gilda","R104","OK","-","Assistance","F","1950-12-12","7601052999"],
    ["Janet","R105","OK","-","Assistance","F","1940-03-25","8581293600"],
    ["Erika","R106","OK","-","Assistance","F","0000-00-00","7603442121"],
   ];
    */
   const handleResidentHover = (event, residentID) => {
    //history.push(`/residents/${tabNameToIndex[newValue]}`);
    //setSelectedTab(newValue);
    //setSection(admin_menu_data[newValue]['link']);
  }
  const handleResidentSelect = (event, residentID) => {
    //history.push(`/residents/${tabNameToIndex[newValue]}`);
    //setSelectedTab(newValue);
    //setSection(admin_menu_data[newValue]['link']);
  }
  

// handle selected resident state
// there, on the table rows hover -> setHoveredUser ,
// then table can access hovereduserid for the duynamic proerties of any element
// and click to setSelectedUser in parent

//const [selectedUser, setSelectedUser] = React.useState(indexToTabName[page]);

   // create new hover method for row
   // on hover -> set residentid for the dynam,ic proerties

   // create new click method for row
   // on click -> set parent residnet id state and go to the corresponding subpanel

 // format name column data
 let updatePhotoIndex = (function () {
    var counter = 0;
    return function () {counter += 1; return counter}

  })();

 function ResidentPhoto(value, tableMeta ){
     
    if (value == 'avatar.png'){
        //let result =( <div className={classes.ResidentPhoto}> <img src={ResidentDefault}/> </div> );
        //let counter = updatePhotoIndex();
        //console.log('> counter',counter)
        let image = photo_resident_map[tableMeta.rowData[2]];
        if (image){
            let result =( <div className={classes.ResidentPhoto}> <img src={image}/> </div> );
            return result
        }else{
            let result =( <div className={classes.ResidentPhoto}> <img src={ResidentDefault}/> </div> );
            return result
        }
     }else{
        let result =( <div  className={classes.ResidentPhoto}> <img src={value}/> </div> );
        return result
     }
    
  }

  const residentNameToResidentid = {
    'Meena': "R100",
    "Imogene":"R101",
    "Emily":"R102",
    "Smrithi":"R103",
    "Gilda":"R104",
    "Janet":"R105",
    "Erika":"R106",
  }

   // format name column data
  function ResidentName(value){

    // onClick -> use the setResidentID method from parent to update the selected resident id state,
    // and set the residentid 
    //<Link to={`/residents/${ residentNameToResidentid[value]}/chart`}>
    let result =(  <h3>{value}</h3> );
    return result
  }

  // provide dynamic icon for placement based on icon name
   function AlertIcon(icon, tableMeta){
    const icons = {
      'Leave Room': TransferWithinAStation,
      'Leave Building': DirectionsRun,
      'Call Help': SmsFailed,
      'Standing Up': AccessibilityNew,
      'Fall': SportsHandball,
      'OK': CheckCircle,
    };

    
    const resIcons = {
        'R100': 'Leave Room',
        'R101': 'Leave Building',
        'R102': 'Call Help',
        'R103': 'Standing Up',
        'R104': 'Fall',
        'R105': 'OK',
      };
      

    icon = resIcons[tableMeta.rowData[2]]
    if (!icon){
        icon = resIcons['R105'];
    }
    //let  DynamicIcon = icons[{icon}]
    //let AlertIcon = icons[icon];
    let AlertIcon = icons[icon];
    let icon_color = 'icon_red'
    if (icon === 'OK'){
        icon_color = 'icon_green'
    }

    let result = ( 
        <React.Fragment>
            {/*<Link to='/residents/chart'>*/}
            <div className={classes.AlertIconWrapper + ' AlertIconWrapper ' +icon_color}>
                <AlertIcon className={classes.AlertIcon + ' '+icon_color}/>
                <span className={classes.AlertIconTitle +' ' +icon_color}>
                    {icon}
                </span>
            </div>
           {/* </Link>*/}
        </React.Fragment>
    )
    return result
  }
  
   // format name column data
   function ResidentRisks(value){
    let result =( <div> {value}</div> );
    return result
  }
   // format name column data
   function ResidentStatus(value){
    let result =( <div>{value}</div> );
    return result
  }
   // format name column data
   function ResidentGender(value){
    let result =( <div>{value}</div> );
    return result
  }
   // format name column data
   function ResidentDOB(value){
    let result =( <div>{value}</div> );
    return result
  }
   // format name column data
   function ResidentPhone(value){
    let result =( <div>{value}</div> );
    return result
  }

  // list of columns for datatable
  // Photo, Name, Room, Alert, Key Risk, Status, Actions
  /*
    {name: "Photo",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentPhoto(value))}},
    {name: "Name",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentName(value))}},
    "ID",
    {name: "Alert",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(AlertIcon(value))}},
    {name: "Key Risks",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentRisks(value))}},
    {name: "Status",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentStatus(value))}},
    {name: "Gender",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentGender(value))}},
    {name: "DOB",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentDOB(value))}},
    {name: "Phone",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentPhone(value))}},
  
  */
    const ResidentListData = [
        {
            "Photo": 'Woman01.png', 
            "Name": "Meena", 
            "ID": "R100", 
            "Alert": "Fall", 
            "Key Risks": "Fall Risk", 
            "Status": "Assistance", 
            "Gender": "F", 
            "DOB": "1928-12-04", 
            "Phone": "8581234567", 
        },
        {
            "Photo": 'Woman02.png', 
            "Name": "Diana", 
            "ID": "R23489723443", 
            "Alert": "Fall", 
            "Key Risks": "Fall Risk", 
            "Status": "Assistance", 
            "Gender": "F", 
            "DOB": "1930-07-04", 
            "Phone": "8581230099", 
        }
    ]

    // DataTable Column Config


   const columns = [
    {name: "Photo",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentPhoto(value,tableMeta))}},
    {name: "Name",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentName(value))}},
    "ID",
    {name: "Alert",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(AlertIcon(value,tableMeta))}},
    {name: "Key Risks",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentRisks(value))}},
    {name: "Status",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentStatus(value))}},
    {name: "Gender",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentGender(value))}},
    {name: "DOB",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentDOB(value))}},
    {name: "Phone",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(ResidentPhone(value))}},
   ];


   function handleCellClick(rowIndex, columnIndex, row, column) {
       setSelectedResidentID("R100");
        console.log('rowIndex: ' + rowIndex + ' columnIndex: ' + columnIndex);
    }

    function handleInfoClick() {
        console.log('handleInfoClick');
      }

    // set options for datatable
   const options = {
    onRowClick: (rowData, rowState) => {
        console.log('|state::',rowState, '|data::',rowData);
        props.handleSelectResident(rowData[2]);
        
      },

  };


  const displayPanel = ()=>{
    switch(isLoaded){
      case false:
       return <div style={{padding: 6}}>
       <CircularProgress  style={{margin: 4}}/>
       <div>loading data ..</div>
       </div>
       break;
    case true: 
      //return <div>{JSON.stringify(apiData,null,2)}</div>
      return (
            <MUIDataTable
                title={"Residents View"}
                data={apiData.data}
                //columns={apiData.headers}
                //data={data}
                columns={columns}
                options={options}
            /> 
      )
      break;
    }
  }

  

  // render the component
  return (
   <div className={classes.root}>

    <h2>Residents</h2>
    <Paper className={classes.screenshot} >
    {displayPanel()}
    </Paper>
   </div>
  );
}





