import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {Grid, Paper} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import DashCard from './DashCard';

const useStyles = makeStyles({
  root: {
    padding: 20,
    margin: 'auto',
    background: '#dde6ec',
    '& a':{
        textDecoration: 'none',
        color: '#000',
    }
  },
});

export default function MyAccountIndex(props) {
  const classes = useStyles();
  
    const cards_data = props.data;

  const cardset = cards_data.map(function(item, index) {
  //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
        if (index > 0) {
            return (
            <Grid item xs={12} md={4}>
                <DashCard {...item} />
            </Grid>
            )
        }
    }
  )

  return (
   <div>
       <h2>My Account Overview</h2>
       <Paper variant="outlined" className={classes.root} >
      <Grid container spacing={2}>
      {cardset}
       
        </Grid>
     </Paper>
   </div>
  );
}