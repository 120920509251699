import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MUIDataTable from "mui-datatables";
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';
import Paper  from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {LiveHelp,TransferWithinAStation,SportsHandball,AccessibilityNew,SmsFailed,DirectionsRun,CheckCircle} from '@material-ui/icons/'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import {resident_data} from '../../../../data/resident_data';
import DataLoader from '../../../common/DataLoader';
/*
Resident Alert:
- summary of latest alerts of the resident
*/


// style the component
const useStyles = makeStyles({
  root: {
    margin: 'auto',
    padding: 4,
    '& a':{
        textDecoration: 'none',
        color: '#000',
    },
    '& tr:hover .AlertIconWrapper':{
        color: '#f00',
        borderRadius:6,
        border:'1px solid #c00',
        backgroundColor: 'rgba(230,200,200, .3)',
    },
    '& .MuiAccordionDetails-root ':{
      display: 'block',
    },
  },
  AlertIconWrapper:{
    padding: '2px 5px',
    border:'1px solid #ccc',
    borderRadius:6,
    whiteSpace: 'nowrap',
    
    
  },
  AlertIcon:{
    fill: '#c00',
    verticalAlign: 'middle',
    minWidth: 36,
    minHeight: 36,
    width: 36,
    height: 36,
    padding: 2,
    marginLeft: 8,
    marginRight: 10,
  },
  AlertIconTitle:{
    fontWeight: 'bold',
    color: '#c00',
    display: 'inline-block',
    margin: '0 3px',
    verticalAlign: 'middle',
    fontSize: '1.2em',
  }
});

// provide table interface for active alerts
export default function ResidentAlerts(props) {
  const classes = useStyles();
  const {ResidentId} = props;
/*
  // load the data from the chart sub section
  // replace this with a call to api via ajax
  const data = resident_data.chart.alerts.data;
  
  
  // list of columns for datatable
   const columns = resident_data.chart.alerts.headers;
*/
 // provide dynamic icon for placement based on icon name
 function AlertIcon(icon, tableMeta){
  const icons = {
    'Leave Room': TransferWithinAStation,
    'Leave Building': DirectionsRun,
    'Call Help': SmsFailed,
    'Standing Up': AccessibilityNew,
    'Fall': SportsHandball,
    'OK': CheckCircle,
  };

  	/*
ALT001
Fall

Edit Edit
Copy Copy
Delete Delete
ALT002
Rising to stand

Edit Edit
Copy Copy
Delete Delete
ALT003
Left room

Edit Edit
Copy Copy
Delete Delete
ALT004
Left builing

Edit Edit
Copy Copy
Delete Delete
ALT005
Calling for help
*/
  const resIcons = {
      'ALT001': 'Fall',
      'ALT002': 'Standing Up',
      'ALT003': 'Leave Room',
      'ALT004': 'Leave Building',
      'ALT005': 'Call Help',
      'ALT006': 'OK',
    };
    

  icon = resIcons[tableMeta.rowData[1]]
  if (!icon){
      icon = resIcons['R105'];
  }
  //let  DynamicIcon = icons[{icon}]
  //let AlertIcon = icons[icon];
  let AlertIcon = icons[icon];
  let icon_color = 'icon_red'
  if (icon === 'OK'){
      icon_color = 'icon_green'
  }

  let result = ( 
      <React.Fragment>
          {/*<Link to='/residents/chart'>*/}
          <div className={classes.AlertIconWrapper + ' AlertIconWrapper ' +icon_color}>
              <AlertIcon className={classes.AlertIcon + ' '+icon_color}/>
              <span className={classes.AlertIconTitle +' ' +icon_color}>
                  {icon}
              </span>
          </div>
         {/* </Link>*/}
      </React.Fragment>
  )
  return result
}


//AJAX:
  // handle ajax data loading error
  const [error, setError] = React.useState(null);
  // handle ajax data loading completion of data load
  const [isLoaded, setIsLoaded] = React.useState(false);
  // handle item list of ajax reponse payload
  const [items, setItems] = React.useState([]);
  // this is the raw result of the ajax response
  const [ajaxData, setAjaxData] = React.useState([]);
  // this is the actual api result data transfored to datatable format and stored in keys for each section
  const [apiData, setApiData] = React.useState({});
  // this handles recieving the api response and the section, formats the response data, and 
  const handleDataLoader = (section, loaderData) => {
    console.log('resident_data', resident_data);
    // convert the data to headers and rows
    // use the function from other componenet in residents main sectiopn sub components
    // note: target json property of the api response payload dataset according to api response structure
    let result ={}
    let data_array= [];
    // check for error or for success, recieve status from ajax response handler in data loader
    if (loaderData){
      //  headers: ['Drug Name', 'Date Ordered', 'Day\'s Supply', 'Regimen'],
        result.headers = [
          //'AlertTime','AlertType','Note','ReportingClinicianId','ResidentId','TransactionId'
          'Alert Time',
          {name: "Alert Type",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(AlertIcon(value,tableMeta))}},
          'Note',
          'Clinician'
          
        ];
        result.data = loaderData.alerts
        result.rows = result.data.map((row) => {
          let new_row = [row['AlertTime'],row['AlertType'],row['Note'],'CNA Susan Richmond'];
          return new_row;
        })
    }
    else{
      // the result is error
      result.error = JSON.stringify(loaderData)
    }
    // this updates the data with the section key with new api data
    let api_response = result;
    setApiData(api_response);
    setIsLoaded(true)
    console.log('> API Handler: ', apiData);
  };


    // set options for datatable
   const options = {
    filterType: 'checkbox',
  };
  const displayPanel = ()=>{
    switch(isLoaded){
      case false:
       return <div>loading data ..</div>
       break;
    case true: 
      return(
        <div>
        {/*JSON.stringify(apiData)*/}
        
    <MUIDataTable
    title={"Alert History"}
    data={apiData.rows}
    columns={apiData.headers}
    options={options}
    /> 
      </div>
      )
      break;
    }
  }
  // render the component
  return (
   <div className={classes.root}>
<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <h3>Alerts</h3>
        </AccordionSummary>
        <AccordionDetails>
          <DataLoader selectedSection={"residentAlerts"} method={'get_list'} payload={{"ResidentId": ResidentId}} handleDataLoader={handleDataLoader} />
          {displayPanel()}
        </AccordionDetails>
    </Accordion>
   </div>
  );
}