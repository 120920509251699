import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {BrowserRouter as Router , Switch, Route, Link,withRouter} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';

import { Formik, Form } from "formik";
import * as yup from "yup";

let ResidentSchema = yup.object().shape({
 
    FirstName: yup.string().required("This field is required."),
    MiddleName: yup.string().required("This field is required."),
    LastName: yup.string().required("This field is required."),
    Gender: yup.string().required("This field is required."),
    DOB: yup.string().required("This field is required."),

    Email: yup.string().required("This field is required."),
    Phone: yup.string().required("This field is required."),
    AddressLine1: yup.string().required("This field is required."),
    AddressLine2: yup.string(),
    City: yup.string().required("This field is required."),
    State: yup.string().required("This field is required."),
    Zip: yup.string().required("This field is required."),

    SSN: yup.string().required("This field is required."),
    MedicaidNo: yup.string().required("This field is required."),
    MedicareNo: yup.string().required("This field is required."),
    
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    '& h2, h3, h4':{
      textAlign: 'left !important',
      color: '#999 !important',
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export const AdminResidentForm = (props) =>  {

 const history = useHistory();

    
  const classes = useStyles();

  const {handleSubmit} = props;

  

  return (

    <Grid container spacing={2}>
    <Grid item xs={12} sm={12}>

    <Paper className={classes.root}>
    
    <Container component="main" maxWidth="lg">
    
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Resident
        </Typography>
        <Formik
          initialValues={{
            //ResidentId: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            DOB: "",
            Gender: "",
            SSN: "",
            MedicaidNo:"",
            MedicareNo:"",
            Email: "",
            Phone: "",
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            Zip: "",
          }}
          validationSchema={ResidentSchema}
          onSubmit={(values) => {
              //event.preventDefault();

            setTimeout(() => {
              console.log('> form values',values);
              let ResNum = Math.floor(Math.random()*12762390233);
              let ResidentId = `R${ResNum}`
              let payload = values;
              payload.ResidentId = ResidentId;
              console.log('> form pre-send',values, '> payload',payload);
              
              axios.post('/api/createResident',  
                payload ,
                { headers: {
                  // 'application/json' is the modern content-type for JSON, but some
                  // older servers may use 'text/json'.
                  // See: http://bit.ly/text-json
                  'content-type': 'application/json'
                },
              }
              )
                .then(res=>{
                  console.log(res);
                  console.log(res.data);
                  handleSubmit(payload)
                  //if (values.username != null || values.username != ''){
                  history.push("/admin/residents");
                  //}

                  //window.location = "/retrieve" //This line of code will redirect you once the submission is succeed
                })
                
              
            }, 1000);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
              <Grid item xs={12}><h3>Identity</h3></Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.FirstName && touched.FirstName}
                    autoComplete="fname"  
                    name="FirstName"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="FirstName"
                    label="FirstName"
                    autoFocus
                    helperText={
                      errors.FirstName && touched.FirstName
                        ? errors.FirstName
                        : null
                    }
                  />
                </Grid>
                
                <Grid item xs={12} sm={4}>
                  <TextField
                  error={errors.MiddleName && touched.MiddleName}
                  autoComplete="fname"
                  name="MiddleName"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="MiddleName"
                  label="MiddleName"
                  helperText={
                    errors.MiddleName && touched.MiddleName
                      ? errors.MiddleName
                      : null
                  }
                />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                  error={errors.LastName && touched.LastName}
                  autoComplete="fname"
                  name="LastName"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="LastName"
                  label="LastName"
                  helperText={
                    errors.LastName && touched.LastName
                      ? errors.LastName
                      : null
                  }
                />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                  error={errors.DOB && touched.DOB}
                  autoComplete="fname"
                  name="DOB"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="DOB"
                  label="DOB"
                  helperText={
                    errors.DOB && touched.DOB
                      ? errors.DOB
                      : null
                  }
                />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                  error={errors.SSN && touched.SSN}
                  autoComplete="fname"
                  name="SSN"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="SSN"
                  label="SSN"
                  helperText={
                    errors.SSN && touched.SSN
                      ? errors.SSN
                      : null
                  }
                />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                  error={errors.Gender && touched.Gender}
                  autoComplete="fname"
                  name="Gender"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="Gender"
                  label="Gender"
                  helperText={
                    errors.Gender && touched.Gender
                      ? errors.Gender
                      : null
                  }
                />
                </Grid>

                <Grid item xs={12}><h3>Contact</h3></Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                  error={errors.Phone && touched.Phone}
                  autoComplete="fname"
                  name="Phone"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="Phone"
                  label="Phone"
                  helperText={
                    errors.Phone && touched.Phone
                      ? errors.Phone
                      : null
                  }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                  error={errors.Email && touched.Email}
                  autoComplete="fname"
                  name="Email"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="Email"
                  label="Email"
                  helperText={
                    errors.Email && touched.Email
                      ? errors.Email
                      : null
                  }
                  />
                </Grid>
               


                <Grid item xs={12}><h3>Address</h3></Grid>

                <Grid item xs={12} sm={6}>
                <TextField
                error={errors.AddressLine1 && touched.AddressLine1}
                autoComplete="fname"
                name="AddressLine1"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                id="AddressLine1"
                label="AddressLine1"
                helperText={
                  errors.AddressLine1 && touched.AddressLine1
                    ? errors.AddressLine1
                    : null
                }
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                  <TextField
                  error={errors.AddressLine2 && touched.AddressLine2}
                  autoComplete="fname"
                  name="AddressLine2"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="AddressLine2"
                  label="AddressLine2"
                  helperText={
                    errors.AddressLine2 && touched.AddressLine2
                      ? errors.AddressLine2
                      : null
                  }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                  error={errors.City && touched.City}
                  autoComplete="fname"
                  name="City"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="City"
                  label="City"
                  helperText={
                    errors.City && touched.City
                      ? errors.City
                      : null
                  }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <TextField
                error={errors.State && touched.State}
                autoComplete="fname"
                name="State"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                id="State"
                label="State"
                helperText={
                  errors.State && touched.State
                    ? errors.State
                    : null
                }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TextField
                  error={errors.Zip && touched.Zip}
                  autoComplete="fname"
                  name="Zip"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="Zip"
                  label="Zip"
                  helperText={
                    errors.Zip && touched.Zip
                      ? errors.Zip
                      : null
                  }
                  />
                </Grid>
                <Grid item xs={12}><h3>Medical</h3></Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                  error={errors.MedicaidNo && touched.MedicaidNo}
                  autoComplete="fname"
                  name="MedicaidNo"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="MedicaidNo"
                  label="MedicaidNo"
                  helperText={
                    errors.MedicaidNo && touched.MedicaidNo
                      ? errors.MedicaidNo
                      : null
                  }
                  />
                </Grid>


                <Grid item xs={12} sm={4}>
                  <TextField
                  error={errors.MedicareNo && touched.MedicareNo}
                  autoComplete="fname"
                  name="MedicareNo"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="MedicareNo"
                  label="MedicareNo"
                  helperText={
                    errors.MedicareNo && touched.MedicareNo
                      ? errors.MedicareNo
                      : null
                  }
                  />
                </Grid>

              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
    </Paper>
    </Grid>
    </Grid>
  );
};

export default withRouter(AdminResidentForm)