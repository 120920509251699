import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
//import DemoTable from './DemoTable'
import ImgMediaCard from '../common/FeatureCard'
import Banner from '../../images/graphics/nurse_doctor_ipad.png';
import Banner2 from '../../images/graphics/nurse_ipad_reception.png';
import BannerVector from '../../images/vectors/spectator_health_app_poster_01.png';
import WorkflowStepper from './WorkflowStepper';
const admin_menu_data = [
    {
        title: 'Residents',
        link:'',
        desc:'',
        data:[

        ]
    },
    {
        title: 'Users',
        link:'',
        desc:'',
    },
    {
        title: 'Clinicians',
        link:'',
        desc:'',
    },
    {
        title: 'Pharmacies',
        link:'',
        desc:'',
    },
    {
        title: 'Prescriptions',
        link:'',
        desc:'',
    },
    {
        title: 'Labs',
        link:'',
        desc:'',
    },
    {
        title: 'Activity',
        link:'',
        desc:'',
    },
    {
        title: 'Vitals',
        link:'',
        desc:'',
    },
]

const cards_data = [
  {
    title: 'Residents',
    image_path: 'smart_health.png',
    text: 'Manage residents in assisted living w advanced care inforamtion. Residents total information view w realtime tracking.',
    link: '/residents',
  },
  {
    title: 'Facilities',
    image_path: 'living_facility.jpg',
    text: 'Supervise building utilities and other information for care facility to ensure all topics are to specification and running.',
    link: '/facility',
  },
  {
    title: 'Admin',
    image_path: 'health_data_network.jpg',
    text: 'Customize the platform to suite your specific needs, by modifying and extedning the platform with information from your organization.',
    link: '/admin',
  },
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    textAlign: 'left',
    '& header': {
      background: '#ffffff',
      background: '-moz-linear-gradient(top,  #ffffff 0%, #e6eff4 100%)',
      background: '-webkit-linear-gradient(top,  #ffffff 0%,#e6eff4 100%)',
      background: 'linear-gradient(to bottom,  #ffffff 0%,#e6eff4 100%)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#e6eff4",GradientType=0 )',
      color: '#8795a5',
    },
  },
  bar: {
    background: '#5592de',
  },
  tabpanel:{
      '& h2':{
        color: '#436f96',
        textAlign:'left',
      }
  },
  app_features:{
    padding: 10,
    margin: 10,
    backgroundColor: '#dde6ec',
  },
  intro:{
    padding: '20px 40px',
    color: '#8795a5',
    '& p': {
      padding: '10px 0',
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
    }
  },
  banner:{
    width: '100%',
    height: 250,
    background:`url(${Banner2}) ` ,
    backgroundSize: 'cover',
    //backgroundPosition: ' center 70%',
    backgroundPosition: ' center 77%',
  },
  Vector:{
    width: '100%',
  },
}));

export default function HomeSection() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cardset = cards_data.map((item, index) =>
    //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
    <Grid item xs={12} md={4}>
      <ImgMediaCard {...item} />
    </Grid>
    )

  return (
    <div className={classes.root}>
            <div className={classes.banner} />

      <div className={classes.intro}>
        <h3>Welcome to Spectator Health Application</h3>
        <p>Our platform improves quality care at assisted living facilities by integrating data for 1) facilities, 2) residents, 3) admin system for the platform.  Living Facility and care staff can access and adminster data through this application. Select from the options below to visit sections for learn about the system.</p>
      </div>
      <Paper variant="outlined" className={classes.app_features} >
      <Grid container spacing={2}>
      {cardset}
       {/*
       <Grid item xs={12}>
       <Divider/>
         <WorkflowStepper/>
         </Grid>
        */}

        <Grid item xs={12}>
        <img className={classes.Vector} src={BannerVector}/>
        </Grid>
        </Grid>
     </Paper>
    </div>
  );
}