import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    margin: 'auto',
    '& a:link':{
        textDecoration: 'none',
        color: '#000',
    },

  },
  minibuttons:{
    '& button':{
        color: '#8297a9',
    }      
  }
});

export default function ImgMediaCard(props) {
  const classes = useStyles();
  const imgpath = props.image_path;
  const img = require ('./../../images/'+imgpath)
  return (
    <Card className={classes.root}>
      
      <Link to={props.link}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image={img}
          title={props.title}
         
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          {props.title} <NavigateNextIcon/> 
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.text}
          </Typography>
        </CardContent>

      </CardActionArea>
      </Link>
      <CardActions className={classes.minibuttons}>
        <Link to={props.link}>
            <Button size="small" >
                Visit
            </Button>
        </Link>        
        
        <Button size="small" >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}