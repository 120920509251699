import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles({
  root: {
      fontSize: '.9em',
    maxWidth: 400,
    margin: 'auto',
    '& a, a:link, a:visited , a:link h2, h2':{
        textDecoration: 'none',
        color: '#000 !important',
    },

   
  },
  minibuttons:{
    '& button':{
        color: '#8297a9',
        
    }      
  },
  info_rows:{
      '& > div:not(:last-child)':{
        margin: '2px auto 8px',
        
      }
  },
  info_row:{
    color: '#69c',
    
    '& label':{
        color:'#999',
        display: 'inline-block',
        width: 100,
        borderBottom: '1px solid #ccc',   
        textTransform: 'capitalize', 
    },
    
},
});

export default function MySummaryCard(props) {
  const classes = useStyles();

    const data = {
        name: 'Josh Patterson',
        contact: '780 beacon st, boston, ma, 01200',
        location: 'Fairwoods Residences',
        role: 'CNA',
        hours: '8-10',
        schedule: 'mon-fri',
    }

  return (
    <Card className={classes.root}>
      
      <Link to="/myaccount">
      
      {/*
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image={img}
          title={props.title}
         
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          {props.title} <NavigateNextIcon/> 
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.text}
          </Typography>
        </CardContent>

      </CardActionArea>
      */}
      <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          My Summary <NavigateNextIcon/> 
          </Typography>
          <div className={classes.info_rows}>
            <div className={classes.info_row}><label>Name</label>{data.name}</div>
            <div className={classes.info_row}><label>contact</label>{data.contact}</div> 
            <div className={classes.info_row}><label>location </label>{data.location}</div> 
            <div className={classes.info_row}><label>user roles</label>{data.role}</div>
            <div className={classes.info_row}><label>hours</label>{data.hours}</div>
            <div className={classes.info_row}><label>schedule</label>{data.schedule}</div>
            </div>
        </CardContent>

      </Link>
      <CardActions className={classes.minibuttons}>
        <Link to={props.link}>
            <Button size="small" >
                Edit
            </Button>
        </Link>        
        

      </CardActions>
    </Card>
  );
}