import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Formik, Form } from "formik";
import * as yup from "yup";

let SignupSchema = yup.object().shape({
  PharmacyName: yup.string().required("This field is required."),
  PharmacyGroup: yup.string().required("This field is required."),
  PharmacyContact: yup.string().required("This field is required."),
  PharmacyStatus: yup.string().required("This field is required."),
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export const AdminPharmaciesForm = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Pharmacies Form
        </Typography>
        <Formik
          initialValues={{
            PharmacyName: "",
            PharmacyGroup:  "",
            PharmacyContact:  "",
            PharmacyStatus: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errors.PharmacyName && touched.PharmacyName}
                    autoComplete="fname"
                    name="PharmacyName"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="PharmacyName"
                    label="PharmacyName"
                    autoFocus
                    helperText={
                      errors.PharmacyName && touched.PharmacyName
                        ? errors.PharmacyName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errors.PharmacyGroup && touched.PharmacyGroup}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="PharmacyGroup"
                    label="PharmacyGroup"
                    name="PharmacyGroup"
                    autoComplete="lname"
                    helperText={
                      errors.PharmacyGroup && touched.PharmacyGroup
                        ? errors.PharmacyGroup
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errors.dob && touched.dob}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="dob"
                    label="Date of Birth"
                    name="dob"
                    type="date"
                    autoComplete="dob"
                    helperText={
                      errors.dob && touched.dob ? errors.dob : null
                    }
                  />
                </Grid>

                <Grid item xs={6}>

                        <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                        <Select
                        variant="outlined"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="dob"
                        fullWidth
                        onChange={handleChange}
                        >
                        <MenuItem value={10}>Admin</MenuItem>
                        <MenuItem value={20}>Test</MenuItem>
                        <MenuItem value={30}>CNA</MenuItem>
                        </Select>
                </Grid>

                <Grid item xs={6}>

                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                    variant="outlined"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="userstatus"
                    fullWidth
                    onChange={handleChange}
                    >
                    <MenuItem value={10}>Active</MenuItem>
                    <MenuItem value={20}>Demo</MenuItem>
                    <MenuItem value={30}>Cancelled</MenuItem>
                    <MenuItem value={30}>Blocked</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={errors.email && touched.email}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    helperText={
                      errors.email && touched.email ? errors.email : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errors.password && touched.password}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    helperText={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
