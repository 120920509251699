import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MUIDataTable from "mui-datatables";
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';
import {LiveHelp,TransferWithinAStation,SportsHandball,AccessibilityNew,SmsFailed,DirectionsRun} from '@material-ui/icons/'
import Paper  from '@material-ui/core/Paper';

// style the component
const useStyles = makeStyles({
  root: {
    margin: 'auto',
    padding: 10,
    '& a':{
        textDecoration: 'none',
        color: '#000',
    },
    '& tr:hover .AlertIconWrapper':{
        color: '#f00',
        borderRadius:6,
        border:'1px solid #c00',
        backgroundColor: 'rgba(230,200,200, .3)',
    }
  },
  AlertIconWrapper:{
    padding: '2px 5px',
    border:'1px solid #ccc',
    borderRadius:6,
    
  },
  AlertIcon:{
    fill: '#c00',
    verticalAlign: 'middle',
    minWidth: 36,
    minHeight: 36,
    width: 36,
    height: 36,
    padding: 2,
    marginLeft: 8,
    marginRight: 10,
  },
  AlertIconTitle:{
    fontWeight: 'bold',
    color: '#c00',
    display: 'inline-block',
    margin: '0 3px',
    verticalAlign: 'middle',
    fontSize: '1.2em',
  }
});

// provide table interface for active alerts
export default function ActiveAlertsDatatable(props) {
  const classes = useStyles();
  const data = [
    ["Fall", "2:15pm", "Frank Timblo", 524, "response active", "Elise Timblo (Daughter) 617-254-8432" ],
    ["Standing Up", "1:02pm", "Cynthia Appleson", 402, "pending", "Brett Appleson (Husband) 617-834-7231"],
    ["Leave Room", "12:54pm", "Jeremy Meyers", 21, "response active", "Elise Timblo (Daughter) 617-254-8432" ],
    ["Leave Building", "12:32pm", "Belinda Armen", 710, "pending", "Brett Appleson (Husband) 617-834-7231"],
    ["Call Help", "11:19am", "Stephen Penderflan", 316, "response active", "Elise Timblo (Daughter) 617-254-8432" ],
   ];
  
   // format name column data
  function ResidentName(value){
    
  let result =( <Link to='/residents/chart' > <h3>{value}</h3> </Link> );

    return result
  }

  // provide dynamic icon for placement based on icon name
   function DynamicIcon(icon){
    const icons = {
      'Leave Room': TransferWithinAStation,
      'Leave Building': DirectionsRun,
      'Call Help': SmsFailed,
      'Standing Up': AccessibilityNew,
      'Fall': SportsHandball,
    };

    //let  DynamicIcon = icons[{icon}]
    let DynamicIcon = icons[icon];
    let result = ( 
        <React.Fragment>
            <Link to='/residents/chart'>
            <div className={classes.AlertIconWrapper + ' AlertIconWrapper '}>
                <DynamicIcon className={classes.AlertIcon}/>
                <span className={classes.AlertIconTitle}>
                    {icon}
                </span>
            </div>
            </Link>
        </React.Fragment>
    )
    return result
  }
  
  // list of columns for datatable
   const columns = [{
    name: "Alert",
    options: {
      filter: true,
      customBodyRender: (value, tableMeta, updateValue) => (
        DynamicIcon(value)
      )
    }
  },
   "Alert Time", 
   {
    name: "Name",
    options: {
      filter: true,
      customBodyRender: (value, tableMeta, updateValue) => (
        ResidentName(value)
      )
    }
  }, 
  "Room", "status", "contact"];

    // set options for datatable
   const options = {
    filterType: 'checkbox',
  };


  // render the component
  return (
   <div className={classes.root}>

    <h2>Active Alerts</h2>
    <Paper variant="outlined" className={classes.screenshot} >
    <MUIDataTable
    title={"Active Alerts"}
    data={data}
    columns={columns}
    options={options}
    /> 
    </Paper>
   </div>
  );
}





