import React, { Fragment } from 'react';
import {BrowserRouter as Router , Switch, Route, Link,NavLink} from 'react-router-dom';
import clsx from 'clsx';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import {Search as SearchIcon, Person, AccountBox, Settings} from '@material-ui/icons';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { MenuList, List, ListItem, ListItemIcon, ListItemText, Divider, SwipeableDrawer , FormGroup, FormControlLabel} from '@material-ui/core';
import SwitchForm from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon'
import { Home, Group, HomeWork, Apps,HelpOutline, Storage } from '@material-ui/icons';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Drawer from '@material-ui/core/Drawer';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {SimpleBreadcrumbs} from '../common/SimpleBreadCrumbs'

import spec_logo from '../../images/spectator_logo.svg'

// routes info fro tree of navigation
import {routes_info} from './RoutesMenu.js'


/* Loop over the menu key data and build the navigation menus 
 - iterate in order over keys and lookup each item data from menu_list object
 - provide menu render componenets and menu item render componenet
 - options for icons or not
*/
const view_data = {
    app_menu: {
        menu_keys: {
            0: "resident",
            1: "admin",
            2: "facility"
        },
        menu_dict:{
            "resident":{
                linl: "",
                title: "Residents",
                info: "",
                enabled: true,
                icon: "",
            },
            "admin":{
                linl: "",
                title: "Admin",
                info: "",
                enabled: true,
                icon: "",
            },
            "facility":{
                linl: "",
                title: "Facility",
                info: "",
                enabled: true,
                icon: "",
            }
        },
        menu_list:[
          {
            link: "/",
            title: "Home",
            info: "",
            enabled: true,
            icon: "home",
            public: true,
        },
        {
          link: "/about",
          title: "Features",
          info: "",
          enabled: true,
          icon: "apps",
          public: true,
        },
       
         {
                link: "/residents",
                title: "Residents",
                info: "",
                enabled: true,
                icon: "group",
                public: false,
            
            },
            
            {
                link: "/facility",
                title: "Facility",
                info: "",
                enabled: true,
                icon: "homework",
                public: false,
              },
            {
              link: "/admin",
              title: "Admin",
              info: "",
              enabled: true,
              icon: "Storage",
              public: false,
            },
            {
              link: "/support",
              title: "Support",
              info: "",
              enabled: true,
              icon: "HelpOutline",
              public: true,
            },
      ]
    },
    user_menu: {
        menu_keys: {
            0: "messages",
            1: "alerts",
            2: "user"
        },
        menu_list:{
            "Messages":{
                link: "",
                title: "Messages",
                info: "",
                enabled: true,
                icon: "",
            },
            "alerts":{
                link: "/alerts",
                title: "Alerts",
                info: "",
                enabled: true,
                icon: "",
            },
            "user":{
                link: "",
                title: "User",
                info: "",
                enabled: true,
                icon: "",
            }
        }
    }
}

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  logo:{
    width: '100%',
    minWidth: 150,
    '& img':{
        maxHeight: 50,
        verticalAlign: 'middle',
    }
  },
  grow: {
   // flexGrow: 1,
    width: '100%',
  },
  appHeader:{
      //background: '#3f79b5',
      background: '#ffffff',
      color: '#000000',
  },
  app_title:{
  },
  main_menu:{
      width: 600,
      padding: 0,
        margin: 2,
        whiteSpace: 'nowrap',
  },
  main_menu_item:{
      display: 'inline-block',
      padding: 2,
      margin: 2,
      minWidth: 50,
      fontSize: '1rem',
      '& button': { color: '#387ea9',},
      '& svg':{
        marginRight: 10,
      },
      '& a':{
        textDecoration:'none',
      },
  },
  
  menuButton: {
    marginRight: theme.spacing(2),
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  title: {
//    display: 'none',
    overflow: 'visible !important',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  account_menu: {
    '& a:link':{
      width: 150,
      textDecoration: 'none',
      color: '#8297a9',
      whiteSpace: 'nowrap',
    },
    '& svg':{
      fill: '#8297a9',
      marginRight: 10,
    },
    
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    '& svg':{
      fill: '#aaa',
    },
    '& .MuiBadge-badge':{
      backgroundColor: '#68c',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  alertsButton:{
    '& svg':{
      color: '#c00',
      fill: '#c00',
    },
    '& .MuiBadge-badge':{
      backgroundColor: '#f00',
      border: '1px solid #fff',
    },
  },
  
// drawer left

appBar: {
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
},
appBarShift: {
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: drawerWidth,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
menuButton: {
  marginRight: theme.spacing(2),
},
hide: {
  display: 'none',
},
drawerLeft: {
  width: drawerWidth,
  flexShrink: 0,
  '& li':{
    padding: '0 0 0 16px',
    background: 'rgba(240,242,245,1)',
  },
  '& a:link':{
    color: '#59c',
    textDecoration: 'none',
  },
  
  '&  a:link.status_pending':{
    color: '#99c',
  },
  '&  a:link.status_draft':{
    color: '#1488de',
  },
  '&  a:link.status_placeholder':{
    color: '#FF5545',
  },
  '&  a:link.status_placed_design':{
    color: '#7266b5',
  },
  
  '& .MuiListItemText-root > span':{
    fontSize: '.8rem',
  },
  '& svg':{
    marginRight: 10,
  },
  '& li:hover span':{
    color: '#025 !important',
  },
  '& li:hover svg':{
    fill: '#025',
  },
},
drawerPaper: {
  width: drawerWidth,
},
drawerHeader: {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  '& > span':{
    fontSize: '1rem',
    color: '#658abb',
    position: 'absolute',
    left: 15,
    top: '38%',
    fontWeight: 'bold',
  },
},
content: {
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: -drawerWidth,
},
contentShift: {
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: 0,
},
sitenav_section_title:{
  textAlign: 'left',
  color: '#304a65',
  //background: '#e1edf9',
  background: 'rgb(204,221,235)',
  background: "linear-gradient(180deg, rgba(225,230,235,1) 0%, rgba(255,255,255,1) 70%)",

  fontSize: '0.9rem',
  textTransform: 'capitalize',
  '& > span':{
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 4,
  },
  position: 'relative',
  padding: '0 0 0 16px',
  overflow: 'hidden',
},

routeSectionSwitch:{
  display: 'inline-block',
  verticalAlign: '-webkit-baseline-middle',
  position: 'absolute',
  right: 5,
  top: 0,
},

login:{
  padding: '0 3',
  whiteSpace: 'nowrap',
  verticalAlign:'middle',
  
  '& button':{
    display: 'inline-block',
    margin: 2,
    verticalAlign:'middle',
  },

},

welcomeNote:{
  fontSize: '11pt',
  display: 'inlineBlock',
  padding: 10,
  verticalAlign: 'middle',
  color: '#666',
}

}));


const AntSwitch = withStyles(() => ({
  root: {
    display: 'inline-block',
    verticalAlign: '-webkit-baseline-middle',
    marginTop: 4,
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    overflow: 'initial',
  },
  switchBase: {
    padding: 2,
    color: '#aaa',
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#72a8ea',
        borderColor: '#37c',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: '1px solid #999',
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#fff',
  },
  checked: {},
}))(SwitchForm);

export default function PrimarySearchAppBar(props) {

  const {activeUser, isLoggedIn, handleLogout} = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,

    checkedGeneral: true,
    checkedAdmin: false,
    checkedFacilities: false,
    checkedResidents: false,
    checkedMyAccount: false,
  });
  //const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  
  // left drawer
  const [open, setOpen] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

  


  // right drawer
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['User', 'Theme', 'Preferences', 'Logs', 'App Info'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Alerts', 'Messages', 'Notifications'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );


  function DynamicIcon(icon){
    const icons = {
      home: Home,
      group: Group,
      homework: HomeWork,
      apps: Apps,
      HelpOutline:HelpOutline,
      Storage:Storage,
    };

    //let  DynamicIcon = icons[{icon}]
    let DynamicIcon = icons[icon];
    return <DynamicIcon/>
  }


  /*
  
  add logic for if isloggedin, then show the public=false items,
  else, show only the public true items
  
  */
    // render the menu
  const main_menu_list = function(){
      if(isLoggedIn){
        return(view_data.app_menu.menu_list.map((item, index) =>
        //  <Button></Button>
        <li key={index} value={index} primaryText={item.title} className={classes.main_menu_item}>
          <Link to={item.link}>
            <Button aria-label={item.title}>
              { DynamicIcon(item.icon) }
              {item.title}
            </Button>
          </Link>
        </li>
      ));
    }else{
      return(view_data.app_menu.menu_list.filter(function(obj){return obj.public;}).map((item, index) =>
      //  <Button></Button>
      <li key={index} value={index} primaryText={item.title} className={classes.main_menu_item}>
        <Link to={item.link}>
          <Button aria-label={item.title}>
            { DynamicIcon(item.icon) }
            {item.title}
          </Button>
        </Link>
      </li>
    ))
    }
  }
    // menu container
  const main_menu = <ul className={classes.main_menu}>{main_menu_list()}</ul>

  const menuId = 'primary-search-account-menu';
  
  //selected={activeRoute(item.link)}
  const route_ui = routes_info.map((item, index) =>
    <NavLink to={item.link} style={{ textDecoration: 'none' }} key={item.link}>
      <MenuItem >
        {/*<ListItemIcon>{item.icon}</ListItemIcon>*/}
        <ListItemText primary={item.title} />
      </MenuItem>
    </NavLink>
  )


  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // routes sections
  const route_sections = routes_info.map(function (section,index){
    

    let markup = section['routes'].map(function (item, index) {
      let statusClass= 'status_' + item.status;;
    
        switch (item.status){
          case 'pending':
            statusClass = 'status_' + item.status;
          case 'draft':
            statusClass = 'status_' + item.status;
        break;
        }
      return(
        <NavLink to={item.link} className={statusClass} style={{ display: state["checked"+section.section] ? 'block':'none' }} key={item.link}>
          <MenuItem >
            <item.icon/>
            <ListItemText primary={item.title} />
          </MenuItem>
        </NavLink>
        );
      })
    // packup the routes menu
    let result = ( 
      <React.Fragment>
        <li className={classes.sitenav_section_title}>
          <span >{section.section}</span>
          <FormGroup className={classes.routeSectionSwitch}>
            <AntSwitch
              checked={state['checked'+section.section]}
              onChange={handleChange}
              name={"checked"+section.section}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </FormGroup>
        </li> 
        {markup}
        <Divider />
      </React.Fragment>
      )
    return result
  });

  // routes list
  const routes_menu_list = (
    <div>
    <div className={classes.toolbar} />
    <Divider />
      <MenuList>
        {route_sections}
        </MenuList>
   
    </div>
  )

  // render the drop down menu for user account
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.account_menu}
    >
    <Link to="/welcome" >
        <MenuItem onClick={handleMenuClose}>
        <Person/>
          Profile
        </MenuItem>
      </Link>

      <Link to="/myaccount" >
        <MenuItem onClick={handleMenuClose}>
          <AccountBox/>
            My account
        </MenuItem>
      </Link>
      
      <MenuItem onClick={toggleDrawer('right', true)}>
      <Settings/>
        Settings
      </MenuItem>
      
      <SwipeableDrawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        {list('right')}
      </SwipeableDrawer>

    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (

    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* Messages and alerts */}
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="primary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
      <Link to="/alerts">
        <IconButton component={Link} to="/alerts" aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
        </Link>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
const loginbuttons = function (){

  if (isLoggedIn === false){
    return( <div className={classes.login}>
      <Link to="/signup">
      <Button
        type="submit"
        size="small"
        variant="contained"
        className={classes.loginButton}
      >
        Signup
    </Button>
    </Link>
    <Link to="/login">
      <Button
        type="submit"
        size="small"
        variant="contained"
        className={classes.loginButton}
      >
        Login
    </Button>
    </Link>
    </div>);
  }else{
    return( <div className={classes.login}>
      <span className={classes.welcomeNote}>Welcome, {activeUser}</span>
      <Link to="/logout">
        <Button
          type="submit"
          size="small"
          variant="contained"
          className={classes.loginButton}
        >
          Logout
      </Button>
      </Link>
    </div>);
  }
}
//onClick={handleLogout({activeUser})}


const loggedInProfileButtons = function (){

    if (isLoggedIn == true) {
      return(<Fragment>
      <IconButton aria-label="show 4 new mails" color="inherit">
      <Badge badgeContent={4} color="primary">
        <MailIcon />
      </Badge>
    </IconButton>
    <Link to="/alerts">

    <IconButton aria-label="show 5 new notifications" color="inherit" className={classes.alertsButton}>
      <Badge badgeContent={5} color="secondary">
        <NotificationsIcon />
      </Badge>
    </IconButton>
      </Link>
    
    <IconButton
      edge="end"
      aria-label="account of current user"
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={handleProfileMenuOpen}
      color="inherit"
    >
      <AccountCircle />
    </IconButton>
    </Fragment>
    );
    }

}


  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appHeader}>
        <Toolbar>
          <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          {/* LOGO */}
    
          <Link to='/'  className={classes.logo}>
            <img src={spec_logo} alt="icon" />
          </Link>      
{/*
          <Typography className={classes.title} variant="h6" noWrap>
            Spectator Health
          </Typography>
*/}

          {/* TOP MENU */}
 
          {main_menu}
     

          <div className={classes.grow} />

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>

          {/* Messages / alerts / user */}
          <div className={classes.sectionDesktop}>
            
          {/* signup and login if not logged in , else  */}
              {loginbuttons()}

            {loggedInProfileButtons()}
          {/* close Messages / alerts / user */}



          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      
      <Drawer
        className={classes.drawerLeft}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <span>Site Navigation</span>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon /> 
          </IconButton>
        </div>
        <Divider />
        {routes_menu_list}
      </Drawer>

      {renderMenu}

      <SimpleBreadcrumbs />

    </div>
  );
}
/*
{renderMobileMenu}
  */