import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper  from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';
import MUIDataTable from "mui-datatables";

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import IconResidents from '@material-ui/icons/Group';
import IconChart from '@material-ui/icons/Assessment';
import IconCareplan from '@material-ui/icons/ListAlt';
import IconVitals from '@material-ui/icons/Accessibility';
import IconNotes from '@material-ui/icons/Notes';
import IconActivity from '@material-ui/icons/LocalHotel';
import IconDiet from '@material-ui/icons/Fastfood';
import IconMeds from '@material-ui/icons/LocalHospital';
import IconLabs from '@material-ui/icons/Colorize';


import ScreenResidents from './../../images/screens/spec_residents.png';
import ResidentsDatatable from './ResidentsTable';

import ScreenChart from './../../images/screens/spec_chart.png';
import ScreenCareplan from './../../images/screens/spec_careplan.png';
import ScreenVitals from './../../images/screens/spec_vitals.png';
import ScreenNotes from './../../images/screens/spec_notes.png';
import ScreenActivity from './../../images/screens/spec_activity.png';
import ScreenDiet from './../../images/screens/spec_diet.png';
import ScreenMeds from './../../images/screens/spec_meds.png';
import ScreenLabs from './../../images/screens/spec_labs.png';

import Woman01 from './../../images/residents/woman_01.png'
import Woman02 from './../../images/residents/woman_02.png'
import Woman03 from './../../images/residents/woman_03.png'
import Woman04 from './../../images/residents/woman_04.png'
import Woman05 from './../../images/residents/woman_05.png'
import Woman06 from './../../images/residents/woman_06.png'
import Woman07 from './../../images/residents/woman_07.png'

import ResidentChart from './resident/ResidentChart';

import axios from "axios";
import DataLoader from '../common/DataLoader';
import DataLoaderPost from '../common/DataLoader_post';

// =====================================================
// forms 
// chart
import FormAddCondition from './resident/forms/FormAddCondition'
import FormAddAllergy from './resident/forms/FormAddAllergy'
import FormAddDiagnosis from './resident/forms/FormAddDiagnosis'
import FormAddPhysician  from './resident/forms/FormAddPhysician'
// careplan
import FormAddCareplan from './resident/forms/FormAddCareplan'
// vitals
import FormAddVitalType from './resident/forms/FormAddVitalType'
import FormAddVitalHeartrate from './resident/forms/FormAddVitalHeartRate'
import FormAddVitalBloodPressure from './resident/forms/FormAddBloodPressure'
import FormAddVitalGlucose from './resident/forms/FormAddGlucose'
import FormAddVitalOxygen from './resident/forms/FormAddOxygen'
// notes
import FormAddNote from './resident/forms/FormAddNote'
// adl
import FormAddActivityType from './resident/forms/FormAddActivityType'
import FormAddActivitySleep from './resident/forms/FormAddActvitySleep'

// diet
import FormAddDietRecord from './resident/forms/FormAddDiet'
// meds
import FormAddPrescription from './resident/forms/FormAddPrescription'
// labs
import FormAddLab from './resident/forms/FormAddLab'


import ResidentCareplan from './resident/ResidentCareplan'

import ResidentVitals from './resident/ResidentVitals'
import FormAddVitalRecord from './resident/resident_vitals/FormAddVitalRecord'

import ResidentNotes from './resident/ResidentNotes'
import ResidentActivity from './resident/ResidentActivity'
import ResidentDiet from './resident/ResidentDiet'
import ResidentMeds from './resident/ResidentMeds'
import ResidentLabs from './resident/ResidentLabs'

import {resident_data} from '../../data/resident_data';

import ResidentDefaultF from './../../images/residents/resident_avatar_female.png'
import ResidentDefaultM from './../../images/residents/resident_avatar_male.png'


/* Menu Component Mapping ===================================================
list of the menu, titles, links, and icons
*/
const admin_menu_data = [
    {
        title: 'Residents',
        link:'list',
        desc:'',
        icon: <IconResidents/>,
        data:[

        ]
    },
    {
      title: 'Chart',
      link:'chart',
      icon: <IconChart/>,
      desc:'',
  },
  /*{
    title: 'Chart/medical',
    link:'chart/medical',
    icon: <IconChart/>,
    desc:'',
},
{
  title: 'Chart/family',
  link:'chart/family',
  icon: <IconChart/>,
  desc:'',
},
{
  title: 'Chart/insurance',
  link:'chart/insurance',
  icon: <IconChart/>,
  desc:'',
},
{
  title: 'Chart/personal',
  link:'chart/personal',
  icon: <IconChart/>,
  desc:'',
},*/
{
        title: 'Careplan',
        link:'careplan',
        icon: <IconCareplan/>,
        desc:'',
    },
    {
        title: 'Vitals',
        link:'vitals',
        icon: <IconVitals/>,
        desc:'',
    },
    {
        title: 'Notes',
        link:'notes',
        icon: <IconNotes/>,
        desc:'',
    },
    {
        title: 'ADL',
        link:'activity',
        icon: <IconActivity/>,
        desc:'',
    },
    {
        title: 'Diet',
        link:'diet',
        icon: <IconDiet/>,
        desc:'',
    },
    {
      title: 'Meds',
      link:'meds',
      icon: <IconMeds/>,
      desc:'',
  },
  {
    title: 'Labs',
    link:'labs',
    icon: <IconLabs/>,
    desc:'',
},
]

/* Styles for tab section and details summary

 */
const useTabStyles = makeStyles((theme) => ({
  root: {
    textAlign:'left',
    color: '#456',   
    //maxWidth: 1300,
    /*margin: 'auto',*/
    display: 'flex',
    borderBottom: 'none',
    '& h4':{
      margin: '0 0 4px 0',
    }
  },
   imgStyle:{
    width:'66px !important', 
    height: 'auto',
  },
  residentSummary:{
    padding: '0 0 0 10px',
    flexGrow: 1,
    verticalAlign:'top',
    '& > div':{
      fontSize: '.8em',
    }
  },
  selectedResidentBadge:{
    padding: 10,
    marginBottom: 6,
  },
  dash_container:{
    position: 'relative',
    background: '#f2f6f9',
    padding: '2px 6px !important',
    marginBottom:100,
    
    '& > div:nth-child(2)':{
      background: 'none',
      float:'right',
    },
  },
  clearfix:{
    content: "",
    display: 'table',
    clear: 'both',
  },
  profile_panel_header:{

  },
  resident_list_header:{
   display:'none',
  }

}))

/* Tab Panel ===================================================
provides the tab panel interface for the resident info
*/

function TabPanel(props) {
  //selectedResidentData={selectedResidentData}
  const { children, value, index, residentID, residentData, ...other } = props;
  const tabNameToIndex = {
    undefined: 'list',
    0: 'list',
    1: 'chart',
    2:'careplan',
    3: 'vitals',
    4: 'notes',
    5: 'activity',
    6: 'diet',
    7: 'meds',
    8: 'labs',
  }
  const classes = useTabStyles();
  
  /*
  Selected Resident detail summary
  provides a badge for the current resident while reviewing sub sections
  */
  function details(residentID) {
    let details = {}
    if (index == 0){
      details = <p className={classes.resident_list_header} >Select resident below to view details</p>
    }else{
      details =  (
        <Paper  className={classes.selectedResidentBadge} >
        <div className={classes.root}>
          <img className={classes.imgStyle} src={residentData[0]}/>
          <div className={classes.residentSummary}>
            <h4>{residentData[1]}</h4>
            <div>ID: <b>{residentData[2]}</b></div>
            <div>Gender: <b>{residentData[6]}</b></div>
            <div>DOB: <b>{residentData[7]}</b></div>
          </div>
        </div>
      </Paper>
      )
    }
    return details
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.dash_container}>
           {details(residentID, index)}
          {children}
        </Box>
      )}
      <div className={classes.clearfix}/>
    </div>
  );
}
//<Typography>
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/* Styles ===================================================
provides styles for the residents system
*/

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',

    '& header': {
      background: '#ffffff',
      background: '-moz-linear-gradient(top,  #ffffff 0%, #e6eff4 100%)',
      background: '-webkit-linear-gradient(top,  #ffffff 0%,#e6eff4 100%)',
      background: 'linear-gradient(to bottom,  #ffffff 0%,#e6eff4 100%)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#e6eff4",GradientType=0 )',
      color: '#000000',
    },
    '& > * + *': {
      //marginTop: theme.spacing(2),
      marginTop: 6,
    },
    
  },
  section_menu:{
    marginTop: 2,
  },
  breadcrumbs:{
    color: '#999',
    marginLeft: 10,
    padding: 4,
    '& a:link, a:visited, a:active': {
      color: '#4499cc',
    },
  },
  bar: {
    background: '#5592de',
  },
  tabpanel:{
    textAlign:'left',
    height: '100%',
    '& .panelinfo':{
      textAlign: 'right',
    },
      '& h2':{
        color: '#436f96',
        textAlign:'left',
        textTransform: 'capitalize',
      }
      ,
      '& img':{
        backgroundColor: '#fff',
        //padding: '20px 30px',
        width: '100%',
        maxWidth: 1300,
        margin: 'auto',
        borderRadius:4,
        opacity: 1,
        border: '1px solid #bcd',
        boxShadow: '3px 2px 5px #8795a5',
      },

  },
  tab_style:{
    minWidth: '120px !important',
    borderLeft: '1px solid #ccc',
    color: '#436f96',
    '&:last-child':{
      borderRight: '1px solid #ccc',
    
    }
  },
  gridscreen:{
    margin: 'auto',
    maxWidth: '1800px',
  },

  screenshot:{
    //height: '110%',
    padding: 4,
    margin: 4,
    backgroundColor: '#dde6ec',
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dde6ec+0,ffffff+100 */
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,dde6ec+100 */
    background: "-moz-linear-gradient(top,  #ffffff 0%, #dde6ec 100%)", /* FF3.6-15 */
    background: "-webkit-linear-gradient(top,  #ffffff 0%,#dde6ec 100%)", /* Chrome10-25,Safari5.1-6 */
    background: "linear-gradient(to bottom,  #ffffff 0%,#dde6ec 100%)", /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dde6ec',GradientType=0 )", /* IE6-9 */
    '& img':{
      width: '100%',
      background: '#fff',
    }
    ,
    '& h2':{
      //maxWidth: 1340,
      margin: '2px 0 8px',
      padding: '1px 4px',
      background: '#d7e1ea',
      borderRadius: 4,
      fontSize: '14pt',
      
    },
    '& h3':{
      //maxWidth: 1340,
      margin: '2px 0 4px',
      padding: '1px 4px',
      background: 'none',
      //borderRadius: 4,
      //borderBottom: '1px solid #ccc',
      fontSize: '11pt',
      color: '#4c85c7',
    }
  },
  careplan_tree:{
    padding: 10,
    height: '100%',
  },
  ResidentSectionPaper:{

  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :nth-child(3)':{
      border: '2px solid #789',
      borderRadius: 4,
    },
  },
  paper: {
    backgroundColor: '#eee',
    border: '2px solid #000',
    boxShadow: '5px 5px 10px #444',
    padding: '2px 4px',
  },
  dataLoaderStatusBadge:{
    padding: 3,
    color: '#666',
    fontSize: '.8em',
    float: 'right',
    verticalAlign: 'middle',
    '& > div':{
      verticalAlign: 'middle',
    },
    '& svg':{
      verticalAlign: 'middle',
    },
  },

}));


function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

// Export Main Component ===================================================

export default  function ResidentSection(props){
  const [residentForm, setResidentForm] = React.useState();
  
  const [open, setOpen] = React.useState(false);
  
  function handleOpen(event,value) {
      //event.preventDefault();
      setOpen(true);
      console.log('> open resident form modal:',value)
      setResidentForm(value)
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    
  /*
    - create a controller for all the API requests
    - create a spinner for loading
    - load the initial list
    - if residentID available from url or selected, load the resident chart info.
    
    const api_base_url = 'http://localhost:4000/api/v1/'
    
    const APIcontroller ={
      list:{
        // return list of residents
        api_url: 'residents',
      },
      // return detail data for selected resident
      chart:{
        api_url: `residents/${residentID}/chart`,
      },
      careplan:{
        api_url: `residents/${residentID}/careplan`,
      },
      vitals:{
        api_url: `residents/${residentID}/vitals`,
      },
      notes:{
        api_url: `residents/${residentID}/notes`,
      },
      activity:{
        api_url: `residents/${residentID}/activity`,
      },
      diet:{
        api_url: `residents/${residentID}/diet`,
      },
      meds:{
        api_url: `residents/${residentID}/meds`,
      },
      labs:{
        api_url: `residents/${residentID}/labs`,
      },
    }

  */

  // provides mapping for resident sections to numeric and back for tracking the active
  // handling page routing and seclection from the tabs menu
  const tabNameToIndex = {
    undefined: 'list',
    0: 'list',
    1: 'chart',
    2: 'careplan',
    3: 'vitals',
    4: 'notes',
    5: 'activity',
    6: 'diet',
    7: 'meds',
    8: 'labs',
  }

  const indexToTabName = {
    undefined: 0,
    'list': 0,
    'chart': 1,
    'careplan': 2,
    'vitals': 3,
    'notes': 4,
    'activity': 5,
    'diet': 6,
    'meds': 7,
    'labs': 8,
  }

  // resident name <-> id mapping
  const residentidToResidentName = {
    'Meena': "R100",
    "Imogene":"R101",
    "Emily":"R102",
    "Smrithi":"R103",
    "Gilda":"R104",
    "Janet":"R105",
    "Erika":"R106",
  }
  const residentNameToResidentid = {
    'Meena': "R100",
    "Imogene":"R101",
    "Emily":"R102",
    "Smrithi":"R103",
    "Gilda":"R104",
    "Janet":"R105",
    "Erika":"R106",
  }

  // resident name <-> index mapping
  const indexToResidentName = {
    0: "Meena",
    1: "Imogene",
    2: "Emily",
    3: "Smrithi",
    4: "Gilda",
    5: "Janet",
    6: "Erika", 
  }
  const residentNameToIndex = {
    "Meena": 0,
    "Imogene":1,
    "Emily":2,
    "Smrithi":3,
    "Gilda":4,
    "Janet":5,
    "Erika":6,
  }

  // resident ID <-> index mapping
  const indexToResidentid = {
    0: "R100",
    1: "R101",
    2: "R102",
    3: "R103",
    4: "R104",
    5: "R105",
    6: "R106", 
  }
  const residentidToIndex = {
    "R100": 0,
    "R101": 1,
    "R102": 2,
    "R103": 3,
    "R104": 4,
    "R105": 5,
    "R106": 6,
  }

  // resident data
  const data = [
    [Woman01,"Meena","R100","Fall","Fall Risk","Assistance","F","1928-12-04","8581234567"],
    [Woman02,"Imogene","R101","Leave Room","Fall Risk","Assistance","F","1940-05-15","7609295555"],
    [Woman03,"Emily","R102","Call Help","Emergency","Assistance","F","1946-12-31","7602315162"],
    [Woman04,"Smrithi","R103","Standing Up","Fall Risk","Assistance","F","1950-01-01","9195732132"],
    [Woman05,"Gilda","R104","OK","-","Assistance","F","1950-12-12","7601052999"],
    [Woman06,"Janet","R105","OK","-","Assistance","F","1940-03-25","8581293600"],
    [Woman07,"Erika","R106","OK","-","Assistance","F","0000-00-00","7603442121"],
   ];

  console.log('props::',props)
  const {match, history} = props;
  const {params} =  match;
  const {residentID, page, subpage} = params;

   /*
   new logic for tab routing and indexes

   if resident id === 'list'
    -> then render the list 'list'
   else
    -> lookup resident ID and got ot that chart
   */

  
  const classes = useStyles();
  //const [value, setValue] = React.useState(0);
  const [section, setSection] = React.useState(page);
  const [selectedTab, setSelectedTab] = React.useState(selectedTabController(residentID, page, subpage));

  // helper for handling the url and selected resident
  function selectedTabController(residentID, page, subpage){
    // change 'list' to 'list'

    /* Resident List Page:
    if the page is empty and the ID is 'list'
     this is the list page, where we dont need a resident id
     we will map it to the first index tab which is value and index 0 
     */
    if(residentID == 'list' ){
      // -> then set the section to the list
      return 0
    }else{
      /* Resident Details Page:
      if this is a detail page, we will have selected a resident ID already
        the residentID will be available from the broswer match params as the element after residents
      return the mapping of this page name to the index of the corresponding tab for details
      */
      return indexToTabName[page]
    }
    /*
    // if the page is empty and there is a valid resident ID
    if (page == undefined && residentID != 'list'){
      // -> set the page to the chart
      return 1
    }
    // else if the id is valid and page valid
    if (page != undefined && residentID != undefined && residentID != 'list'){
      // -> show the corresponding tab with the resident data
      return indexToTabName[page]
    }*/
 }

  // pass this function downt to the resident table component,
  // there, on the table select resdient -> setSelectedResident
  const [selectedResidentID, setSelectedResidentID] = React.useState(residentID);
  const [selectedResidentData, setSelectedResidentData] = React.useState(  getResidentDataByID(residentID));
  
  
  // get the resident data by lookup from residentID of param in url or from UI
  function getResidentDataByID(residentID){
    console.log('residentID',residentID)
    if (residentID != undefined){
      return data[data.findIndex(p => p[2] == residentID)]
    }else{
      return [Woman01, ]
    }
  }
  //setSelectedTab(indexToTabName[page]);
  //setSection(admin_menu_data[indexToTabName[page]]['link']);
// HandleTabChange ===================================================

  // change the tab
  // helper logic to switch between list view and resident detaisl 
  // NOTE: refactor the URL or lookup logic for checking if list view or not.. if possible..
  // so all functionas can use the same function with logicen encapsulated within
  const handleTabChange = (event, newValue) => {
    console.log('newValue',newValue)
    //if (selectedResdientid != undefined){
    if (newValue == 0 ){
      // push the list page
      history.push(`/residents/list`);
    }else{
      // push the details page with rsident ID
      history.push(`/residents/${selectedResidentID}/${tabNameToIndex[newValue]}`);
    }
              //}
    // selectedTabController()
    setSelectedTab(newValue);
    setSection(admin_menu_data[newValue]['link']);
  }
  
  const handleSelectResident = (residentID) => {

    // when click on a resident , select the resdient 
    setSelectedResidentID(residentID);
    // change the url to chart, resident default view
    history.push(`/residents/${residentID}/chart`);
    
    //find the index of the data based on the resident ID
    let dataindex = data.findIndex(p => p[2] == residentID)

    //then assign the resident data to the state
    //setSelectedTab(1);
    
    // get thie residdne info and image
    setSelectedResidentData(data[dataindex])


    setSelectedTab(1);
    setSection(admin_menu_data[1]['link']);
  }


/*
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSection(admin_menu_data[newValue]['link']);
    
  };
  */
/*
  handleCallToRouter = (value) => {
    this.props.history.push(value);

    setSection(admin_menu_data[value]['link']);
    
  }
*/

//AJAX:
  // handle ajax data loading error
  const [error, setError] = React.useState(null);
  // handle ajax data loading completion of data load
  const [isLoaded, setIsLoaded] = React.useState(false);
  // handle item list of ajax reponse payload
  const [items, setItems] = React.useState([]);
  // this is the raw result of the ajax response
  const [ajaxData, setAjaxData] = React.useState([]);
  // this is the actual api result data transfored to datatable format and stored in keys for each section
  const [apiData, setApiData] = React.useState([]);
// helper to transofrm data for UI
function ConvertToCSV(arr) {
  const array = [Object.keys(arr[0])].concat(arr)
  let result =  array.map(it => {
    return Object.values(it)
  })
  console.log('> ConvertToCSV',result)
  return result
}
  // this handles recieving the api response and the section, formats the response data, and 
  const handleDataLoader = (section, loaderData) => {
    console.log('> ResidentSection handleDataLoader ',section, loaderData);
    // convert the data to headers and rows
    // use the function from other componenet in residents main sectiopn sub components
    // note: target json property of the api response payload dataset according to api response structure
    let result ={}
    let data_array= [];
    // check for error or for success, recieve status from ajax response handler in data loader
    if (loaderData.residents){

      /*
      // the result is succesful actual data return
      data_array = ConvertToCSV(loaderData.residents);
      // create a formatted result for storing in the data section key and processing by datatable
      // slice the headers of the top of arrar
      result.headers = data_array.shift(1);
      console.log('> transformed to CSV ------------')
      console.log('> Headers', result.headers , '> Data',data_array)
      
      */
      /* correct: ResidentDefaultF
        "Photo": 'Woman02.png', 
        "Name": "Diana", 
        "ID": "R23489723443", 
        "Alert": "Fall", 
        "Key Risks": "Fall Risk", 
        "Status": "Assistance", 
        "Gender": "F", 
        "DOB": "1930-07-04", 
        "Phone": "8581230099", 
      */
      /*
       "AddressLine1": "1600 Delaware",
         "City": "San Clemente",
         "DOB": "1940-03-25",
         "Email": "MarigJ@me.com",
         "FirstName": "Janet ",
         "Gender": "F",
         "LastName": "Marigold",
         "MedicaidNo": "45677\r",
         "MedicareNo": "12350",
         "MiddleName": "Edith",
         "Phone": "8581293600",
         "ResidentId": "R105",
         "SSN": "999999996",
         "State": "CA",
         "Zip": "92152"

      */
      
      let CleanData = [];
      let RealData = []
      // configure data format
      let i = 0;
      for (i; i < loaderData.residents.length; i++){
        
        let r = loaderData.residents[i]
        
        let person = [ 'avatar.png',"Meena","R100","Fall","Fall Risk","Assistance","F","1928-12-04","8581234567"];
        /*let ResidentDyn = [
          //ResidentDefaultF, // photo
          'avatar.png',
          loaderData.residents[i].FirstName+" "+loaderData.residents[i].LastName, // name
          loaderData.residents[i].ResidentId, // ID
          "-", // alert
          "-", // key risk
          "-", //status
          loaderData.residents[i].Gender, // gender
          loaderData.residents[i].DOB, // dat of birth
          loaderData.residents[i].Phone, // phone
        ]*/
        let Resident = [ 'avatar.png',`${r.FirstName} ${r.LastName}`,r.ResidentId,"Fall","Fall Risk","Assistance",r.Gender,r.DOB,r.Phone];
        
        CleanData.push(person);
        
        RealData.push(Resident);
      }

      // set the rest of array to data
      result.data = RealData;
      console.log('> RealData', RealData)
      //result.data=loaderData
    }
    else{
      // the result is error
      result.error = loaderData
    }
    // this updates the data with the section key with new api data
    let api_response = result;
    setApiData(api_response);
    setIsLoaded(true)
    console.log('> apiData ', apiData);
  };






const resident_forms_map = {
  // Chart
  "AddCondition": FormAddCondition,
  "AddAllergy": FormAddAllergy,
  "AddDiagnosis": FormAddDiagnosis,
  "AddDoctor" : FormAddPhysician,
  // Careplan
  "Careplan": FormAddCareplan,
  // Vitals
  "AddVitalType" : FormAddVitalType,
  "AddVitalHeartrate" : FormAddVitalHeartrate,
  // Notes
  "AddNote": FormAddNote,
  // ADL
  "AddActivityType": FormAddActivityType,
  "AddActivitySleep": FormAddActivitySleep,
  // Diet
  "AddDietRecord": FormAddDietRecord,
  // Meds
  "AddPrescription": FormAddPrescription,
  // Labs
  "AddLabRecord": FormAddLab,
}
    /*
    // pass a function down to the children for calling the modal form dynamically,
    // by using the setResidentForm state to dynamically set the resdient form and show when click the link

    <div className={classes.CreateItemButton}>
          <Link to={'/admin/'+ admin_section + '/form'}  >
            <Button color="primary" onClick={handleOpen} variant="outlined" size="small" boxShadow={2} style={{ border: '1px solid' }} or> 
              <AddCircleOutline/>
              Add Record
              </Button>
          </Link>
        </div>

        */

       const DynamicForm = resident_forms_map[residentForm];
/**
 * Add new logic if the url of resid is 'list' then a resident hasnt been selected
 * so disable the detail tabs until a resdient is selected
 */

// RENDER ===================================================
  return (
    <div className={classes.root}>
      {/*
      <div className={classes.breadcrumbs}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href="/" onClick={handleClick}>
            Material-UI
          </Link>
          <Link color="inherit" href="/getting-started/installation/" onClick={handleClick}>
            Core
          </Link>
          <Typography color="textPrimary">Breadcrumb</Typography>
        </Breadcrumbs>
      </div>
      */}
      <AppBar position="static" className={classes.section_menu}>
        <Tabs 
        centered
        textColor="#ffffff"
        TabIndicatorProps={{
            style: {
              backgroundColor: "#88bbee"
            }
          }}
        value={indexToTabName[page]} 
        onChange={handleTabChange} 
        aria-label="tabs sample: residents">
        {admin_menu_data.map((item, index) =>
            //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
           <Tab className={classes.tab_style} icon={item.icon} compnent={Link} to={'/admin/'+item.link} key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab>
            )}
          
        </Tabs>
        
      </AppBar>
      
      <div className={classes.tabpanel}>
      <Paper   className={classes.screenshot} >

     
      
        {/*
        <div className="panelinfo">
            <div>section : {section}</div>
            <div>index : {indexToTabName[section]}</div>
            <div>page : {page}</div>
            <div>selectedTab : {selectedTab}</div>
        </div>
        */}
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12} className={classes.gridscreen}>
              {indexToTabName[page] === 0 && 
              <TabPanel value={selectedTab}  index={0} residentID={selectedResidentID} residentData={selectedResidentData}>
              {/*
              <h2>{section}</h2>
              <img src={ScreenResidents}  />
              <h4>{selectedResdientid}</h4>
              */}
              <DataLoader selectedSection={"residents"} method={'get_list'} handleDataLoader={handleDataLoader} />
              <ResidentsDatatable handleSelectResident={handleSelectResident} residentID={selectedResidentID} isLoaded={isLoaded} apiData={apiData}/>
              </TabPanel>
              }
              {indexToTabName[page] === 1 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]} residentID={selectedResidentID} residentData={selectedResidentData}>
              {/*<DataLoaderPost selectedSection={"residents"} method={'get_by_id'} handleDataLoader={handleDataLoader} payload={{"ResidentId":selectedResidentID}}/>*/}

              <h2>{section}</h2>
              {/* <img src={ScreenChart}  /> */}
              <Paper  className={classes.ResidentSectionPaper} >
                <ResidentChart residentID={selectedResidentID} handleOpen={handleOpen} handleClose={handleClose}/>
              </Paper>
              </TabPanel>
              }
              {indexToTabName[page] === 2 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]} residentID={selectedResidentID} residentData={selectedResidentData}>
                <h2>{section}</h2>
                {/*<img src={ScreenCareplan}  />*/}
                <Paper variant="outlined" className={classes.careplan_tree} >
                  <ResidentCareplan residentID={selectedResidentID} handleOpen={handleOpen} handleClose={handleClose}/>
                </Paper>
              </TabPanel>
              }
              {indexToTabName[page] === 3 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]} residentID={selectedResidentID} residentData={selectedResidentData}>
                <h2>{section}</h2>
                {/*<img src={ScreenVitals}  />*/}
                <Paper variant="outlined" className={classes.careplan_tree} >
                  <ResidentVitals residentID={selectedResidentID} handleOpen={handleOpen} handleClose={handleClose}/>
                </Paper>
              </TabPanel>
              }
              {indexToTabName[page] === 4 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]} residentID={selectedResidentID} residentData={selectedResidentData}>
                <h2>{section}</h2>
                {/*<img src={ScreenNotes}  />*/}
                <Paper variant="outlined" className={classes.careplan_tree} >
                  <ResidentNotes residentID={selectedResidentID} handleOpen={handleOpen} handleClose={handleClose}/>
                </Paper>
              </TabPanel>
              }
              {indexToTabName[page] === 5 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]} residentID={selectedResidentID} residentData={selectedResidentData}>
                  <h2>{section}</h2>
                  {/*<img src={ScreenActivity}  />*/}
                  <Paper variant="outlined" className={classes.careplan_tree} >
                    <ResidentActivity residentID={selectedResidentID} handleOpen={handleOpen} handleClose={handleClose}/>
                  </Paper>
              </TabPanel>
              }
              {indexToTabName[page] === 6 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]} residentID={selectedResidentID} residentData={selectedResidentData}>
                <h2>{section}</h2>
                {/*<img src={ScreenDiet}  />*/}
                <Paper variant="outlined" className={classes.careplan_tree} >
                    <ResidentDiet residentID={selectedResidentID} handleOpen={handleOpen} handleClose={handleClose}/>
                  </Paper>
              </TabPanel>
              }
              {indexToTabName[page] === 7 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]} residentID={selectedResidentID} residentData={selectedResidentData}>
                <h2>{section}</h2>
                {/*<img src={ScreenMeds}  />*/}
                <Paper variant="outlined" className={classes.careplan_tree} >
                    <ResidentMeds residentID={selectedResidentID} handleOpen={handleOpen}  handleClose={handleClose}/>
                  </Paper>
              </TabPanel>
              }
              {indexToTabName[page] === 8 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]} residentID={selectedResidentID} residentData={selectedResidentData}>
                <h2>{section}</h2>
                {/*<img src={ScreenLabs}  />*/}
                <Paper variant="outlined" className={classes.careplan_tree} >
                    <ResidentLabs residentID={selectedResidentID} handleOpen={handleOpen} handleClose={handleClose}/>
                  </Paper>
              </TabPanel>
              }
          </Grid>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {/*<h2 id="transition-modal-title">Admin: {residentForm}</h2>*/}
            <Route path={'/residents/'+ selectedResidentID + '/'+ section +'/'+ residentForm + '/form'}  
              render={(props) => <DynamicForm {...props} residentID={residentID} section={section} residentForm={residentForm} handleClose={handleClose}/>}
            />
            
          </div>
        </Fade>
      </Modal>


        </Paper>
        
      </div>
    </div>
  );
}

// component={resident_forms_map[residentForm]} 

/*
  
        <TabPanel value={value} index={2}>
        <h2>{section}</h2>
        <DemoTable admin_section='clinicians' />
        </TabPanel>
        
        <TabPanel value={value} index={3}>
        <h2>{section}</h2>
        <DemoTable admin_section='pharmacies' />
        </TabPanel>
        
        <TabPanel value={value} index={4}>
        <h2>{section}</h2>
        <DemoTable admin_section='prescriptions' />
        </TabPanel>
        
        <TabPanel value={value} index={5}>
        <h2>{section}</h2>
        <DemoTable admin_section='labs' />
        </TabPanel>
        
        <TabPanel value={value} index={6}>
        <h2>{section}</h2>
        <DemoTable admin_section='activities' />
        </TabPanel>
        
        <TabPanel value={value} index={7}>
        <h2>{section}</h2>
        <DemoTable admin_section='vitals' />
        </TabPanel>
        */