/*

This file will receive a resident ID and load all of the Activty from API

Sub sections

1
- activities summary : table of activity type list
 -> select activity type from list
2
- activity plot and hist : table of activity of records with charting
 -> reset  chart, edit record, add record

*/

/*

This file will receive a resident ID and load all of the vitals from API

Sub sections

1
- vitals summary : table of vitals list
 -> select vitals type from list
2
- vital plot and hist : table of history of records with charting
 -> reset  chart, edit record, add record

*/


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from "@material-ui/core/Grid";

import ResidentActivityDetails from './resident_activity/ResidentActivityDetails';
import ResidentActivityTable from './resident_activity/ResidentActivityTable';

/*

This file will receive a resident ID and load all of the chart info from API

Sub sections

1
- active conditions
 -> view all conditions
2
- dagnosis
 -> view all
3
- alergies
 -> view all
4
- notes
 -> view all
5
- active prescriptions
 -> view all
6
- doctors
 -> view all
7
- alerts
 -> view all

*/

const useStyles = makeStyles({
  root: {
    margin: 'auto',
    '& a':{
        textDecoration: 'none',
        color: '#FFF',
    }
  },
  buttonStyle:{
      textDecoration:'none',
      '& button':{
        boxShadow: '2px',
        border:'none',
        backgroundColor: '#64b7ed',
      },
      '& button > span':{
          color: '#fff',
      }
  }
});

export default function ResidentActivity(props) {
  const classes = useStyles();
  const {residentID,handleOpen} = props;
  
  const [selectedActivityType, setSelectedActivityType] = React.useState('Sleep');
  
      const handleSelectActivityType = (selectedActivity) => {
          
          setSelectedActivityType(selectedActivity);
          console.log('selectedActivity',selectedActivity)
  
           // change the url to chart, resident default view
           //history.push(`/residents/${residentID}/vitals/${selectedVital}`);
  /*
          // when click on a resident , select the resdient 
          setSelectedResidentID(residentID);
         
          
          //find the index of the data based on the resident ID
          let dataindex = data.findIndex(p => p[2] == residentID)
      
          //then assign the resident data to the state
          //setSelectedTab(1);
          
          // get thie residdne info and image
          setSelectedResidentData(data[dataindex])
      
      
          setSelectedTab(1);
          setSection(admin_menu_data[1]['link']);
          */
      }
  
  
  return (
    <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} md={6}>
            
            <ResidentActivityTable handleSelectActivityType={handleSelectActivityType}/>
            < Link  className={classes.buttonStyle} to={`/residents/${residentID}/activity/AddActivityType/form`}  >
            <Button onClick={(e) => props.handleOpen(e,'AddActivityType')}  variant="contained"  size="small" > 
                    Add Activty Type
                    </Button>
                </Link>
        </Grid>
        <Grid item xs={12} md={6}>
            <ResidentActivityDetails selectedActivity={selectedActivityType}/>
             
            < Link  className={classes.buttonStyle} to={`/residents/${residentID}/activity/AddActivitySleep/form`}  >
                    <Button onClick={(e) => props.handleOpen(e,'AddActivitySleep')}  variant="contained"  size="small" > 
                    Add Activty Record
                    </Button>
                </Link>            
        </Grid>
    </Grid>
  );
}