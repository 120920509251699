import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {BrowserRouter as Router , Switch, Route, Link} from 'react-router-dom';


import { Formik, Form } from "formik";
import * as yup from "yup";

let NotesSchema = yup.object().shape({
  ResidentId: yup.string().required("This field is required."),
  ClinicianId: yup.string().required("This field is required."),
  Note: yup.string().required("This field is required."),
  DateTime: yup.string().required("This field is required."),
  Priority: yup.string().required("This field is required."),
  photos: yup.string().required("This field is required."),
  attachments: yup.string().required("This field is required."),
  
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    '& h2, h3, h4':{
      textAlign: 'left !important',
      color: '#999 !important',
    }
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  
}));

export default function FormAddNote(props) {
  const classes = useStyles();
  const {residentID, section, residentForm, handler, handleClose} = props;
  
  return (
    <Container component="main" maxWidth="lg">
      <Link to={`/residents/${residentID}/${section}`}  >
      <Button color="primary" onClick={handleClose}>Back to Resident Info </Button>
      </Link>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Note Form
        </Typography>
        <Formik
          initialValues={{
            ResidentId: '',
            ClinicianId: '',
            Note: '',
            DateTime: '',
            Priority: '',
            photos: '',
            attachments: '',
          }}
          validationSchema={NotesSchema}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>

                {/*
                ResidentId	ClinicianId	NDC	MEDID	MedDesc	QtyDispensed	DaysSupply	DateOrdered	DateFilled	Regimen
                */}
              <Grid item xs={12}><h3>Note</h3></Grid>
                
              <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.Note && touched.Note}
                    autoComplete="fname"
                    name="Note"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="Note"
                    label="Note"
                    autoFocus
                    helperText={
                      errors.Note && touched.Note
                        ? errors.Note
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.DateTime && touched.DateTime}
                    autoComplete="mname"
                    name="DateTime"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="DateTime"
                    label="DateTime"
                    autoFocus
                    helperText={
                      errors.DateTime && touched.DateTime
                        ? errors.DateTime
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.Priority && touched.Priority}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="Priority"
                    label="Priority"
                    name="Priority"
                    autoComplete="Priority"
                    helperText={
                      errors.Priority && touched.Priority
                        ? errors.Priority
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}><h3>Photos</h3></Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.photos && touched.photos}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="photos"
                    label="photos"
                    name="photos"
                    autoComplete="photos"
                    helperText={
                      errors.photos && touched.photos ? errors.photos : null
                    }
                  />
                </Grid>

                <Grid item xs={12}><h3>Attachments</h3></Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={errors.attachments && touched.attachments}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="attachments"
                    label="attachments"
                    name="attachments"
                    autoComplete="lname"
                    helperText={
                      errors.attachments && touched.attachments
                        ? errors.attachments
                        : null
                    }
                  />
                </Grid>



              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
