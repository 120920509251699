import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// material ui
import {Icon,Button} from '@material-ui/core';
import Axios from 'axios';  
import { Done, ErrorOutline, Cached, Replay } from '@material-ui/icons';

import {API_CONTROLLER} from '../../services/api_controller'; // import all api methods


const useStyles = makeStyles({
    root: {
        textAlign: 'left',
        //overflow:'auto',
        position: 'relative',
        height: 50,
        whiteSpace: 'nowrap',
        
        '& > div':{
            whiteSpace: 'nowrap',
            position: 'absolute',
            right: 0,
            top: 0,
           // float:'right',
            fontSize: '9pt',
            color: '#567',
        }
    },
      icon:{
          verticalAlign: 'middle',
      },
      api_url:{
          color: '#48f',
          display: 'inline-block',
      }

    })

/*
API AJAX REQUEST COMPONENT
--------------------------
The AJAX request pattern and mechanism with Loading, Error, and IsLoaded sates and rendering.

This component is a basic example of the AJAX request approach in React using function components as opposed to class.

The useEffect allows us to make the request and handle the response states, 
and state updates are what trigger react to re-render the DOM (Document Object Model) webpage interface.

Regarding the pattern, we will be using an API controller object with methods that can be imported and implemented in 
all of our components for different sections of the site, and allow for centralized full list of our API methods and request,
allowing them to be used as needed by corresponding components via import and implementation as int he pattern below.

By creating an API controller where we abstract the endpoints with a stable semantically named methods,
we decouple the backend API from the implementation code, and allows us to easily change or evolve the API while
just updating the URL config in the controller, without having to go through all of our application code and update API calls with cherry picking.
It also makes the front end code easier overall by using this common pattern. 

The controller will reside in its own folder and files and imported as needed in parts of the frontend application component code,
 where ever we need to load data for screens, display, or submit forms, and authenticate.

*/


export default function DataLoaderComponent(props) {
    const classes = useStyles();
    
    // props.section is the namespace for the mapping of the endpoint key
    // its also the key for the state.data object which has a set of keys that 
    // are object to store the api data results
    // props.handleDataUpdate is passed down from the parent, and accepts the input of the 
    // api data result to set the parent state from the centralized child compnent for handling the ajax execution
    // and result handler.
    // create a render component that allows an indicator of the state and perhpas a popup for verbose details

    // props
    // this is the selected section to specify which method to load
    const selectedSection = props.selectedSection;
    // this is the parent handler to allow the parent state to update 
    // update the dataResponse w the API result data for render in table
    // update the loader state in parent for conditional render state of the UI / datatable
    const handleDataLoader = props.handleDataLoader

    // handle ajax data loading error
    const [error, setError] = React.useState(null);
    // handle ajax data loading completion of data load
    const [isLoaded, setIsLoaded] = React.useState(false);
    // handle item list of ajax reponse payload
    const [items, setItems] = React.useState([]);
    const [loading, setLoading]= React.useState(false);

    // this is the dynamic api url for loading data
    let dynamic_url = API_CONTROLLER.base_path + API_CONTROLLER.endpoints[selectedSection][props.method].endpoint;
    let RequestMethod = API_CONTROLLER.endpoints[selectedSection][props.method].method
    
    const {payload, reload, setApiReload} = props;

    const apiReload = () =>{
        console.log("refresh data")
        apiRequest()
    }        
    const apiRequest = () =>{
        setLoading(true)
        setIsLoaded(false)
        // request the api endpoint: dynamically build the url path from the controller object properties
      //fetch(API_CONTROLLER.base_path + API_CONTROLLER.endpoints.residents.get_list.endpoint)
      
      // UPDATE: map the props section to the key for the api controller method,
      // modify so that 'get_list' is a dynamic property passed such as  props.method
      
      //fetch('https://httpbin.org/get') // test public api request
      //let dynamic_url = API_CONTROLLER.base_path + API_CONTROLLER.endpoints[selectedSection][props.method].endpoint;
      //let dynamic_url = API_CONTROLLER.base_path + API_CONTROLLER.endpoints.residents.get_list.endpoint;

      //let api_path = 'http://spectatorhealth.net/residentList' // test hardcode url
      let api_path = dynamic_url ; // use dynamic api path 
      console.log( '> DataLoader Begin: ',selectedSection, props.method,RequestMethod,' | dynamic_url: ', dynamic_url )
      
      // add the method and payload ..
      let requestConfig ={}

      if (RequestMethod === 'GET'){
        requestConfig = {
            //method: props.method,
            //mode:'cors',
            cache: 'no-cache',
            headers:{
                'Content-Type':'application/json'
            },
            //body: props.payload
        }
      }else{
        requestConfig = {
            method: RequestMethod,
            //mode:'cors',
            cache: 'no-cache',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify(props.payload)
        }
      }

      fetch(api_path, requestConfig) // dynamic api endpoint
      
        //fetch(API_CONTROLLER.base_path + API_CONTROLLER.endpoints[selectedSection].get_list.endpoint)
        
        // handle the response  
        .then(res => res.json())
        //process the result data
        .then(
          (result) => {
            // update the state that data is loaded
            setIsLoaded(true);
            // update the list of items with the API response data
            setItems(result.items);
            // run the parent handler and update state
            handleDataLoader(selectedSection, result);
            // confirming api conection
            console.log('API Result',api_path,result);
            console.log( 'DataLoader > Success: ',selectedSection, RequestMethod,' | dynamic_url: ', dynamic_url )
            setLoading(false)
            
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            // update state that data load is completed
            setIsLoaded(true);
            // update state that data load had an error and didnt reteurn the data
            setError(error);
            // run the parent handler and update state
            handleDataLoader(selectedSection, error);
            console.log( 'DataLoader > ERROR: ',selectedSection, RequestMethod,' | dynamic_url: ', dynamic_url,error )
            setLoading(false)
            
        }
        )
    }

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    React.useEffect(() => {
        apiRequest()
    }, [])
  
    
    if (reload === true){
        console.log('> DataLoader reload = ', reload)
        if (loading == false){
            console.log('> DataLoader re-loading API request...', loading)
            apiReload()
        }
       
    }

    const DataLoaderContent = () =>{
        // handle rendering conditionally based on AJAX response
        if (error) {
            // render the error state
        return <div><ErrorOutline color='error'  className={classes.icon} /><span >API Error: {error.message}</span><div className={classes.api_url} >{dynamic_url}</div></div>;
        } else if (!isLoaded) {
            // render the loading progress spinner
        return <div><Cached color='primary'   className={classes.icon} /><span >API Loading...</span><div className={classes.api_url} >{dynamic_url}</div></div>;
        } else {
            // render the completed interface with data loaded, triggered by the state update of isLoaded and !error (no error)
            return <div><Done  style={{color:'0c3'}} className={classes.icon} /><span  >API Success</span>
            <Button aria-label={'Refresh'} onClick={apiReload} color={'primary'} size={'small'}>
                <Replay/>
                Refresh
            </Button>
            <div  className={classes.api_url}>{dynamic_url}</div>
            
            </div>;
            /*return (
            <ul>
            { // map over the items array that has been updated with the API response, and render out the list interface
                items.map(item => (
                <li key={item.name}>
                {item.name} {item.alert}
                </li>
            ))}
            </ul>
        );*/
        }
    }
    return(

        <div className={classes.root}>
        {DataLoaderContent()}
        </div>

    )
    
  }