import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    margin: 'auto',
    '& a':{
        textDecoration: 'none',
        color: '#000',
    }
  },
});

export default function MyAccountCreds(props) {
  const classes = useStyles();
  
  return (
   <div>
       <h2>My Credentials</h2>
   </div>
  );
}