import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MUIDataTable from "mui-datatables";
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';
import {LiveHelp,TransferWithinAStation,SportsHandball,AccessibilityNew,SmsFailed,DirectionsRun} from '@material-ui/icons/'
import Paper  from '@material-ui/core/Paper';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {resident_data} from '../../../../data/resident_data';


import axios from "axios";
import DataLoader from '../../../common/DataLoader';

/*
Resident Doctors: 
- information about current doctor,
- ability to add and view other doctors
*/

// style the component
const useStyles = makeStyles({
  root: {
    width: '96%',
    margin: 'auto',
    padding: 4,
    '& a':{
        textDecoration: 'none',
        color: '#000',
    },
    '& tr:hover .AlertIconWrapper':{
        color: '#f00',
        borderRadius:6,
        border:'1px solid #c00',
        backgroundColor: 'rgba(230,200,200, .3)',
    },
    '& .MuiAccordionDetails-root ':{
      display: 'block',
    },
  },
  AlertIconWrapper:{
    padding: '2px 5px',
    border:'1px solid #ccc',
    borderRadius:6,
    
  },
  AlertIcon:{
    fill: '#c00',
    verticalAlign: 'middle',
    minWidth: 36,
    minHeight: 36,
    width: 36,
    height: 36,
    padding: 2,
    marginLeft: 8,
    marginRight: 10,
  },
  AlertIconTitle:{
    fontWeight: 'bold',
    color: '#c00',
    display: 'inline-block',
    margin: '0 3px',
    verticalAlign: 'middle',
    fontSize: '1.2em',
  },
  heading: {
    //fontSize: theme.typography.pxToRem(15),
    fontSize: '0.9em',
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    //fontSize: theme.typography.pxToRem(15),
    fontSize: '.8em',
    color: '#666',
  },
});

// provide table interface for active alerts
export default function ResidentDoctors(props) {
  const classes = useStyles();

  const {ResidentId} = props;

  const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  

  // load the data from the chart sub section
  // replace this with a call to api via ajax
  const data = resident_data.chart.doctors.data;
  

//AJAX:
  // handle ajax data loading error
  const [error, setError] = React.useState(null);
  // handle ajax data loading completion of data load
  const [isLoaded, setIsLoaded] = React.useState(false);
  // handle item list of ajax reponse payload
  const [items, setItems] = React.useState([]);
  // this is the raw result of the ajax response
  const [ajaxData, setAjaxData] = React.useState([]);
  // this is the actual api result data transfored to datatable format and stored in keys for each section
  const [apiData, setApiData] = React.useState({});
  // this handles recieving the api response and the section, formats the response data, and 
  const handleDataLoader = (section, loaderData) => {
    console.log('resident_data', resident_data);
    // convert the data to headers and rows
    // use the function from other componenet in residents main sectiopn sub components
    // note: target json property of the api response payload dataset according to api response structure
    let result ={}
    let data_array= [];
    // check for error or for success, recieve status from ajax response handler in data loader
    if (loaderData){
        result.data = loaderData.residentClinicians
    }
    else{
      // the result is error
      result.error = JSON.stringify(loaderData)
    }
    // this updates the data with the section key with new api data
    let api_response = result;
    setApiData(api_response);
    setIsLoaded(true)
    console.log('> API Handler: ', apiData);
  };

  // list of columns for datatable
   //const columns = resident_data.chart.alergies.data;

    // set options for datatable
   const options = {
    filterType: 'checkbox',
  };

  const displayPanel = ()=>{
    switch(isLoaded){
      case false:
       return <div>loading data ..</div>
       break;
    case true: 
      //return <div>{JSON.stringify(apiData,null,2)}</div>
      return (
        apiData.data.map((row,index) => {
          //const value = row[column.id];
          return (
            <ExpansionPanel key={index} expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)}>
            <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography className={classes.heading}>{row['FirstName']} {row['LastName']}</Typography>
            <Typography className={classes.secondaryHeading}>{row['ClinicianType']} > {row['Phone']}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>

              <div>
                {row['Phone']} | 
                {row['Email']} | NPI {row['NPI']}
              </div>
              <div>
                {row['AddressLine1']}
                {row['City']}
                {row['State']}
                {row['City']}
                {row['Zip']}
              </div>
            </Typography>
          </ExpansionPanelDetails>
          </ExpansionPanel>
          );
        })
      )
      break;
    }
  }

  // render the component
  return (
   <div className={classes.root}>
     <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        > <h3>Doctors</h3>
      </AccordionSummary>
        <AccordionDetails>
      <Paper variant="outlined" className={classes.screenshot} >
      <DataLoader selectedSection={"residentDoctor"} method={'get_list'} payload={{"ResidentId": ResidentId}} handleDataLoader={handleDataLoader} />
      {displayPanel()}
      </Paper>
      </AccordionDetails>
    </Accordion>
   </div>
  );
}

/*
{apiData.data.map((row,index) => {
          //const value = row[column.id];
          return (
            <ExpansionPanel expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)}>
            <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography className={classes.heading}>{row[0]}</Typography>
            <Typography className={classes.secondaryHeading}>{row[1]}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
            {row}
            </Typography>
          </ExpansionPanelDetails>
          </ExpansionPanel>
          );
        })}
*/