import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { AddCircleOutline} from '@material-ui/icons';
import { shadows } from '@material-ui/system';
import { Done, ErrorOutline, Cached } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AdminResidentForm } from "./forms/AdminResidentForm";
import { AdminUserForm } from "./forms/AdminUserForm";
import { AdminCliniciansForm } from "./forms/AdminCliniciansForm";
import { AdminPharmaciesForm } from "./forms/AdminPharmaciesForm";
import { AdminPrescriptionsForm } from "./forms/AdminPrescriptionsForm";
import { AdminLabsForm } from "./forms/AdminLabsForm";
import { AdminActivityForm } from "./forms/AdminActivityForm";
import { AdminVitalsForm } from "./forms/AdminVitalsForm";

import {BrowserRouter as Router , Switch, Route, Link} from 'react-router-dom';

import {API_CONTROLLER} from '../../services/api_controller';
import DataLoaderComponent from './../common/DataLoader';
import {app_data} from './../../data/app_data';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

let admin_data = {}

/* Residents */
columns.residents = [
    { id: 'ResidentId', label: 'ResidentId', minWidth: 100 },
    { id: 'FirstName', label: 'FirstName', minWidth: 170 },
    { id: 'MiddleName', label: 'MiddleName', minWidth: 100 },
    { id: 'LastName', label: 'LastName', minWidth: 100 },
    { id: 'AddressLine1', label: 'AddressLine1', minWidth: 100 },
    { id: 'AddressLine2', label: 'AddressLine2', minWidth: 100 },
    { id: 'City', label: 'City', minWidth: 100 },
    { id: 'State', label: 'State', minWidth: 100 },
    { id: 'Zip', label: 'Zip', minWidth: 100 },
    { id: 'Gender', label: 'Gender', minWidth: 100 },
    { id: 'DOB', label: 'DOB', minWidth: 100 },
    { id: 'Email', label: 'Email', minWidth: 100 },
    { id: 'Phone', label: 'Phone', minWidth: 100 },
]

function createDataResidents(key,ResidentId,FirstName,MiddleName,LastName,AddressLine1,AddressLine2,City,State,Zip,Gender,DOB,Email,Phone){
    return {key,ResidentId,FirstName,MiddleName,LastName,AddressLine1,AddressLine2,City,State,Zip,Gender,DOB,Email,Phone}
}
//    "ResidentId","FirstName","MiddleName","LastName","AddressLine1","AddressLine2","City","State","Zip","Gender","DOB","Email","Phone"

 admin_data.residents = [
     createDataResidents("R100","R100","Meena","","Sugavanam","7777 Starlight Dr","","La Jolla","CA","92037","F","1928-12-04","Ms1020@gmail.com","8581234567"),
    createDataResidents("R101","R101","Imogene","Kay","Coca","1555 Mesquite","Apt 114","Carlsbad","CA","92009","F","1940-05-15","Coca2000@hotmail.com","7609295555"),
    createDataResidents("R102","R102","Emily","Susan","Schroeder","998 Heather ln","","San Diego","CA","92005","F","1946-12-31","Schorder21@gmail.com","7602315162"),
    createDataResidents("R103","R103","Smrithi ","","Mandhana","2223 Oxnard","","Oceanside","CA","92020","F","1950-01-01","SmirthiMand@gmail.com","9195732132"),
    createDataResidents("R104","R104","Gilda","Helen","Radner","555 Remington ","Unit 200","Chula Vista","CA","92012","F","1950-12-12","GRadner122@gmail.com","7601052999"),
    createDataResidents("R105","R105","Janet ","Edith","Marigold","1600 Delaware","","San Clemente","CA","92152","F","1940-03-25","MarigJ@me.com","8581293600"),
    createDataResidents("R106","R106","Erika","Martha","De Jeong","215 Hygeia","","Encinitas","CA","92007","F","0000-00-00","EMDeJeong@mac.com","7603442121"),

]

/* Users */
columns.users = [
    { id: 'username', label: 'UserName', minWidth: 170 },
    { id: 'name', label: 'Name', minWidth: 100 },
    { id: 'user_type', label: 'Type', minWidth: 100 },
    { id: 'roles', label: 'Roles', minWidth: 100 },
    { id: 'created_date', label: 'Created', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 100 },
]
function createDataUsers(key, username, name, user_type, roles, created_date, status){
    return {username, name, user_type, roles, created_date, status}
}
admin_data.users = [
    createDataUsers("001","Raghu", "Raghu", "test", "admin", "2020-04-01", "active"),
    createDataUsers("002","Ajit", "Ajit", "test", "admin", "2020-04-01", "active"),
    createDataUsers("003","JPBoston", "Josh", "test", "admin", "2020-04-01", "active"),
    createDataUsers("004","Samira", "Samira", "CNA", "CNA", "2020-04-01", "active"),
]


/* Clinicians */
columns.clinicians = [
    { id: 'ClinicianId', label: 'ClinicianId', minWidth: 170 },
    { id: 'ClinicianType', label: 'ClinicianType', minWidth: 100 },
    { id: 'FirstName', label: 'FirstName', minWidth: 100 },
    { id: 'NPI', label: 'NPI', minWidth: 100 },
    { id: 'DEA_NUMBER', label: 'DEA_NUMBER', minWidth: 100 },
]
function createDataClinicians(key,ClinicianId,ClinicianType,NPI,DEA_NUMBER,FirstName,MiddleName,LastName,AddressLine1,AddressLine2,City,State,Zip,Email,Phone
){
    return {key,ClinicianId,ClinicianType,NPI,DEA_NUMBER,FirstName,MiddleName,LastName,AddressLine1,AddressLine2,City,State,Zip,Email,Phone
    }
}
admin_data.clinicians = [
    createDataClinicians("CLN001","CLN001","MD","1234567","","Bart","Edgar","Poses","9579 Via Hermosa","","la Jolla","CA","92037","PosesB@gmail.com","7601234567"),
    createDataClinicians("CLN002","CLN002","HN","7890123","","Samira","","Rashid","2251 Kindred st","Unit 23","Solana Beach","CA","92040","SRashid06@gmail.com","8582213211"),
    createDataClinicians("CLN003","CLN003","MT","9998725","","Alysha","Ann","Keys","321 Stratmore","unit 6","Del Mar","CA","92104","AAK2150@hotmail.com","9193432222"),
    createDataClinicians("CLN004","CLN004","CNA","5552121","","Jamie","Maria","Ramon","438 Bishop ln","unit 100","Pacific Beach","CA","92021","RamonJam@hotmail.com","7602223000"),
    createDataClinicians("CLN005","CLN005","CNA","6669200","","DeVante","Bernard","Burns","2334 Frigate rd","","Mission Beach","CA","92044","Devante.Burns@gmail.com","8582123090"),
]

/* Pharmacies */
columns.pharmacies = [
    { id: 'name', label: 'Pharmacy Name', minWidth: 170 },
    { id: 'group', label: 'Group', minWidth: 170 },
    { id: 'contact', label: 'Contact', minWidth: 170 },
    { id: 'status', label: 'Status', minWidth: 170 },
]
function createDataPharmacies(name, group, contact, status){
    return {name, group, contact, status}
}
admin_data.pharmacies = [
    createDataPharmacies("Wallgreens", "group 1", "bob smith", "active"),
    createDataPharmacies("CVS", "group 1", "Jennifer Lao", "active"),
    createDataPharmacies("Wegmans", "group 2", "Vital Manerah", "active"),
]

/* Prescriptions */
columns.prescriptions = [
    {id: 'ResidentId', label: 'ResidentId', minWidth: 90 },
    {id: 'ClinicianId', label: 'ClinicianId', minWidth: 90 },
    {id: 'NDC', label: 'NDC', minWidth: 70 },
    {id: 'MEDID', label: 'MEDID', minWidth: 70 },
    {id: 'MedDesc', label: 'MedDesc', minWidth: 200 },
    {id: 'QtyDispensed', label: 'QtyDispensed', minWidth: 70 },
    {id: 'DaysSupply', label: 'DaysSupply', minWidth: 70 },
    {id: 'DateOrdered', label: 'DateOrdered', minWidth: 170 },
    {id: 'DateFilled', label: 'DateFilled', minWidth: 170 },
    {id: 'Regimen', label: 'Regimen', minWidth: 170 },
]
function createDataPrescriptions(ResidentId,ClinicianId,NDC,MEDID,MedDesc,QtyDispensed,DaysSupply,DateOrdered,DateFilled,Regimen){
    return {ResidentId,ClinicianId,NDC,MEDID,MedDesc,QtyDispensed,DaysSupply,DateOrdered,DateFilled,Regimen}
}
//"ResidentId","ClinicianId","NDC",MEDID,"MedDesc",QtyDispensed,DaysSupply,"DateOrdered","DateFilled","Regimen"

admin_data.prescriptions = [
    createDataPrescriptions( "R001","CLN001","65162110105",250621,"ibuprofen 400 mg",30.00,30,"2020-03-01","2020-03-03","1 tablet after lunch"),
    createDataPrescriptions( "R001","CLN001","72124000201",574777,"Tudorza Pressair 400 mcg/actuation breath activated",60.00,10,"2020-03-01","2020-03-01","2 puffs a day"),
    createDataPrescriptions( "R001","CLN001","16714068401",209275,"simvaststain 40 mg tablet",30.00,30,"2020-03-01","2020-03-02","1 tablet daily"),
    createDataPrescriptions( "R001","CLN001","59212056210",163111,"Plaquenil 200 mg tablet",100.00,100,"2020-10-01","2020-11-01","1 tablet daily"),
    createDataPrescriptions( "R001","CLN001","63629147202",227625,"methotrexate sodium 2.5 mg tablet",30.00,30,"2020-03-02","2020-03-15","1 tablet daily"),

]

/* Labs */
columns.labs = [
    {id: 'ResidentId', label: 'ResidentId', minWidth: 100},
    {id: 'OrderingClinicianId', label: 'OrderingClinicianId', minWidth: 100},
    {id: 'LabOrderNo', label: 'LabOrderNo', minWidth: 100},
    {id: 'LOINC', label: 'LOINC', minWidth: 100},
    {id: 'LOINDesc', label: 'LOINDesc', minWidth: 100},
    {id: 'Result', label: 'Result', minWidth: 100},
    {id: 'UnitOfeasure', label: 'UnitOfeasure', minWidth: 100},
    {id: 'DateOrdered', label: 'DateOrdered', minWidth: 100},
    {id: 'Cost', label: 'Cost', minWidth: 100},
]
function createDataLabs(ResidentId,OrderingClinicianId,LabOrderNo,LOINC,LOINDesc,Result,UnitOfeasure,DateOrdered,Cost){
    return {ResidentId,OrderingClinicianId,LabOrderNo,LOINC,LOINDesc,Result,UnitOfeasure,DateOrdered,Cost}
}
admin_data.labs = [
    createDataLabs("R100","CLN001","1065","2093-3","Cholesterol,Total","225","mg/dl","2020-03-02",25.00),
    createDataLabs("R100","CLN001","1453","4548-4","Hemaglobin A1C","7","%","0000-00-00",30.10),
    createDataLabs("R100","CLN001","505321","65759-3","Lymphocyte Act. Profile","500","/uL","2020-03-20",28.10),
    createDataLabs("R100","CLN001","3004","2160-0","Creatinine clearance","95","ml/min","2020-03-02",35.00),
    createDataLabs("R100","CLN001","1172","2571-8","Triglycerides","300","mg/dl","2020-04-05",26.50),
    createDataLabs("R100","CLN001","1032","2345-7","Glucose","120","mg/dl","2020-03-01",10.00),
]

/* Activities */
columns.activities = [

    {id: 'ActivityType', label: 'ActivityType', minWidth: 100},
    {id: 'ActivityDesc', label: 'ActivityDesc', minWidth: 100},
    {id: 'icon', label: 'icon', minWidth: 100},
]
function createDataActivity(ActivityType,ActivityDesc,icon){
    return {ActivityType,ActivityDesc,icon}
}
admin_data.activities = [
    createDataActivity("ACT001","Sitting", "icon-Sitting"),
    createDataActivity("ACT002","Walking", "icon-Walking"),
    createDataActivity("ACT003","Sleeping", "icon-Sleeping"),
    createDataActivity("ACT004","Resting", "icon-Resting"),
    createDataActivity("ACT005","Bathroom", "icon-Bathroom"),
    createDataActivity("ACT007","Eating", "icon-Eating"),
    createDataActivity("ACT008","Leisure", "icon-Leisure"),
    createDataActivity("ACT009","Wheelchair", "icon-Wheelchair"),
]

/* Vitals */
columns.vitals = [
    {id: 'VitalType', label: 'VitalType', minWidth: 100},
    {id: 'VitalDesc', label: 'VitalDesc', minWidth: 100},
    {id: 'icon', label: 'icon', minWidth: 100},
]
function createDataVitals(VitalType,VitalDesc, icon){
    return {VitalType,VitalDesc, icon}
}
admin_data.vitals = [
    createDataVitals("VT100","HeartRate", "icon-HeartRate"),
    createDataVitals("VT200","Blood Pressure", "icon-BloodPressure"),
    createDataVitals("VT300","Blood Oxygen Level", "icon-BloodOxygen"),
    createDataVitals("VT400","Respiration Rate", "icon-RespirationRate"),
    createDataVitals("VT500","Blood Sugar", "icon-BloodSugar"),
]

const admin_form_map = {
    "residents" : <AdminResidentForm/>,
    "users" : <AdminUserForm/>,
    "clinicians" : <AdminCliniciansForm/>,
    "pharmacies" : <AdminPharmaciesForm/>,
    "prescriptions" : <AdminPrescriptionsForm/>,
    "labs" : <AdminLabsForm/>,
    "activities" : <AdminActivityForm/>,
    "vitals" : <AdminVitalsForm/>,
}
const admin_forms_map = {
  "residents" : AdminResidentForm,
  "users" : AdminUserForm,
  "clinicians" : AdminCliniciansForm,
  "pharmacies" : AdminPharmaciesForm,
  "prescriptions" : AdminPrescriptionsForm,
  "labs" : AdminLabsForm,
  "activities" : AdminActivityForm,
  "vitals" : AdminVitalsForm,
}


// styles
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 600,
  },
  CreateItemButton:{
    textAlign: 'left',
    '& a': {
      textDecoration: 'none',
    }
    
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  
  },
  paper: {
    backgroundColor: '#eee',
    border: '2px solid #789',
    boxShadow: '5px 5px 10px #444',
    padding: '2px 4px',
    borderRadius: 4,
  },
  dataLoaderStatusBadge:{
    padding: 3,
    color: '#666',
    fontSize: '.8em',
    float: 'right',
    verticalAlign: 'middle',
    '& > div':{
      verticalAlign: 'middle',
    },
    '& svg':{
      verticalAlign: 'middle',
    },
    
  }
});

export default function DemoTable (props) {
  const classes = useStyles();
  
  //let admin_section = 'residents'
  //let admin_section = 'clinicians'
  let admin_section = props.admin_section

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [open, setOpen] = React.useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    /*
  //AJAX:
  // handle ajax data loading error
  const [error, setError] = React.useState(null);
  // handle ajax data loading completion of data load
  const [isLoaded, setIsLoaded] = React.useState(false);
  // handle item list of ajax reponse payload
  const [items, setItems] = React.useState([]);
  
  // this is the raw result of the ajax response
  const [ajaxData, setAjaxData] = React.useState([]);

  // this is the actual api result data transfored to datatable format and stored in keys for each section
  const [apiData, setApiData] = React.useState([]);
  

  // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    React.useEffect(() => {
      // request the api endpoint: dynamically build the url path from the controller object properties
      let dynamic_url = API_CONTROLLER.base_path + API_CONTROLLER.endpoints[admin_section].get_list.endpoint;
      console.log( 'DemoTable > admin_section: ',admin_section, ' | dynamic_url: ', dynamic_url )
      console.log('> admin_data', admin_data);
      console.log('> columns', columns);
      fetch(dynamic_url)
      //fetch('https://httpbin.org/get')
        // handle the response  
        .then(res => res.json())
        //process the result data
        .then(
          (result) => {
            // update the state that data is loaded
            setIsLoaded(true);
            // update the list of items with the API response data
            //setItems(result.items);
            setAjaxData(result);
            
            //console.log('AJAX success',result.items)
            console.log('AJAX success',dynamic_url,result)
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            // update state that data load is completed
            setIsLoaded(true);
            // update state that data load had an error and didnt reteurn the data
            setError(error);
            console.log('AJAX error',dynamic_url, error)
            console.log( 'DemoTable > admin_section: ',admin_section, ' | dynamic_url: ', dynamic_url )
      
            
          }
        )
    }, [])
    */


    /*
    when changing the page, make a request via the API_CONTROLLER mapping and update the state with the data.
    Then process the data formatting and set state again.
    Add the isLoading spinner to the system to check if its loading.
    Since we have a common UI, creating this once, will handle for all of the admin tables.

    create a cache of the data once loaded and fallback on that if cant load

    create a fallback for whent eh data cant load from api and load from static file,
    - show indicator

    -use the handlers for updating the state from url and tab select to trigger the ajax load
    - use the namespace for the url/tab of the selected section to map to the same namespace for the data loader api copntroller method
    - execute the loader to load the controller method and save the result data to the state object of the data
      using the key from the url/tab to be the key for the data object to load that data result into.
    - this provides a lookup from which tab to which api method to which state object key to store the data
       and that same key to access the data in the datatable ui.


        create a new state.data
        create a handler for update setData to set the data state
        pass that funciton down as props to the DataLoader component
        update data namespace in parent to now link all dybnamc dat to the state.data[key] object
        instead iof the curent static data
        implement the dataloader component in the render jsx section, place at top right 
        provide another spinner for the state of the datatable that updates based on the state from dataloader 
        that is passed back up to the parent by implementing the parent functions to set the parent state and trigger re render
        dunzo


    */



  function ConvertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr)
  
    let result =  array.map(it => {
      return Object.values(it)
    })

	return result
  }

/*
AJAX integration:

DataLoader componenent is a method of hanlding calls to the API and passing a callbacks
 from parent to handle success and error results of the API repsonse.  Success returns the data from the API
 response and sets it to the current state of the parent component.  

 The parent component maintains an object , with all of the API data responses, using the application section name
 as the key for storing the API response and caching the data / using name space for mapping to the correct data of 
 the current admin seciton.  The app can cleanup other keys when switching secitons to delete any UI frontend storage
  of data that may be sensitive.
 
  The DataLoader component implementation uses several 
  - useState elements, 
  - a success and error handler, or a single handler, which calls the set state of the values in the API response to 
  update the application data and UI,
  - recieves the application section namespace and method and payload to configure the DataLoader with the correct 
  keys to create the dynamic API request path.
  - the correct keys in the api_controller.js file corresponding to the object property path in the API_CONTROLLER 
  with corresponding values to the desired API URL endpoint and http request method.
  - for dynamic data-tables, the resonse data is formatted into headers and rows for supplying the Table component,
  using the common function convert json dataset to csv table headers and rows.
  - any mapping of namespacing can be hanlded with map objects if required for any namespace traversal of dyanmic objects.



*/


//AJAX:
  const [apiReload, setApiReload] = React.useState(false)
  // handle ajax data loading error
  const [error, setError] = React.useState(null);
  // handle ajax data loading completion of data load
  const [isLoaded, setIsLoaded] = React.useState(false);
  // handle item list of ajax reponse payload
  const [items, setItems] = React.useState([]);
  // this is the raw result of the ajax response
  const [ajaxData, setAjaxData] = React.useState([]);
  // this is the actual api result data transfored to datatable format and stored in keys for each section
  const [apiData, setApiData] = React.useState([]);
// helper to transofrm data for UI
function ConvertToCSV(arr) {
  if (arr.length>0){
    const array = [Object.keys(arr[0])].concat(arr)
    let result =  array.map(it => {
      return Object.values(it)
    })
    console.log('> ConvertToCSV',result)
    return result
  }else{
    return [[],[]]
  }
}
  // this handles recieving the api response and the section, formats the response data, and 
  const handleDataLoader = (section, loaderData) => {
    console.log('> AdminSection handleDataLoader ',section, loaderData, apiReload);
    //setApiReload(false);
    
    // convert the data to headers and rows
    // use the function from other componenet in residents main sectiopn sub components
    // note: target json property of the api response payload dataset according to api response structure
    let result ={}
    let data_array= [];
    let response;
    // check for error or for success, recieve status from ajax response handler in data loader
    if (loaderData.residents){
      
      response = loaderData.residents;
      //frmt_response = loaderData[section];


      // the result is succesful actual data return
      data_array = ConvertToCSV(response);
      // create a formatted result for storing in the data section key and processing by datatable
      // slice the headers of the top of arrar
      result.headers = data_array.shift(1);
      result.data = data_array;
      console.log('> transformed to CSV ------------')
      console.log('> Headers', result.headers , '> Data',data_array)
      
    }
    if (loaderData.users){
      
      response = loaderData.users;
      //frmt_response = loaderData[section];


      // the result is succesful actual data return
      data_array = ConvertToCSV(response);
      // create a formatted result for storing in the data section key and processing by datatable
      // slice the headers of the top of arrar
      result.headers = data_array.shift(1);
      result.data = data_array;
      console.log('> transformed to CSV ------------')
      console.log('> Headers', result.headers , '> Data',data_array)
      
    }
    if (loaderData.clinicians){
      
      response = loaderData.clinicians;
      //frmt_response = loaderData[section];


      // the result is succesful actual data return
      data_array = ConvertToCSV(response);
      // create a formatted result for storing in the data section key and processing by datatable
      // slice the headers of the top of arrar
      result.headers = data_array.shift(1);
      result.data = data_array;
      console.log('> transformed to CSV ------------')
      console.log('> Headers', result.headers , '> Data',data_array)
      
    }
    if (loaderData.pharmacies){
      
      response = loaderData.pharmacies;
      //frmt_response = loaderData[section];


      // the result is succesful actual data return
      data_array = ConvertToCSV(response);
      // create a formatted result for storing in the data section key and processing by datatable
      // slice the headers of the top of arrar
      result.headers = data_array.shift(1);
      result.data = data_array;
      console.log('> transformed to CSV ------------')
      console.log('> Headers', result.headers , '> Data',data_array)
      
    }
    if (loaderData.prescriptions){
      
      response = loaderData.prescriptions;
      //frmt_response = loaderData[section];


      // the result is succesful actual data return
      data_array = ConvertToCSV(response);
      // create a formatted result for storing in the data section key and processing by datatable
      // slice the headers of the top of arrar
      result.headers = data_array.shift(1);
      result.data = data_array;
      console.log('> transformed to CSV ------------')
      console.log('> Headers', result.headers , '> Data',data_array)
      
    }
    if (loaderData.vitalTypes){
      
      response = loaderData.vitalTypes;
      //frmt_response = loaderData[section];


      // the result is succesful actual data return
      data_array = ConvertToCSV(response);
      // create a formatted result for storing in the data section key and processing by datatable
      // slice the headers of the top of arrar
      result.headers = data_array.shift(1);
      result.data = data_array;
      console.log('> transformed to CSV ------------')
      console.log('> Headers', result.headers , '> Data',data_array)
      
    }


    else{
      // the result is error
      result.error = loaderData
    }
    // this updates the data with the section key with new api data
    let api_response = result;
    setApiData(api_response);
    setIsLoaded(true)
    setApiReload(false)
    
    console.log('> apiData ', apiData ,apiReload);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // handle form submit for the admin forms
  const handleSubmit = function(values){
    
    //event.preventDefault();
    
        // update the logged in user state
        //setIsLoggedIn(true);
        //setActiveUser(user);
        console.log('> DemoTable Form Submit Payload', values);
        setIsLoaded(false);
        handleClose()
        setApiReload(true);

  }

  let FormName = admin_forms_map[admin_section];

  /*
  // handle rendering conditionally based on AJAX response
  if (error) {
      // render the error state
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
      // render the loading progress spinner
    return <div><Cached color='primary' />AJAX Loading...</div>;
  } else {
      // render the completed interface with data loaded, triggered by the state update of isLoaded and !error (no error)
      */



      const displayPanel = ()=>{
        switch(isLoaded){
          case false:
           return <div>
             <CircularProgress  />
             <div>loading data ..</div>
             </div>
           break;
        case true: 
          //return <div>{JSON.stringify(apiData,null,2)}</div>
          if (apiData.headers == undefined){
            return (
              <div>
                Data Error
              </div>
            )
          }else{
          return (
                <div>

                <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {
                        apiData.headers.map((column) => (
                        //apiData[admin_section].headers.map((column) => (
                        <TableCell
                          key={column}
                          align={'left'}
                          style={{ minWidth: 100 }}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      apiData.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      // apiDate[admin_section].rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row[0]}>
                          {row.map((field) => {
                            const value = field;
                            return (
                              <TableCell key={field} align={'left'}>
                                {`${field}`}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={apiData.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              />
               <div className={classes.CreateItemButton}>
               <Link to={'/admin/'+ admin_section + '/form'}  >
                 <Button color="primary" onClick={handleOpen} variant="outlined" size="small" boxShadow={2} style={{ border: '1px solid' }} or> 
                   <AddCircleOutline/>
                   Add Record
                   </Button>
               </Link>
             </div>
             </div>
          )}
          break;
        }
      }


  return (
      <Paper className={classes.root}>
        <div className={classes.dataLoaderStatusBadge}>
          <DataLoaderComponent selectedSection={props.admin_section} method={'get_list'} handleDataLoader={handleDataLoader} payload={{ "ResidentId": "R100"}} reload={apiReload}/>
          

        </div>
      {/* on dataload success */}
      
      {displayPanel()}
       
       
        
       

        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {/*<h2 id="transition-modal-title">Admin: {admin_section}</h2>*/}
            <Link to={`/admin/${admin_section}`}  >
            {/* pass in handle close */}
            <Button color="primary" onClick={handleClose}>Back to Admin</Button>
            </Link>
            <Route path={'/admin/'+ admin_section + '/form'}  component={() => <FormName handleSubmit={handleSubmit} admin_section={admin_section} />}  />
            
            {/* render={props => <FormName {...props} />} */}

          </div>
        </Fade>
      </Modal>

       {/* close data load success */}

      </Paper>


  );
//}
}
/*
          <Route path={"/admin/"+ admin_section } exact>
          
      </Route>

        <Route path={'/admin/'+ admin_section + '/form'}  component={admin_forms_map[admin_section]} />

*/