

//import LinkIcon from '@material-ui/icons/Link';
//import LinkIcon from '@material-ui/icons/Launch';
//import LinkIcon from '@material-ui/icons/ChevronRight';
import LinkIcon from '@material-ui/icons/ArrowRight';


export  const routes_info = [
    { section: 'General',
        routes: [
            {
                title: 'home',
                link: '/',
                icon: LinkIcon,
                status: 'draft',
            },
            {
                title: 'about',
                link: '/about',
                icon: LinkIcon,
                status: 'pending',
            },
            {
                title: 'info',
                link: '/info',
                icon: LinkIcon,
                status: 'pending',
            },
            {
                title: 'contact',
                link: '/contact',
                icon: LinkIcon,
                status: 'pending',
            },
        ],
    },

{ section: 'Residents',
routes: [
    {
        title: '/residents/chart/medical/',
        link: '/residents/chart',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/chart/family/',
        link: '/residents/chart',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/chart/insurance/',
        link: '/residents/chart',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/chart/personal/',
        link: '/residents/chart',
        icon: LinkIcon,
        status: 'placed_design',
    },

    {
        title: '/residents/careplan/',
        link: '/residents/careplan/',
        icon: LinkIcon,
        status: 'placed_design',
    },

    {
        title: '/residents/vitals/heartrate',
        link: '/residents/vitals/',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/vitals/bloodpressure',
        link: '/residents/vitals/',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/vitals/oxygensaturation',
        link: '/residents/vitals/',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/vitals/respiration',
        link: '/residents/vitals/',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/vitals/glucose',
        link: '/residents/vitals/',
        icon: LinkIcon,
        status: 'placed_design',
    },

    {
        title: '/residents/notes/',
        link: '/residents/notes/',
        icon: LinkIcon,
        status: 'placed_design',
    },

    {
        title: '/residents/activity/bathroom/',
        link: '/residents/activity/',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/activity/sleep/',
        link: '/residents/activity/',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/activity/meals/',
        link: '/residents/activity/',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/activity/physical/',
        link: '/residents/activity/',
        icon: LinkIcon,
        status: 'placed_design',
    },

    {
        title: '/residents/diet/',
        link: '/residents/diet/',
        icon: LinkIcon,
        status: 'placed_design',
    },

    {
        title: '/residents/meds/',
        link: '/residents/meds/',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/meds/refill',
        link: '/residents/meds',
        icon: LinkIcon,
        status: 'placed_design',
    },

    {
        title: '/residents/labs/',
        link: '/residents/labs/',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/labs/reorder',
        link: '/residents/labs',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/labs/edit',
        link: '/residents/labs',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/labs/create',
        link: '/residents/labs',
        icon: LinkIcon,
        status: 'placed_design',
    },
    {
        title: '/residents/labs/view',
        link: '/residents/labs',
        icon: LinkIcon,
        status: 'placed_design',
    },
]},


{ section: 'Facilities',
    routes: [
    {
        title: '/facilties/',
        link: '/facilties/',
        icon: LinkIcon,
        status: 'placeholder',
    },
]},
{ section: 'Admin',
routes:[
    {
        title: 'admin > residents > all',
        link: '/admin/residents/',
        icon: LinkIcon,
        status: 'draft',
    },
    {
        title: 'admin > residents > create',
        link: '/admin/residents/form',
        icon: LinkIcon,
        status: 'draft',
    },
    {
        title: 'admin > residents > view',
        link: '/admin/residents/:id/view',
        icon: LinkIcon,
        status: 'pending',
    },
    {
        title: 'admin > residents > edit',
        link: '/admin/residents/form',
        icon: LinkIcon,
        status: 'draft',
    },
    {
        title: 'admin > users > all',
        link: '/admin/users',
        icon: LinkIcon,
        status: 'draft',
    },
    {
        title: 'admin > users > create',
        link: '/admin/users/form',
        icon: LinkIcon,
        status: 'draft',
    },
    {
        title: 'admin > users > view',
        link: '/admin/users/:id/view',
        icon: LinkIcon,
        status: 'pending',
    },
    {
        title: 'admin > users > edit',
        link: '/admin/users/form',
        icon: LinkIcon,
        status: 'draft',
    },
]
},

{ section: 'MyAccount',
    routes: [
    {
        title: '/myaccount/',
        link: '/myaccount/',
        icon: LinkIcon,
        status: 'draft',
    },
    {
        title: '/myaccount/contact',
        link: '/myaccount/contact',
        icon: LinkIcon,
        status: 'placeholder',
    },
    {
        title: '/myaccount/roles/',
        link: '/myaccount/roles/',
        icon: LinkIcon,
        status: 'placeholder',
    },
    {
        title: '/myaccount/org/',
        link: '/myaccount/org/',
        icon: LinkIcon,
        status: 'placeholder',
    },
    {
        title: '/myaccount/creds/',
        link: '/myaccount/creds/',
        icon: LinkIcon,
        status: 'placeholder',
    },
    {
        title: '/myaccount/plan/',
        link: '/myaccount/plan/',
        icon: LinkIcon,
        status: 'placeholder',
    },
    {
        title: '/myaccount/history/',
        link: '/myaccount/history/',
        icon: LinkIcon,
        status: 'placeholder',
    },
    {
        title: '/myaccount/billing',
        link: '/myaccount/billing',
        icon: LinkIcon,
        status: 'placeholder',
    },
    ]
},
];

/*
const routes_menu = {
    'admin': {
        routes:[
            {
                path: '/admin/residents',
            }
        ]
    },
    'residents': {},
    'facilities': {},
    'home': {},
    'myaccount': {},
    'settings': {},
}
*/

/*
Make a list of the paths and titles in json object
iterate over the list and render all of the paths

make a component for list section 
- section parent
- pages
- sub pages

Routes:
--------------------------------

/admin/residents/
/admin/residents/create
/admin/residents/:id/view
/admin/residents/:id/edit

/admin/users/
/admin/clinicians/
/admin/pharmacies/
/admin/vital_types/
/admin/activity_types/
/admin/overview

--------------------------------
/residents/chart/medical/
/residents/chart/family/
/residents/chart/insurance/
/residents/chart/personal/

/residents/careplan/
/residents/careplan/list
/residents/careplan/:id/view
/residents/careplan/:id/edit

/residents/vitals/heartrate
/residents/vitals/bloodpressure
/residents/vitals/oxygensaturation
/residents/vitals/respiration
/residents/vitals/glucose

/residents/notes/
/residents/notes/create
/residents/notes/:id/view
/residents/notes/:id/edit

/residents/activity/bathroom/
/residents/activity/sleep/
/residents/activity/meals/
/residents/activity/physical/

/residents/diet/
/residents/diet/create
/residents/diet/:id/edit

/residents/meds/
/residents/meds/createprescription
/residents/meds/:id/refill
/residents/meds/:id/edit
/residents/meds/:id/view

/residents/labs/
/residents/labs/create
/residents/labs/:id/reorder
/residents/labs/:id/edit
/residents/labs/:id/view


--------------------------------
/facilties/overview/


--------------------------------
/myaccount/overview/
/myaccount/contactinfo
/myaccount/roles/
/myaccount/organization/
/myaccount/credentials/
/myaccount/plan/
/myaccount/history/
/myaccount/billing
--------------------------------


/sitemap/


*/