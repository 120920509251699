import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {BrowserRouter as Router , Switch, Route, Link} from 'react-router-dom';


import { Formik, Form } from "formik";
import * as yup from "yup";

let PrescriptionsSchema = yup.object().shape({
  ResidentId: yup.string().required("This field is required."),
  ClinicianId: yup.string().required("This field is required."),
  NDC: yup.string().required("This field is required."),
  MEDID: yup.string().required("This field is required."),
  MedDesc: yup.string().required("This field is required."),
  QtyDispensed: yup.string().required("This field is required."),
  DaysSupply: yup.string().required("This field is required."),
  DateOrdered: yup.string().required("This field is required."),
  DateFilled: yup.string().required("This field is required."),
  Regimen: yup.string().required("This field is required."),
  
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    '& h2, h3, h4':{
      textAlign: 'left !important',
      color: '#999 !important',
    }
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  
}));

export const AdminPrescriptionsForm = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="lg">
      
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Prescriptions Form
        </Typography>
        <Formik
          initialValues={{
            ResidentId: "",
            ClinicianId: "",
            NDC: "",
            MEDID: "",
            MedDesc: "",
            QtyDispensed: "",
            DaysSupply: "",
            DateOrdered: "",
            DateFilled: "",
            Regimen: "",
          }}
          validationSchema={PrescriptionsSchema}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>

                {/*
                ResidentId	ClinicianId	NDC	MEDID	MedDesc	QtyDispensed	DaysSupply	DateOrdered	DateFilled	Regimen
                */}
              <Grid item xs={12}><h3>Prescription Info</h3></Grid>
                
              <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.ResidentId && touched.ResidentId}
                    autoComplete="fname"
                    name="ResidentId"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="ResidentId"
                    label="ResidentId"
                    autoFocus
                    helperText={
                      errors.ResidentId && touched.ResidentId
                        ? errors.ResidentId
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.ClinicianId && touched.ClinicianId}
                    autoComplete="mname"
                    name="ClinicianId"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="ClinicianId"
                    label="ClinicianId"
                    
                    helperText={
                      errors.ClinicianId && touched.ClinicianId
                        ? errors.ClinicianId
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.NDC && touched.NDC}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="NDC"
                    label="NDC"
                    name="NDC"
                    autoComplete="lname"
                    helperText={
                      errors.NDC && touched.NDC
                        ? errors.NDC
                        : null
                    }
                  />
                </Grid>
               
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.MEDID && touched.MEDID}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="MEDID"
                    label="MEDID"
                    name="MEDID"
                    autoComplete="MEDID"
                    helperText={
                      errors.MEDID && touched.MEDID ? errors.MEDID : null
                    }
                  />
                </Grid>

                <Grid item xs={12}><h3>Medication Info</h3></Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={errors.QtyDispensed && touched.QtyDispensed}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="QtyDispensed"
                    label="QtyDispensed"
                    name="QtyDispensed"
                    autoComplete="lname"
                    helperText={
                      errors.QtyDispensed && touched.QtyDispensed
                        ? errors.QtyDispensed
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    error={errors.DaysSupply && touched.DaysSupply}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="DaysSupply"
                    label="DaysSupply"
                    name="DaysSupply"
                    autoComplete="DaysSupply"
                    helperText={
                      errors.DaysSupply && touched.DaysSupply
                        ? errors.DaysSupply
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    error={errors.DateOrdered && touched.DateOrdered}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="DateOrdered"
                    label="Date Ordered"
                    name="DateOrdered"
                    type="date"
                    autoComplete="DateOrdered"
                    helperText={
                      errors.DateOrdered && touched.DateOrdered ? errors.DateOrdered : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={errors.DateFilled && touched.DateFilled}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="DateFilled"
                    label="Date Filled"
                    name="DateFilled"
                    type="date"
                    autoComplete="DateFilled"
                    helperText={
                      errors.DateFilled && touched.DateFilled ? errors.DateFilled : null
                    }
                  />
                </Grid>

                
                <Grid item xs={12} sm={12}>
                  <TextField
                    error={errors.Regimen && touched.Regimen}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="Regimen"
                    label="Regimen"
                    name="lastRegimenName"
                    autoComplete="Regimen"
                    helperText={
                      errors.Regimen && touched.Regimen
                        ? errors.Regimen
                        : null
                    }
                  />
                </Grid>



              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
