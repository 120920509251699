import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import NotesIcon from '@material-ui/icons/Notes';
import LocalHospital from '@material-ui/icons/LocalHospital';
import RepeatOne from '@material-ui/icons/RepeatOne';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function HistoryList() {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <NotesIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Updated note for Mary" secondary="Jan 9, 2020" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <RepeatOne />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Renewed Prescription" secondary="Jan 7, 2020" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <LocalHospital />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Added careplan" secondary="July 20, 2020" />
      </ListItem>
    </List>
  );
}
