/*

This file will receive a resident ID and load all of the careplan data

Sub sections

1
- care plan display
 -> edit careplan
2
- resident informaiton
 -> view PDF

*/



import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from "@material-ui/core/Grid";

import ResidentCareplanDisplay from './resident_careplan/ResidentCareplanDisplay'
/*

This file will receive a resident ID and load all of the chart info from API

Sub sections

1
- active conditions
 -> view all conditions
2
- dagnosis
 -> view all
3
- alergies
 -> view all
4
- notes
 -> view all
5
- active prescriptions
 -> view all
6
- doctors
 -> view all
7
- alerts
 -> view all

*/

const useStyles = makeStyles({
  root: {
    textAlign: 'left',
    //margin: 'auto',
    '& a':{
        textDecoration: 'none',
        color: '#FFF',
    }
  },
  buttonStyle:{
      textDecoration:'none',
      '& button':{
        boxShadow: '2px',
        border:'none',
        backgroundColor: '#64b7ed',
      },
      '& button > span':{
          color: '#fff',
      }
  }
});

export default function ResidentCareplan(props) {
  const classes = useStyles();
  
  return (
    <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} md={9}>
            <div>
                <h3>Careplan Overview</h3>
            </div>
            <hr/>
            <ResidentCareplanDisplay/>
        </Grid>
        <Grid item xs={12} md={3}>
            <div>
                <h3>Resident Info</h3>
            </div>
            <hr/>
            <div>
                <h3>Careplan Config</h3>
            </div>
            <div>Careplan Options</div>
            <div>Sub Info</div>
            <div>
            <hr/>
            < Link  className={classes.buttonStyle} to={'/'}  >
                <Button  variant="contained"  size="small"> 
                View PDF
                </Button>
            </Link>
            </div>
        </Grid>
    </Grid>
  );
}