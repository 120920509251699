
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from "@material-ui/core/Grid";

import ResidentActiveConditions from './resident_chart/ResidentActiveConditions'
import ResidentDiagnosis from './resident_chart/ResidentDiagnosis'
import ResidentAllergies from './resident_chart/ResidentAllergies'
import ResidentNotes from './resident_chart/ResidentNotes'
import ResidentActivePrescriptions from './resident_chart/ResidentActivePrescriptions'
import ResidentDoctorsExpandable from './resident_chart/ResidentDoctorsExpandable'
import ResidentAlerts from './resident_chart/ResidentAlerts'
/*

This file will receive a resident ID and load all of the chart info from API

Sub sections

1
- active conditions
 -> view all conditions
2
- dagnosis
 -> view all
3
- alergies
 -> view all
4
- notes
 -> view all
5
- active prescriptions
 -> view all
6
- doctors
 -> view all
7
- alerts
 -> view all

*/

const useStyles = makeStyles({
  root: {
    //margin: 'auto',
    '& a':{
        textDecoration: 'none',
        color: '#FFF',
    }
  },
  buttonStyle:{
      textDecoration:'none',
      '& button':{
        boxShadow: '2px',
        border:'none',
        backgroundColor: '#7fabd0',
        //backgroundColor: '#64b7ed',7fabd0
      },
      '& button > span':{
          color: '#fff',
      }
  },
  panelContainer:{
      padding: 4,
  }
});

export default function ResidentChart(props) {
    const {residentID,handleOpen} = props;

  const classes = useStyles();
  
  return (
    <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12} md={12} className={classes.panelContainer}>
                <ResidentAlerts ResidentId={residentID}/>
                < Link  className={classes.buttonStyle} to={'/'}  >
                   {/*} <Button  variant="contained"  size="small"> 
                    View Alerts
                    </Button>*/}
                </Link>
            </Grid>
        <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12} className={classes.panelContainer}>
                <ResidentActiveConditions ResidentId={residentID}/>
                < Link  className={classes.buttonStyle} to={`/residents/${residentID}/chart/AddCondition/form`}  >
                    <Button onClick={(e) => props.handleOpen(e,'AddCondition')}  variant="contained"  size="small" > 
                    Add Condition
                    </Button>
                </Link>
            </Grid>
            <Grid container spacing={0} >
                {/*<Grid item xs={12} md={6} className={classes.panelContainer}>
                    <ResidentDiagnosis ResidentId={residentID}/>
                    < Link  className={classes.buttonStyle} to={`/residents/${residentID}/chart/AddDiagnosis/form`}  >
                    <Button onClick={(e) => props.handleOpen(e,'AddDiagnosis')}  variant="contained"  size="small" > 
                    Add Diagnosis
                    </Button>
                </Link>
                </Grid>*/}

                <Grid item xs={12} md={12} className={classes.panelContainer}>
                    <ResidentAllergies ResidentId={residentID}/>
                    < Link  className={classes.buttonStyle} to={`/residents/${residentID}/chart/AddAllergy/form`}  >
                    <Button onClick={(e) => props.handleOpen(e,'AddAllergy')}  variant="contained"  size="small" > 
                    Add Allergy
                    </Button>
                </Link>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.panelContainer}>
                <ResidentNotes ResidentId={residentID}/>
                < Link  className={classes.buttonStyle} to={`/residents/${residentID}/chart/AddNote/form`}  >
                    <Button onClick={(e) => props.handleOpen(e,'AddNote')}  variant="contained"  size="small" > 
                    Add Note
                    </Button>
                </Link>
                < Link  className={classes.buttonStyle} to={`/residents/${residentID}/notes`}  >
                    <Button  variant="contained"  size="small"> 
                    View Notes
                    </Button>
                </Link>
            </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12} className={classes.panelContainer}>
                <ResidentActivePrescriptions ResidentId={residentID}/>
                < Link  className={classes.buttonStyle} to={`/residents/${residentID}/chart/AddPrescription/form`}  >
                    <Button onClick={(e) => props.handleOpen(e,'AddPrescription')}  variant="contained"  size="small" > 
                    Add Prescription
                    </Button>
                </Link>
                < Link  className={classes.buttonStyle} to={`/residents/${residentID}/meds`}   >
                    <Button  variant="contained"  size="small"> 
                    View Prescriptions
                    </Button>
                </Link>
            </Grid>
            <Grid item xs={12} md={12} className={classes.panelContainer}>
                <ResidentDoctorsExpandable ResidentId={residentID}/>
                < Link  className={classes.buttonStyle} to={`/residents/${residentID}/chart/AddDoctor/form`}  >
                    <Button onClick={(e) => props.handleOpen(e,'AddDoctor')}  variant="contained"  size="small" > 
                    Add Physician
                    </Button>
                </Link>
            </Grid>
            
        </Grid>
       
    </Grid>
  );
}