import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MUIDataTable from "mui-datatables";
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';
import {LiveHelp,TransferWithinAStation,SportsHandball,AccessibilityNew,SmsFailed,DirectionsRun} from '@material-ui/icons/'
import Paper  from '@material-ui/core/Paper';

import {resident_data} from '../../../../data/resident_data';

import IconSleep from '../../../../images/svg/icon_activity_sleep.svg';
import IconPhysical from '../../../../images/svg/icon_activity_physical.svg';
import IconFood from '../../../../images/svg/icon_activity_food.svg';
import IconBathroom from '../../../../images/svg/icon_activity_bathroom.svg';

/*
Resdient Notes has a table of the resident notes summary
*/

// style the component
const useStyles = makeStyles({
  root: {
    margin: 'auto',
    padding: 10,
    '& a':{
        textDecoration: 'none',
        color: '#000',
    },
    /*
    '& tr:hover .AlertIconWrapper':{
        color: '#f00',
        borderRadius:6,
        border:'1px solid #c00',
        backgroundColor: 'rgba(230,200,200, .3)',
    }*/
  },
  AlertIconWrapper:{
    padding: '2px 5px',
    //border:'1px solid #ccc',
    borderRadius:6,
    
  },
  AlertIcon:{
    fill: '#c00 !important',
    verticalAlign: 'middle',
    minWidth: 36,
    minHeight: 36,
    width: '36px  !important',
    height: 36,
    padding: 2,
    marginLeft: 8,
    marginRight: 10,
    //
    border: 'none !important',
    opacity: 1,
    maxWidth: 'none !important',
    boxShadow: 'none !important',
    borderRadius: 'none !important',
    backgroundColor: 'none  !important',
    background: 'none',
  },
  AlertIconTitle:{
    fontWeight: 'bold',
    color: '#c00',
    display: 'inline-block',
    margin: '0 3px',
    verticalAlign: 'middle',
    fontSize: '1.2em',
  }
});

// provide table interface for active alerts
export default function ResidentActivityTable(props) {
  const classes = useStyles();


  function ConvertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr)
  
    let result =  array.map(it => {
      return Object.values(it)
    })

	return result
  }

 
  // provide dynamic icon for placement based on icon name
  function TypeIcon(icon, tableMeta){
    const icons = {
      'Sleep': IconSleep,
      'Physical Activity': IconPhysical,
      'Meals': IconFood,
      'Bathroom': IconBathroom,

    };
    
    //icon = resIcons[tableMeta.rowData[0]]
    if (!icon){
        icon = icons['Sleep'];
    }
    //let  DynamicIcon = icons[{icon}]
    //let AlertIcon = icons[icon];
    let TypeIcon = icons[icon];
    let icon_color = 'icon_blue'
    if (icon === 'OK'){
        icon_color = 'icon_green'
    }
  
    let result = ( 
        <React.Fragment>
            {/*<Link to='/residents/chart'>*/}
            <div className={classes.AlertIconWrapper + ' AlertIconWrapper ' +icon_color}>
                <img src={TypeIcon} className={classes.AlertIcon + ' '+icon_color}/>
                {/*<span className={classes.AlertIconTitle +' ' +icon_color}>
                    {icon}
                </span>*/}
            </div>
           {/* </Link>*/}
        </React.Fragment>
    )
    return result
  }

  // load the data from the chart sub section
  // replace this with a call to api via ajax
  const data =  resident_data.activity.activity_list.data;
  
  // list of columns for datatable
  const columns = resident_data.activity.activity_list.headers;
  columns[0] = {name: "Icon",options:{filter:true, customBodyRender:(value, tableMeta, updateValue)=>(TypeIcon(value,tableMeta))}};

    // set options for datatable
   const options = {
    filterType: 'checkbox',
    onRowClick: (rowData, rowState) => {
      console.log('|state::',rowState, '|data::',rowData);
      props.handleSelectActivityType(rowData[1]);
      
    },
  };

  // render the component
  return (
   <div className={classes.root}>

    <div>Select activity category below to view details.</div>
    <MUIDataTable
    title={"Activity"}
    data={data}
    columns={columns}
    options={options}
    /> 
   </div>
  );
}



/*

   // format name column data
  function ResidentName(value){
    
  let result =( <Link to='/residents/chart' > <h3>{value}</h3> </Link> );

    return result
  }

  // provide dynamic icon for placement based on icon name
   function DynamicIcon(icon){
    const icons = {
      'Leave Room': TransferWithinAStation,
      'Leave Building': DirectionsRun,
      'Call Help': SmsFailed,
      'Standing Up': AccessibilityNew,
      'Fall': SportsHandball,
    };

    //let  DynamicIcon = icons[{icon}]
    let DynamicIcon = icons[icon];
    let result = ( 
        <React.Fragment>
            <Link to='/residents/chart'>
            <div className={classes.AlertIconWrapper + ' AlertIconWrapper '}>
                <DynamicIcon className={classes.AlertIcon}/>
                <span className={classes.AlertIconTitle}>
                    {icon}
                </span>
            </div>
            </Link>
        </React.Fragment>
    )
    return result
  }

*/

