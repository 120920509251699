import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  MenuList,
  MenuItem,
 } from '@material-ui/core';

import MyAccountContact from './myaccount/MyAccountContact';
import MyAccountRoles from './myaccount/MyAccountRoles';
import MyAccountOrg from './myaccount/MyAccountOrg';
import MyAccountCreds from './myaccount/MyAccountCreds';
import MyAccountPlan from './myaccount/MyAccountPlan';
import MyAccountHistory from './myaccount/MyAccountHistory';
import MyAccountBilling from './myaccount/MyAccountBilling';
import MyAccountIndex from './myaccount/MyAccountIndex';

import{ 
    ContactPhone,
    VerifiedUser,
    Business,
    HowToReg,
    Info,
    ViewHeadline,
    CreditCard,
    Dashboard,
    } from '@material-ui/icons/';

    import { NavLink, withRouter, Switch, Route } from 'react-router-dom';
    
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: 5,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      height: '100vh',
    },
    '& li:hover':{
      background: 'rgba( 100,130,160, .1)',
    },
    '& a':{
      textDecoration:'none',
      color: '#387ea9',
    },
    '& button': { color: '#387ea9',},
    '& svg':{
      fill: '#387ea9',
    },  
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      position: 'absolute',
      backgroundColor: '#8297a9',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    
    
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

 
const menu_data = [
  { title: 'Account Overview', divide: false, icon: <Dashboard/>, link: '/myaccount/', component: MyAccountIndex,
  desc: 'Account Dash'},
  { title: 'Contact Info', divide: true, icon: <ContactPhone/>, link: '/myaccount/contact', component: MyAccountContact,
  desc: 'Manage your contact and user info'},
{ title: 'User Roles', divide: false, icon: <VerifiedUser/>, link: '/myaccount/roles', component: MyAccountRoles,
  desc: 'Control levels of access and functions based on access roles'},
{ title: 'Organization',divide: false,  icon: <Business/>, link: '/myaccount/org', component: MyAccountOrg,
  desc: 'Manage info about your organization and care team members'},
{ title: 'Credentials',divide: false,  icon: <HowToReg/>, link: '/myaccount/creds', component: MyAccountCreds,
  desc: 'Control credentials for professional activity and platform features'},
{ title: 'Plan Info',divide: true,  icon: <Info/>, link: '/myaccount/plan', component: MyAccountPlan,
  desc: 'Manage your Spectator Account plan'},
{ title: 'History',divide: false,  icon: <ViewHeadline/>, link: '/myaccount/history', component: MyAccountHistory,
  desc: 'Review the history of your account activity'},
{ title: 'Billing', divide: false, icon: <CreditCard/>, link: '/myaccount/billing', component: MyAccountBilling,
  desc: 'Manage payments, billing, and history'},
    ];

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const activeRoute = (routeName) => {
    return props.location.pathname === routeName ? true : false;
  }


  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {menu_data.map((item, index) => (
          <ListItem button key={item.title}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const drawerRoutes = (
    <div>
    <div className={classes.toolbar} />
    <Divider />
      <MenuList>
      {menu_data.map((item, key) => {
    
        let divide = item.divide == true ? <Divider />: '';
        return (
          <React.Fragment>
          {divide}
          <NavLink to={item.link} style={{ textDecoration: 'none' }} key={key}>
            <MenuItem selected={activeRoute(item.link)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </MenuItem>
          </NavLink>
          </React.Fragment>
        );
      })}
    </MenuList>
    <Divider />
    </div>
  )


  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            My Account
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
          {/*<h3>Account Menu</h3>*/}
            {drawerRoutes}
            
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
       
        <Switch>
              <Route exact path="/myaccount/" key={-1}>
                <MyAccountIndex data={menu_data}/>
              </Route>
            {menu_data.map((route) => (
              <Route exact path={route.link} key={route.link}>
                <route.component />
              </Route>
            ))}
          </Switch>

      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
