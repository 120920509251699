import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
//import DemoTable from './DemoTable'
import ImgMediaCard from '../../common/FeatureCard'
import Banner from '../../../images/backgrounds/office_lobby.jpg';
import BannerNurseDoctor from '../../../images/graphics/nurse_doctor_ipad.png';
import WorkflowStepper from '../../home/WorkflowStepper';

import vector1 from '../../../images/vectors/nurse_helping_senior_sit.png';
import vector2 from '../../../images/vectors/nurse_helping_senior_wheelchair_ipad.png';
import vector3 from '../../../images/vectors/nurse_showing_senior_ipad.png';
import vector4 from '../../../images/vectors/senior_using_ipad.png';
import BannerVector from '../../../images/vectors/spectator_health_app_poster_02.png';


import {
  Group, Assignment,  AccountCircle, AssignmentInd, Notes, LocalPharmacy, Colorize, LocalHospital, Fastfood, Hotel, Accessibility
} from '@material-ui/icons';

const admin_menu_data = [
  {
    title: 'Users',
    icon: AccountCircle,
    link:'',
    desc:'Provide platform users access to manage residents and administer the platform.',
    },
    {
      title: 'Residents',
      icon: Group,
      link:'',
      desc:'Add and manage resident information, medical, activity, and personal data.',
      data:[
      ]
      },
      {
    title: 'Clinicians',
    icon: AssignmentInd,
    link:'',
    desc:'Clinicians including Nurses and Doctors are recorded and stored for each resdient\'s list of specialists.',
    },
    {
    title:'Conditions',
    icon: Assignment,
    desc: 'Manage and assign medical conditions and diagnosis to residents.'
    },
    {
    title: 'Diet',
    icon: Fastfood,
    link:'',
    desc:'Clinicians can manage resident dietary intake, food, water, supplements to track consumption.',
    },
    {
    title: 'Activity',
    icon: Hotel,
    link:'',
    desc:'Resident activity such as sleep, bowl movements, and physical activity can be recorded and monitored.',
    },
    {
    title: 'Vitals',
    icon: Accessibility,
    link:'',
    desc:'Resident health vital recordings provide motioning for topics including blood pressure, heart rate, and glucose.',
    },
    {
    title:'Notes',
    icon: Notes,
    desc: 'Allow nurses and doctors to manage and author notes for residents.'
    },
    {
    title: 'Pharmacies',
    icon: LocalPharmacy,
    link:'',
    desc:'Pharmacy records allows adminstration of medication fulfilment providers and streamline the prescription process.',
    },
    {
    title: 'Prescriptions',
    icon: LocalHospital,
    link:'',
    desc:'Medication prescriptions are tracked for each resident with order / reorder actions available fromt he dashboard.',
    },
    {
    title: 'Labs',
    icon: Colorize,
    link:'',
    desc:'Resident labs are managed, to track tests and provide result information.',
    },
]

const cards_data = [
  {
    title: 'Residents',
    image_path: 'smart_health.png',
    text: 'Manage residents in assisted living w advanced care inforamtion. Residents total information view w realtime tracking.',
    link: '/residents',
  },
  {
    title: 'Facilities',
    image_path: 'living_facility.jpg',
    text: 'Supervise building utilities and other information for care facility to ensure all topics are to specification and running.',
    link: '/facility',
  },
  {
    title: 'Admin',
    image_path: 'health_data_network.jpg',
    text: 'Customize the platform to suite your specific needs, by modifying and extedning the platform with information from your organization.',
    link: '/admin',
  },
]

const vectors = [
  {vector: vector1, title: 'Request Help Alerts', desc: 'Alerts from the system help clinicians repond to residents quickly to help prevent injuries.'},
  {vector: vector2, title: 'Personalized Experience', desc: 'Comprehensive information tracking of medical and lifestyle aspects help clinicians cater to resident individual needs.'},
  {vector: vector3, title: 'Coordinated Expert Support', desc: 'On-site and Off-site clinical experts and doctors easily can share information and access important data to deliver the best care.'},
  {vector: vector4, title: 'Review Activity and Improve', desc: 'Mobile app online access makes reviewing information effortless for both residents and care providers.'},
]


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    textAlign: 'left',
    '& header': {
      background: '#ffffff',
      background: '-moz-linear-gradient(top,  #ffffff 0%, #e6eff4 100%)',
      background: '-webkit-linear-gradient(top,  #ffffff 0%,#e6eff4 100%)',
      background: 'linear-gradient(to bottom,  #ffffff 0%,#e6eff4 100%)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#e6eff4",GradientType=0 )',
      color: '#8795a5',
    },
  },
  bar: {
    background: '#5592de',
  },
  tabpanel:{
      '& h2':{
        color: '#436f96',
        textAlign:'left',
      }
  },
  app_features:{
    padding: 10,
    margin: 10,
    backgroundColor: '#eef2f5',
  },
  intro:{
    padding: '20px 40px',
    color: '#8795a5',
    '& p': {
      padding: '10px 0',
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
    }
  },
  icon:{
    fill: '#63a2e4',
    width: 50,
    height: 'auto',
    display:'block',
    margin: 'auto',
  },
  title:{
    borderBottom: '1px solid #999',
    margin: '4px 0',
    paddingBottom: 4,
  },
  desc:{
    marginTop: 4,
    color: '#5c788f',
    lineHeight: 1.6,
    padding: '0 4px',
  },
  feature_item:{
    borderRadius:4,
    '&:hover':{
      background:'#fff',
    },
  },
  banner:{
    width: '100%',
    height: 200,
    background:`url(${BannerNurseDoctor}) ` ,
    backgroundSize: 'cover',
    backgroundPosition: ' center 69%',
  },
  vectorSet:{
    width: '98%',
    padding: 10,
    margin: 'auto',
  },
  vector:{
    width: 'auto',
    height: 200,
    backgroundSize: 'cover',
    borderRadius: 4,
  },
  Vector:{
    width: '100%',
  },
}));

export default function AboutSection() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // card set
  const cardset = cards_data.map((item, index) =>
    //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
    <Grid item xs={12} md={4}>
      <ImgMediaCard {...item} />
    </Grid>
    )


    // icon set 
    const iconset = admin_menu_data.map((item, index) =>{
    //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
    let Icon = item.icon;
    // return the icon and description , link
    return(<Grid item xs={12} md={4} lg={3} className={classes.feature_item}>
        <Icon className={classes.icon}/>
        <h4 className={classes.title}>{item.title}</h4>
        <p className={classes.desc}>{item.desc}</p>
      </Grid>)
    })

// vector set 
const vectorset = vectors.map((item, index) =>{
  //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
  let VectorImg = item.vector;
  // return the icon and description , link
  return(
  <Grid item xs={12} md={6} lg={3} className={classes.vector_item}>
      <div className={classes.vector} style={{backgroundImage:`url(${item.vector})`}}/>
      <h4 className={classes.title}>{item.title}</h4>
      <p className={classes.desc}>{item.desc}</p>
    </Grid>)
  })

  return (
    <div className={classes.root}>
      <div className={classes.banner} />
      <div className={classes.intro}>
        <h3>Spectator Health App Overview</h3>
        <p>Spectator Health provides a login user environment for managing assisted living residents' information.</p>
      </div>
      <Grid container spacing={2} className={classes.vectorSet}>
      {vectorset}
      
      <Grid item xs={12}>
       <Divider/>
         <WorkflowStepper/>
         </Grid>
      
      </Grid>
      
      {
      <Paper variant="outlined" className={classes.app_features} >
      <Grid container spacing={2}>
      {iconset}
       
      <Grid item xs={12}>
      <img className={classes.Vector} src={BannerVector}/>
      </Grid>
        </Grid>
     </Paper>
     }
    </div>
  );
}