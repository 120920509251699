import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Divider} from '@material-ui/core';

import img1 from '../../images/screenshots/screen_resident_list.png';
import img2 from '../../images/screenshots/screen_resident_chart.png';
import img3 from '../../images/screenshots/screen_resident_order_lab.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepImg:{
      width: 200,
      height:130,
      //background: '#0f0',
      backgroundSize: 'cover',
      borderRadius: 3,
      boxShadow: '2px 3px 5px #4a7198',
      display: 'block',
      margin: 'auto',
      marginBottom: 9,
    },
}));

function getSteps() {
  return ['Login to Resident List', 'Select Resident to View Chart', 'Update Info and Review Reports'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return (<div><b>Step 1:</b> Login to Resident List with user account. Check for active Resident Alerts indicated by red alert icons and message.</div>);
    case 1:
      return (<div><b>Step 2:</b> Click the Resident to view chart information covering conditions, allergies, meds, notes, doctors, alert history, and other info.</div>);
    case 2:
      return (<div><b>Step 3:</b>  Review and update all sections of the Resident information, providing automated reporting.</div>);
    default:
      return 'Unknown step';
  }
}

export default function WorkflowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const images = [
    img1,img2,img3
  ]

  return (
    <div className={classes.root}>
     
        <h3>Resident-Centric Workflow</h3>
        <p>Below is the typical 3-step easy process to review and manage resident information aimed at Nurse and Doctor users.</p>
        
      <Stepper nonLinear activeStep={activeStep} style={{background:'rgba(255,255,255,0.8)'}}>
        {steps.map((label, index) => (
          <Step key={label}>
           
            <StepButton onClick={handleStep(index)} completed={completed[index]}>
            <div className={classes.stepImg} style={{backgroundImage: `url(${images[index]})`}}></div>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" className={classes.completed}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                  </Button>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}