import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    '& svg':{
        width: 40,
        height: 'auto',
        fill: '#8297a9',
    },
    '&:hover':{
        boxShadow: '1px 3px 5px #8297a9',
    },
    '& button:hover svg':{
        fill: '#9bd',
        
    }
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function DashCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
//    { title: 'Billing', divide: false, icon: <CreditCard/>, link: '/myaccount/billing', component: MyAccountBilling},

  return (
    <Card className={classes.root} variant="outlined">
        <CardActionArea>
        <Link to={props.link}>
            <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
            {props.icon}
            </Typography>
            <Typography variant="h5" component="h2">
            {props.title}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
            
            </Typography>
            <Typography variant="body2" component="p">
            {props.desc}
            </Typography>
        </CardContent>
        </Link>
    </CardActionArea>
    {/*
    <CardActions>
        <Link to={props.link}>
            <Button size="small">Learn More</Button>
        </Link>
      </CardActions>
      */}
    </Card>
  );
}
