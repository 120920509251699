import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MUIDataTable from "mui-datatables";
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';
import {LiveHelp,TransferWithinAStation,SportsHandball,AccessibilityNew,SmsFailed,DirectionsRun} from '@material-ui/icons/'
import Paper  from '@material-ui/core/Paper';

import {resident_data} from '../../../../data/resident_data';

/*
Resident Diagnosis:
- list of diagnosis
*/

// style the component
const useStyles = makeStyles({
  root: {
    //margin: 'auto',
    padding:4,
    '& a':{
        textDecoration: 'none',
        color: '#000',
    },
    '& tr:hover .AlertIconWrapper':{
        color: '#f00',
        borderRadius:6,
        border:'1px solid #c00',
        backgroundColor: 'rgba(230,200,200, .3)',
    }
  },
  AlertIconWrapper:{
    padding: '2px 5px',
    border:'1px solid #ccc',
    borderRadius:6,
    
  },
  AlertIcon:{
    fill: '#c00',
    verticalAlign: 'middle',
    minWidth: 36,
    minHeight: 36,
    width: 36,
    height: 36,
    padding: 2,
    marginLeft: 8,
    marginRight: 10,
  },
  AlertIconTitle:{
    fontWeight: 'bold',
    color: '#c00',
    display: 'inline-block',
    margin: '0 3px',
    verticalAlign: 'middle',
    fontSize: '1.2em',
  }
});

// provide table interface for active alerts
export default function ResidentDiagnosis(props) {
  const classes = useStyles();

  // load the data from the chart sub section
  // replace this with a call to api via ajax
  const data = resident_data.chart.diagnosis.data;
  
  
  // list of columns for datatable
   //const columns = resident_data.chart.alergies.data;

    // set options for datatable
   const options = {
    filterType: 'checkbox',
  };

  // render the component
  return (
   <div className={classes.root}>

    <h3>Diagnosis</h3>
    {data.map((row) => {
        //const value = row[column.id];
        return (
          <div key={row} >
            {row}
          </div>
        );
      })}
   </div>
  );
}