import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {BrowserRouter as Router , Switch, Route, Link} from 'react-router-dom';


import { Formik, Form } from "formik";
import * as yup from "yup";

let LabsSchema = yup.object().shape({
    ResidentId: yup.string().required("This field is required."),
    OrderingClinicianId: yup.string().required("This field is required."),
    LabOrderNo: yup.string().required("This field is required."),
    LOINC: yup.string().required("This field is required."),
    LOINDesc: yup.string().required("This field is required."),
    Result: yup.string().required("This field is required."),
    UnitOfMeasure: yup.string().required("This field is required."),
    DateOrdered: yup.string().required("This field is required."),
    Cost: yup.string().required("This field is required."),
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    '& h2, h3, h4':{
      textAlign: 'left !important',
      color: '#999 !important',
    }
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  
}));

export default function FormAddLab(props) {

    // pass in the params for building a dynamic url, 
    // pass params for form state and modal open close on submit
    // access controller api to execute the ajax call on submit
    const {residentID, section, residentForm, handler, handleClose} = props;
    
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="lg">
      <Link to={`/residents/${residentID}/labs`}  >
            {/* pass in handle close */}
            <Button color="primary" onClick={handleClose}>Back to Resident Info</Button>
            </Link>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
        Labs Form
        </Typography>
        <Formik
          initialValues={{
            ResidentId: "",
            OrderingClinicianId: "",
            LabOrderNo: "",
            LOINC: "",
            LOINDesc: "",
            Result: "",
            UnitOfMeasure: "",
            DateOrdered: "",
            Cost: "",
          }}
          validationSchema={LabsSchema}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>

                {/*
                ResidentId	OrderingClinicianId	LabOrderNo	LOINC	LOINDesc	Result	UnitOfeasure	DateOrdered	Cost
                */}
                <Grid item xs={12}><h3>Lab Info</h3></Grid>
                
              <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.ResidentId && touched.ResidentId}
                    autoComplete="fname"
                    name="ResidentId"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="ResidentId"
                    label="ResidentId"
                    autoFocus
                    helperText={
                      errors.ResidentId && touched.ResidentId
                        ? errors.ResidentId
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.OrderingClinicianId && touched.OrderingClinicianId}
                    autoComplete="OrderingClinicianId"
                    name="OrderingClinicianId"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="OrderingClinicianId"
                    label="OrderingClinicianId"
                    helperText={
                      errors.OrderingClinicianId && touched.OrderingClinicianId
                        ? errors.OrderingClinicianId
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.LOINC && touched.LOINC}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="LOINC"
                    label="LOINC"
                    name="LOINC"
                    autoComplete="LOINC"
                    helperText={
                      errors.LOINC && touched.LOINC
                        ? errors.LOINC
                        : null
                    }
                  />
                </Grid>
               
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.LOINDesc && touched.LOINDesc}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="LOINDesc"
                    label="LOINDesc"
                    name="LOINDesc"
                    autoComplete="LOINDesc"
                    helperText={
                      errors.LOINDesc && touched.LOINDesc ? errors.LOINDesc : null
                    }
                  />
                </Grid>

                <Grid item xs={12}><h3>Info</h3></Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    error={errors.Result && touched.Result}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="Result"
                    label="Result"
                    name="Result"
                    autoComplete="Result"
                    helperText={
                      errors.Result && touched.Result
                        ? errors.Result
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    error={errors.UnitOfMeasure && touched.UnitOfMeasure}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="UnitOfMeasure"
                    label="UnitOfeasure"
                    name="UnitOfMeasure"
                    autoComplete="UnitOfMeasure"
                    helperText={
                      errors.UnitOfMeasure && touched.UnitOfMeasure
                        ? errors.UnitOfMeasure
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    error={errors.DateOrdered && touched.DateOrdered}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="DateOrdered"
                    label="Date Ordered"
                    name="DateOrdered"
                    type="DateOrdered"
                    autoComplete="DateOrdered"
                    helperText={
                      errors.DateOrdered && touched.DateOrdered ? errors.DateOrdered : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                <TextField
                  error={errors.Cost && touched.Cost}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  id="Cost"
                  label="Cost"
                  name="Cost"
                  autoComplete="Cost"
                  helperText={
                    errors.Cost && touched.Cost ? errors.Cost : null
                  }
                />
              </Grid>

              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
