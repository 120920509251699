import React from 'react';
import ReactDOM from 'react-dom';

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import Button from '@material-ui/core/Button';

import PrimarySearchAppBar from './components/common/AppHeader'
import AdminHome from './components/admin/AdminHome'
import AdminMenu from './components/admin/AdminMenuRoutes'
import ResidentSection from './components/residents/ResidentSection'
import FacilitySection from './components/facility/FacilitySection'
import HomeSection from './components/home/HomeSection'
import AboutSection from './components/public/about/AboutSection'
import SupportSection from './components/public/support/SupportSection'
import ResponsiveDrawer from './components/UserAccount/MyAccount'
import AlertsTable from './components/alerts/AlertsTable.js';
import SignupPage from './components/login/SignupPage';
import LoginPage from './components/login/LoginPage';
import WelcomePage from './components/welcome/WelcomePage';
import LogoutPage from './components/welcome/LogoutPage';
import logo from './logo.svg';
import './App.css';

import {BrowserRouter as Router , Switch, Route, Link, Redirect, withRouter} from 'react-router-dom';
import { useHistory } from "react-router-dom";


const styles = {
  root: {
    margin: 20,
    height: '100%',
   
  },
  gridItem: {
    backgroundColor: "blue",
    color: "white",
    borderStyle: "solid"
  },
  footer:{
    zIndex: 200,
    backgroundColor:"#dde6ec",
    color: "#99a6bb",
    height: 40,
    position: 'fixed',
    bottom:0,
    width: '100%',
    '& p':{
      margin: 'auto',
      padding: 10,
    }
  },
  route_container:{
    position: 'relative',
    zIndex: 100,
    height: '100%',
    '& .MuiButton-contained':{
      marginRight: 4,
    },
    '& .MuiTableCell-root':{
      padding: 4,
    },
    '& .MuiCheckbox-root':{
      color:'rgb(120 153 195 / 54%)',
    }
  },
  
};

const App = props => {
  const { classes } = props;
  
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [activeUser, setActiveUser] = React.useState(false);
  //const history = useHistory();
  
  // handle login : set the userid and name w stub data from the form
  // pass the handlerto the form

  const handleLogin = function(user){
    
        // update the logged in user state
        setIsLoggedIn(true);
        setActiveUser(user);
        console.log('> login user', user);
        

  }


  const handleLogout = function(){
        let user = activeUser;
        // update the logged in user state
        setIsLoggedIn(false);
        setActiveUser(null);
        console.log('> logout user, goodbye', user);
    
  }
      
  return (
    <Router>
    
    <div className="App">
      <header className="App-header">
        <PrimarySearchAppBar isLoggedIn={isLoggedIn} activeUser={activeUser} handleLogout={handleLogout}/>

      </header>
      <div className={classes.route_container}>
        <Switch>
          <Redirect exact from="/admin" to="/admin/residents" />
          <Redirect exact from="/residents" to="/residents/list" />
          <Redirect exact from="/residents/list/:residentsection" to="/residents/list" />
          <Route path="/" exact component={HomeSection} />
          <Route path="/about" exact component={AboutSection} />
          <Route path="/support" exact component={SupportSection} />
          <Route path="/welcome" exact component={() => <WelcomePage activeUser={activeUser} isLoggedIn={isLoggedIn} />} />
          <Route path="/logout" exact component={() => <LogoutPage setIsLoggedIn={setIsLoggedIn}/>} />
          <Route exact path="/residents/:residentID?/:page?/:subpage?/:formpage?"  render={props => <ResidentSection {...props} />} />
          <Route path="/facility"  component={FacilitySection} />
          <Route exact path="/admin/:page?/:subpage?" render={props => <AdminMenu {...props} />} />
          <Route path="/myaccount"  component={ResponsiveDrawer} />
          <Route path="/alerts"  component={AlertsTable} />
          <Route path="/signup"  component={SignupPage} />
          <Route path="/login" component={() => <LoginPage handleLogin={handleLogin} />}/>
          
          
        </Switch>
        </div>
{/*
        <Grid container>
          <Grid item xs={3} className={classes.gridItem}>
            ONE
          </Grid>
          <Grid item xs={3} className={classes.gridItem}>
            TWO
          </Grid>
          <Grid item xs={3} className={classes.gridItem}>
            THREE
          </Grid>
          <Grid item xs={3} className={classes.gridItem}>
            FOUR
          </Grid>
        </Grid>
*/}
  <div className={classes.footer}>
    <p>Copyright Spectator Health 2020
      </p>
    </div>
    </div>
    </Router>

  );
}
//export default withRouter((withStyles(styles)(App)));
export default withStyles(styles) (App) ;
/*
<AdminHome name='josh' />
      <Button variant="contained" color="primary">
      Hello World
    </Button>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
*/