import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import DemoTable from './DemoTable'
import MySummaryCard from './MySummary'
import MyHistoryCard from './MyHistory'
import MyResidentsCard from './MyResidents'
import MyFacilitiesCard from './MyFacilties'

const admin_menu_data = [
    {
        title: 'Residents',
        link:'',
        desc:'',
        data:[

        ]
    },
    {
        title: 'Users',
        link:'',
        desc:'',
    },
    {
        title: 'Clinicians',
        link:'',
        desc:'',
    },
    {
        title: 'Pharmacies',
        link:'',
        desc:'',
    },
    {
        title: 'Prescriptions',
        link:'',
        desc:'',
    },
    {
        title: 'Labs',
        link:'',
        desc:'',
    },
    {
        title: 'Activity',
        link:'',
        desc:'',
    },
    {
        title: 'Vitals',
        link:'',
        desc:'',
    },
]

const cards_data = [
  {
    title: 'Residents',
    image_path: 'smart_health.png',
    text: 'Manage residents in assisted living w advanced care inforamtion. Residents total information view w realtime tracking.',
    link: '/residents',
  },
  {
    title: 'Facilities',
    image_path: 'living_facility.jpg',
    text: 'Supervise building utilities and other information for care facility to ensure all topics are to specification and running.',
    link: '/facility',
  },
  {
    title: 'Admin',
    image_path: 'health_data_network.jpg',
    text: 'Customize the platform to suite your specific needs, by modifying and extedning the platform with information from your organization.',
    link: '/admin',
  },
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    textAlign: 'left',
    '& header': {
      background: '#ffffff',
      background: '-moz-linear-gradient(top,  #ffffff 0%, #e6eff4 100%)',
      background: '-webkit-linear-gradient(top,  #ffffff 0%,#e6eff4 100%)',
      background: 'linear-gradient(to bottom,  #ffffff 0%,#e6eff4 100%)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#e6eff4",GradientType=0 )',
      color: '#8795a5',
    },
  },
  bar: {
    background: '#5592de',
  },
  tabpanel:{
      '& h2':{
        color: '#436f96',
        textAlign:'left',
      }
  },
  app_features:{
    padding: 10,
    margin: 10,
    backgroundColor: '#dde6ec',
  },
  intro:{
    padding: '20px 40px',
    color: '#8795a5',
    '& p': {
      padding: '10px 0',
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
    }
  },
  activeUser:{
    fontWeigt: 'bold',
    color: '#024',

  }

}));

export default function WelcomePage(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const {activeUser} = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 /*
  const cardset = cards_data.map((item, index) =>
    //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
    <Grid item xs={12} md={4}>
      <ImgMediaCard {...item} />
    </Grid>
    )
 */
  return (
    <div className={classes.root}>
      <div className={classes.intro}>
        <h3>Welcome to your Spectator Health</h3>
        <p>Active User Dashboard Welcome Screen : <span className={classes.activeUser}>{activeUser}</span></p>
      </div>
       
      <Paper variant="outlined" className={classes.app_features} >
      <Grid container spacing={2}>


      <Grid item xs={12} md={3}>
            <MySummaryCard/>
          </Grid>

          <Grid item xs={12} md={3}>
            <MyResidentsCard/>
          </Grid>
          <Grid item xs={12} md={3}>
            <MyFacilitiesCard/>
          </Grid>

          <Grid item xs={12} md={3}>
            <MyHistoryCard/>
          </Grid>
          
        </Grid>
     </Paper>
     
    </div>
  );
}