import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {BrowserRouter as Router , Switch, Route, Link} from 'react-router-dom';


import { Formik, Form } from "formik";
import * as yup from "yup";

let AlergySchema = yup.object().shape({
  Alergy: yup.string().required("This field is required."),
  Notes: yup.string().required("This field is required."),
  
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    '& h2, h3, h4':{
      textAlign: 'left !important',
      color: '#999 !important',
    }
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  
}));

export default function FormAddAlergy(props) {

    // pass in the params for building a dynamic url, 
    // pass params for form state and modal open close on submit
    // access controller api to execute the ajax call on submit
    const {residentID, section, residentForm, handler, handleClose} = props;
    
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="lg">
      <Link to={`/residents/${residentID}/${section}`}  >
      {/* pass in handle close */}
      <Button color="primary" onClick={handleClose}>Back to Resident Info </Button>
      </Link>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Alergy Form
        </Typography>
        <Formik
          initialValues={{
            Alergy: "",
            Notes: "",
           
          }}
          validationSchema={AlergySchema}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
              <Grid item xs={12}><h3>Alergy</h3></Grid>
                
              <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.Alergy && touched.Alergy}
                    autoComplete="Alergy"
                    name="Alergy"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="Alergy"
                    label="Alergy"
                    autoFocus
                    helperText={
                      errors.Alergy && touched.Alergy
                        ? errors.Alergy
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.Notes && touched.Notes}
                    autoComplete="Notes"
                    name="Notes"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="Notes"
                    label="Notes"
                    helperText={
                      errors.Notes && touched.Notes
                        ? errors.Notes
                        : null
                    }
                  />
                </Grid>

              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
