import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DemoTable from './DemoTable'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';

const admin_menu_data = [
    {
        title: 'Residents',
        link:'residents',
        desc:'',
        data:[

        ]
    },
    {
        title: 'Users',
        link:'users',
        desc:'',
    },
    {
        title: 'Clinicians',
        link:'clinicians',
        desc:'',
    },
    {
        title: 'Pharmacies',
        link:'pharmacies',
        desc:'',
    },
    {
        title: 'Prescriptions',
        link:'prescriptions',
        desc:'',
    },
    {
        title: 'Labs',
        link:'labs',
        desc:'',
    },
    {
        title: 'Activity',
        link:'activities',
        desc:'',
    },
    {
        title: 'Vitals',
        link:'vitals',
        desc:'',
    },
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',

    '& header': {
      background: '#ffffff',
      background: '-moz-linear-gradient(top,  #ffffff 0%, #e6eff4 100%)',
      background: '-webkit-linear-gradient(top,  #ffffff 0%,#e6eff4 100%)',
      background: 'linear-gradient(to bottom,  #ffffff 0%,#e6eff4 100%)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#e6eff4",GradientType=0 )',
      color: '#000000',
    },
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  section_menu:{
    marginTop: 2,
  },
  breadcrumbs:{
    color: '#999',
    marginLeft: 10,
    padding: 4,
    '& a:link, a:visited, a:active': {
      color: '#4499cc',
    },
  },
  bar: {
    background: '#5592de',
  },
  tabpanel:{
      '& h2':{
        color: '#000000',
        textAlign:'left',
        textTransform: 'capitalize',
      }
  }
}));


function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default  function AdminMenu(props){

  const tabNameToIndex = {
    0: 'residents',
    1: 'users',
    2:'clinicians',
    3: 'pharmacies',
    4: 'prescriptions',
    5: 'labs',
    6: 'activities',
    7: 'vitals',
  }
  const indexToTabName = {
    'residents': 0,
    'users': 1,
    'clinicians': 2,
    'pharmacies': 3,
    'prescriptions': 4,
    'labs': 5,
    'activities': 6,
    'vitals': 7,
  }
  const {match, history} = props;
  const {params} =  match;
  const {page} = params;

  const classes = useStyles();
  //const [value, setValue] = React.useState(0);
  const [section, setSection] = React.useState(page);
  const [selectedTab, setSelectedTab] = React.useState(indexToTabName[page]);


  const handleTabChange = (event, newValue) => {
    history.push(`/admin/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
    setSection(admin_menu_data[newValue]['link']);
  }

/*
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSection(admin_menu_data[newValue]['link']);
    
  };
  */
/*
  handleCallToRouter = (value) => {
    this.props.history.push(value);

    setSection(admin_menu_data[value]['link']);
    
  }
*/
  let selectedSection = admin_menu_data[indexToTabName[page]]['link']
  return (
    <div className={classes.root}>
      {/*
      <div className={classes.breadcrumbs}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href="/" onClick={handleClick}>
            Material-UI
          </Link>
          <Link color="inherit" href="/getting-started/installation/" onClick={handleClick}>
            Core
          </Link>
          <Typography color="textPrimary">Breadcrumb</Typography>
        </Breadcrumbs>
      </div>
      */}
      <AppBar position="static" className={classes.section_menu}>
        <Tabs 
        textColor="#ffffff"
        TabIndicatorProps={{
            style: {
              backgroundColor: "#88bbee"
            }
          }}
        value={indexToTabName[page]} 
        onChange={handleTabChange} 
        aria-label="tabs sample: residents">
        {admin_menu_data.map((item, index) =>
            //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
           <Tab compnent={Link} to={'/admin/'+item.link} key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab>
            )}
          
        </Tabs>
        
      </AppBar>
      
      <div className={classes.tabpanel}>

      {indexToTabName[page] === 0 && 
        <TabPanel value={selectedTab}  index={indexToTabName[section]}>
          <h2>{selectedSection}</h2>
          <DemoTable admin_section={selectedSection} />
        </TabPanel>
      }
      {indexToTabName[page] === 1 && 
        <TabPanel value={selectedTab}  index={indexToTabName[section]}>
          <h2>{selectedSection}</h2>
          <DemoTable admin_section={selectedSection} />
        </TabPanel>
      }
      {indexToTabName[page] === 2 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]}>
                <h2>{selectedSection}</h2>
                <DemoTable admin_section={selectedSection} />
              </TabPanel>
            }
      {indexToTabName[page] === 3 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]}>
                <h2>{selectedSection}</h2>
                <DemoTable admin_section={selectedSection} />
              </TabPanel>
            }
            {indexToTabName[page] === 4 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]}>
                <h2>{selectedSection}</h2>
                <DemoTable admin_section={selectedSection} />
              </TabPanel>
            }
            {indexToTabName[page] === 5 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]}>
                <h2>{selectedSection}</h2>
                <DemoTable admin_section={selectedSection} />
              </TabPanel>
            }
            {indexToTabName[page] === 6 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]}>
                <h2>{selectedSection}</h2>
                <DemoTable admin_section={selectedSection} />
              </TabPanel>
            }
            {indexToTabName[page] === 7 && 
              <TabPanel value={selectedTab}  index={indexToTabName[section]}>
                <h2>{selectedSection}</h2>
                <DemoTable admin_section={selectedSection} />
              </TabPanel>
            }
      </div>
    </div>
  );
}
/*
  
        <TabPanel value={value} index={2}>
        <h2>{section}</h2>
        <DemoTable admin_section='clinicians' />
        </TabPanel>
        
        <TabPanel value={value} index={3}>
        <h2>{section}</h2>
        <DemoTable admin_section='pharmacies' />
        </TabPanel>
        
        <TabPanel value={value} index={4}>
        <h2>{section}</h2>
        <DemoTable admin_section='prescriptions' />
        </TabPanel>
        
        <TabPanel value={value} index={5}>
        <h2>{section}</h2>
        <DemoTable admin_section='labs' />
        </TabPanel>
        
        <TabPanel value={value} index={6}>
        <h2>{section}</h2>
        <DemoTable admin_section='activities' />
        </TabPanel>
        
        <TabPanel value={value} index={7}>
        <h2>{section}</h2>
        <DemoTable admin_section='vitals' />
        </TabPanel>
        */