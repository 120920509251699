/*
Prescriptions table

- refill prescription
- create new prescription

*/




import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from "@material-ui/core/Grid";

import ResidentPrescriptionsTable from './resident_prescriptions/ResidentPrescriptionsTable'

/*

This file will receive a resident ID and load all of the chart info from API

Sub sections

1
- active conditions
 -> view all conditions
2
- dagnosis
 -> view all
3
- alergies
 -> view all
4
- notes
 -> view all
5
- active prescriptions
 -> view all
6
- doctors
 -> view all
7
- alerts
 -> view all

*/

const useStyles = makeStyles({
  root: {
    //margin: 'auto',
    '& a':{
        textDecoration: 'none',
        color: '#FFF',
    }
  },
  buttonStyle:{
      textDecoration:'none',
      '& button':{
        boxShadow: '2px',
        border:'none',
        backgroundColor: '#64b7ed',
      },
      '& button > span':{
          color: '#fff',
      }
  }
});

export default function ResidentPrescriptions(props) {
  const classes = useStyles();
  const {residentID,handleOpen} = props;
  
  return (
    <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} md={12}>
            <ResidentPrescriptionsTable/>
            < Link  className={classes.buttonStyle} to={`/residents/${residentID}/meds/AddPrescription/form`}  >
            <Button onClick={(e) => props.handleOpen(e,'AddPrescription')}  variant="contained"  size="small" > 
                Add Prescription 
                </Button>
            </Link>         
        </Grid>
    </Grid>
  );
}