/*

each page of detail section will have a view model

describe each view model below based on the design mockups

each sub section and table will have a key and data associated or an array

list out all categorize by page for request, 
pass param for the data model key corrpsing to the page in th request

*/


// make a dynamic request to the backend api micrservice for the corresponding section info
/*const sample_json = {
    "APIS":[
        {
            "api_endpoint": "GET /resident/:id/chart",
            "front_end_route": "residents/:id/chart",
            "api_result":{   
                "residentID": 30583802,
                "first_name": "bob",
            }
        },
        {
            "api_endpoint": "GET /resident/:id/careplan",
            "front_end_route": "residents/:id/careplan",
            "api_result":{   
                "residentID": 30583802,
                "careplan_info": [
                    {
                        "section": "need_medical_assitance",
                        "data":[
                            {
                                "question": 'Residents all medication... ',
                                "answer": true,
                            },
                            {
                                "question": 'Residents all medication... ',
                                "answer": true,
                            },
                            {
                                "question": 'Residents all medication... ',
                                "answer": true,
                            },
                        ]
                    },
                    {
                        "section": "need_medical_assitance",
                        "data":[
                            {
                                "question": 'Residents all medication... ',
                                "answer": true,
                            },
                            {
                                "question": 'Residents all medication... ',
                                "answer": true,
                            },
                            {
                                "question": 'Residents all medication... ',
                                "answer": true,
                            },
                        ]
                    },
                ]
                
            }
        }

    ]
 

}*/


export const resident_data = {
    residentID:"R100",
    resident_info:{

    },
    // api endpoint (GET /resident/:id/chart )
    chart:{
        active_conditions:{
            headers: ['Condition','Date Created', 'Created By'],
            data:[
                ['knee swollen from bang on desk', '08:36 08-23-2020', 100378021],
                ['Constipation for 2 days', '12:38 08-16-2020', 100378021],
                ['Poor eye sight', '08:36 08-03-2020', 100378021],
            ],
        },
        diagnosis:{
            //headers: [],
            data:[
                'Arthritis',
                'COPD',
                'Chronic Bronchitis',
                'Coronary Heart Disease',
                'Cataracts',
            ],
        },
        alergies:{
            //headers: [],
            data:[
                ['Antihistamines', 'Dry mouth from antihistamines'],
                ['Theophylline (Erythromycin)', 'Seizure from theophylline while taking'],
            ],
        },
        notes:{
            headers: ['Note', 'Created By', 'Priority', 'Date/Time'],
            data:[
                ['Patient had mild headache at night. Adminstered Ib...', 100378021, 1, '14:28 03-22-2020'],
                ['Patient had trouble sleeping at night.', 100378021, 1, '14:28 03-22-2020'],
                ['Patient had diffculty with BMs and mild stomach ac...', 100378021, 1, '14:28 03-22-2020'],
                ['Patient had pain in hip area in same area as previ...', 100378021, 1, '14:28 03-22-2020'],
            ],
        },
        active_prescriptions:{
            headers: ['Drug Name', 'Date Ordered', 'Day\'s Supply', 'Regimen'],
            data:[
                ['Vicodin','02-19-2020', '65mgs', 'Take 2 pills w water in morning'],
                ['Simvastatin','02-19-2020', '65mgs', 'Take 2 pills w water in morning'],
                ['Lisinopril','02-19-2020', '65mgs', 'Take 2 pills w water in morning'],
            ],
        },
        doctors:{
            table:{
                headers:['Name', 'Type', 'Hostpital', 'Phone1', 'Phone2', 'Address'],
                data:[
                ['Dr. Paula Eisenhart, MD', 'Primary Care physician, internal medicine', 'Scripss Green Hospital', '(760)-321-8782', '(760)-321-8888', '7565 Mission Valley Rd, Ste 200|San Diego, CA|92108'],
                ['Dr. Sanjay Gupta, MD', 'Surgeon, internal medicine', 'Scripss Green Hospital', '(760)-321-8782', '(760)-321-8888', '7565 Mission Valley Rd, Ste 200|San Diego, CA|92108'],
                ],
                data:[
                    ['Dr. Paula Eisenhart, MD', 'Primary Care physician, internal medicine', 'Scripss Green Hospital', '(760)-321-8782', '(760)-321-8888', '7565 Mission Valley Rd, Ste 200|San Diego, CA|92108'],
                    ['Dr. Sanjay Gupta, MD', 'Surgeon, internal medicine', 'Scripss Green Hospital', '(760)-321-8782', '(760)-321-8888', '7565 Mission Valley Rd, Ste 200|San Diego, CA|92108'],
                ],
            },
            alerts:{
                headers: ['Alert Type', 'Alert Time', 'Date'],
                data:[
                    ['Fall Alert', '1:18pm', 'yesterday', '04-22-2020'],
                    ['Standing Up', '11:47am', '4 days ago', '04-22-2020'],
                    ['Left Building', '11:16am', 'last week', '04-22-2020'],
                    ['Left Room', '10:28am', 'last month', '04-22-2020'],
                    ['Help Call', '9:02am', 'last year', '04-22-2020'],
                ],
            },
        },
    },
    // api endpoint (GET /resident/:id/careplan )
    careplan:{
        "resident_info":[
            {'label': 'Name', 'value': 'Mary Jenkins'},
            {'label': 'DOB', 'value': '1949'},
            {'label': 'Age', 'value': '80'},
            {'label': 'Gender', 'value': 'Female'},
            {'label': 'Date', 'value': '21-04-2020'},
        ],
        "careplan_info": [
            {
                "section": "need_medical_assitance",
                "title": "Need Medical Assistance",
                "data":[
                    {
                        "question": 'Residents all medication... ',
                        "answer": true,
                    },
                    {
                        "question": 'Residents all medication... ',
                        "answer": true,
                    },
                    {
                        "question": 'Residents all medication... ',
                        "answer": true,
                    },
                ]
            },
            {
                "section": "need_diabetic_care",
                "title": "Need Diabetic Care",
                "data":[
                    {
                        "question": 'Residents all medication... ',
                        "answer": true,
                    },
                    {
                        "question": 'Residents all medication... ',
                        "answer": true,
                    },
                    {
                        "question": 'Residents all medication... ',
                        "answer": true,
                    },
                ]
            },
            {
                "section": "need_Mobility Assistance",
                "title": "Need Mobility Assitance",
                "data":[
                    {
                        "question": 'Residents all medication... ',
                        "answer": true,
                    },
                    {
                        "question": 'Residents all medication... ',
                        "answer": true,
                    },
                    {
                        "question": 'Residents all medication... ',
                        "answer": true,
                    },
                ]
            },
        ]
    },
    // api endpoint (GET /resident/:id/vitals )
    vitals: {
        vitals_list:[
            {"icon": "hearticon", "Title": "heart rate", "Value": '72', "Measurement": "bpm avg", "Time Recorded":"6:41 am"},
            {"icon": "bloodpressure", "Title": "blood pressure", "Value": '121/91', "Measurement": "mmHg", "Time Recorded":"6:42 am"},
            {"icon": "oxygen", "Title": "oxygen saturation", "Value": '94.6', "Measurement": "%", "Time Recorded":"6:30 am"},
            {"icon": "respiration", "Title": "respiration", "Value": '13', "Measurement": "rpm", "Time Recorded":"6:58 am"},
            {"icon": "glucose", "Title": "glucose", "Value": '8.2', "Measurement": "mmoI/L", "Time Recorded":"6:47 am"},
        ],
        vital_details:{

            chart:{

            },
            table:{
                "heart rate":{
                    headers:['Vital', 'BPM', 'Datetime'],
                    data:[
                        ['Heart Rate', 98, '12:00 02-04-2020'],
                        ['Heart Rate', 101, '12:00 02-04-2020'],
                        ['Heart Rate', 89, '12:00 02-04-2020'],
                    ],
                },
                "blood pressure":{
                    headers:['Vital', 'mmHg', 'Datetime'],
                    data:[
                        ['Blood Pressure', '90/60', '12:00 02-04-2020'],
                        ['Blood Pressure', '90/60', '12:00 02-04-2020'],
                        ['Blood Pressure', '90/60', '12:00 02-04-2020'],
                    ],
                },
                "oxygen saturation":{
                    headers:['Vital', 'mm Hg', 'Datetime'],
                    data:[
                        ['Blood Oxygen', 80, '12:00 02-04-2020'],
                        ['Blood Oxygen', 80, '12:00 02-04-2020'],
                        ['Blood Oxygen', 80, '12:00 02-04-2020'],
                    ],
                },
                "respiration":{
                    headers:['Vital', 'breaths per minute', 'Datetime'],
                    data:[
                        ['Respiration rate', 16, '12:00 02-04-2020'],
                        ['Respiration rate', 14, '12:00 02-04-2020'],
                        ['Respiration rate', 13, '12:00 02-04-2020'],
                    ],
                },
                "glucose":{
                    headers:['Vital', 'mg/dL', 'Datetime'],
                    data:[
                        ['Glucose', '140 mg/dL (7.8 mmol/L)', '12:00 02-04-2020'],
                        ['Glucose', '140 mg/dL (7.8 mmol/L)', '12:00 02-04-2020'],
                        ['Glucose', '140 mg/dL (7.8 mmol/L)', '12:00 02-04-2020'],
                    ],
                },
            },
        }
    },
    // api endpoint (GET /resident/:id/notes )
    notes:{
        table:{
            headers:['note', 'priority', 'category', 'file attach', 'photos', 'datetime', 'created by'],
            data:[
                ['Patient had mild headache at night. Adminstered Ib...', 'critical', 'doctor', ['file1.png'], ['image1.png', 'image2.png'], '10:34am 03-20-2020', 100378021],
                ['Patient had trouble sleeping at night.', 'normal', 'general', [], [], '10:34am 03-20-2020', 100378021],
                ['Patient had diffculty with BMs and mild stomach ac...', 'normal', 'reminder', [], [], '10:34am 03-20-2020', 100378021],
                ['Patient had pain in hip area in same area as previ...', 'normal', 'checkup', ['file1.png'], ['image1.png'], '10:34am 03-20-2020', 100378021],
            ],
        }
    },
    // api endpoint (GET /resident/:id/activity )
    activity:{
        activity_list:{
            data:[
                ['Bathroom','Bathroom', '6', 'average visit', 'system', 'Thursday, Jan 23'],
                ['Sleep','Sleep', '11:35', 'min', 'system', 'Thursday, Jan 23'],
                ['Meals','Meals', '4', 'meals', 'system', 'Thursday, Jan 23'],
                ['Physical Activity','Physical Activity', '37', 'steps', 'system', 'Thursday, Jan 23'],
            ],
            headers:['Activity', 'Title', 'Value', 'Measure', 'Type', 'Date'],
        },
        activity_details:{
            chart:{

            },
            table:{
                'Bathroom':{
                    headers:['Visits','Quality','date'],
                    data:[
                        [6,'soft','02-02-2020'],
                        [2,'medium','02-02-2020'],
                        [4,'medium','02-02-2020'],
                        [3,'hard','02-02-2020'],
                    ],
                },
                'Sleep':{
                    headers:['Hours','date'],
                    data:[
                        [8,'02-02-2020'],
                        [6,'02-02-2020'],
                        [7,'02-02-2020'],
                        [7,'02-02-2020'],
                    ],
                },
                'Meals':{
                    headers:['Meals per Day','date'],
                    data:[
                        [3,'02-02-2020'],
                        [2,'02-02-2020'],
                        [4,'02-02-2020'],
                        [3,'02-02-2020'],
                    ],
                },
                'Physical Activity':{
                    headers:['Hours','date'],
                    data:[
                        [1,'02-02-2020'],
                        [2,'02-02-2020'],
                        [3,'02-02-2020'],
                        [1,'02-02-2020'],
                    ],
                },
            }
        }
    },
    // api endpoint (GET /resident/:id/diet )
    diet:{
        charts:[
            {
                title: 'food',
                data: {},
            },
            {
                title: 'fluid',
                data: {},
            },
            {
                title: 'supplements',
                data: {},
            },
        ],
        table:{
            headers:['date time recorded', 'food % total', 'fluid mg', 'supplemental mg'],
            data:[
                [60,900,200],
                [50,1200,150],
                [80,700,0],
                [40,900,0],
                [60,860,200],
            ],
        }
    },
    // api endpoint (GET /resident/:id/meds )
    meds:{
        table:{
            headers:['drug name', 'date ordered', 'date filled', 'day\'s supply', 'Quantity Filled', 'Regimen', 'Day\'s Left' ],
            data:[
                ['Vicodin 10 mg', '02-19-2020', '02-19-2020', '30mgs', '50x 30mgs', 'Take 2 pills w water in morning 65 mg', '7 days'],
                ['Simvastatin', '02-19-2020', '02-19-2020', '30mgs', '50x 30mgs', 'Take 2 pills w water in morning 65 mg', '7 days'],
                ['Lisinopril', '02-19-2020', '02-19-2020', '30mgs', '50x 30mgs', 'Take 2 pills w water in morning 65 mg', '7 days'],
                ['Levothyroxine', '02-19-2020', '02-19-2020', '30mgs', '50x 30mgs', 'Take 2 pills w water in morning 65 mg', '7 days'],
                ['Azithromycin', '02-19-2020', '02-19-2020', '30mgs', '50x 30mgs', 'Take 2 pills w water in morning 65 mg', '7 days'],
                ['Metformin', '02-19-2020', '02-19-2020', '30mgs', '50x 30mgs', 'Take 2 pills w water in morning 65 mg', '7 days'],
                ['Lipitor', '02-19-2020', '02-19-2020', '30mgs', '50x 30mgs', 'Take 2 pills w water in morning 65 mg', '7 days'],
            ],
        }
    },
    // api endpoint (GET /resident/:id/labs )
    labs:{
        table:{
            headers:['LOINC','lab id', 'date of service', 'lab results', 'units', 'range', 'status', 'typetag', 'reorder'],
            data:[
                ['1234 Blood Analysis', '002', '02-19-2020', '7.7', 'Na', '0.12', 'Normal', 'Blood'],
                ['0282 Stool Analysis', '002', '02-19-2020', '7.7', 'Na', '0.12', 'Abnormal', 'Infection'],
                ['0734 Urine Analysis', '002', '02-19-2020', '7.7', 'Na', '0.12', 'Normal', 'Diagnosis'],
            ],
        }
    },
    personal:{

    },
}
