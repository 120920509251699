// API controller
    // list of all the API url endpoints with metadata as needed for dynamically composing requests
export const API_CONTROLLER = {
        // sample api base path
        //base_path: 'http://localhost:4000/api/v1/', // local
        //base_path: 'http://54.184.151.252:3000/',  // remote
        base_path: '/api/',  // remote jlp
        //base_path: 'http://spectatorhealth.net/api/',  // remote jlp
        
        // sample api endpoint
        endpoints: {
            
            /* //////////////////////////////////////////////////////////////////
            RESIDENTS 
            */

            // endpoints for resdients
            residents: {
                get_list:{
                    endpoint: 'residentList',
                    method: 'GET',
                },
                add:{
                    endpoint: 'residents',
                    method: 'POST',
                },
                update:{
                    endpoint: 'residents',
                    method: 'PUT',
                }                
            },
            // add allergies here
            residentAlerts: {
                get_list:{
                    endpoint: 'getResidentAlerts',
                    method: 'POST',
                },
                get_by_id:{
                    endpoint: 'getResidentById',
                    method: 'POST',
                },
                add:{
                    endpoint: 'residents',
                    method: 'POST',
                },
                update:{
                    endpoint: 'residents',
                    method: 'PUT',
                }                
            },
            // add allergies here
            residentAllergies: {
                get_list:{
                    endpoint: 'getResidentAllergies',
                    method: 'POST',
                },
                get_by_id:{
                    endpoint: 'getResidentById',
                    method: 'POST',
                },
                add:{
                    endpoint: 'residents',
                    method: 'POST',
                },
                update:{
                    endpoint: 'residents',
                    method: 'PUT',
                }                
            },
            // add allergies here
            residentConditions: {
                get_list:{
                    endpoint: 'getResidentConditions',
                    method: 'POST',
                },
                get_by_id:{
                    endpoint: 'getResidentById',
                    method: 'POST',
                },
                add:{
                    endpoint: 'residents',
                    method: 'POST',
                },
                update:{
                    endpoint: 'residents',
                    method: 'PUT',
                }                
            },
            // add allergies here
            residentNotes: {
                get_list:{
                    endpoint: 'getResidentNotes',
                    method: 'POST',
                },
                get_by_id:{
                    endpoint: 'getResidentById',
                    method: 'POST',
                },
                add:{
                    endpoint: 'residents',
                    method: 'POST',
                },
                update:{
                    endpoint: 'residents',
                    method: 'PUT',
                }                
            },
            // add allergies here
            residentPrescriptions: {
                get_list:{
                    endpoint: 'getResidentPrescriptions',
                    method: 'POST',
                },
                get_by_id:{
                    endpoint: 'getResidentById',
                    method: 'POST',
                },
                add:{
                    endpoint: 'residents',
                    method: 'POST',
                },
                update:{
                    endpoint: 'residents',
                    method: 'PUT',
                }                
            },
            // add doctors here
            residentDoctor: {
                get_list:{
                    endpoint: 'getResidentClinicians',
                    method: 'POST',
                },
                get_by_id:{
                    endpoint: 'getResidentById',
                    method: 'POST',
                },
                add:{
                    endpoint: 'residents',
                    method: 'POST',
                },
                update:{
                    endpoint: 'residents',
                    method: 'PUT',
                }                
            },
            // endpoints for careplan
            careplan: {
                get_list:{
                    endpoint: 'careplan',
                    method: 'GET',
                },
                add:{
                    endpoint: 'careplan',
                    method: 'POST',
                },
                update:{
                    endpoint: 'careplan',
                    method: 'PUT',
                }                
            },       
            // endpoints for vitals
            // provide vitals type record
            vitals: {
                get_list:{
                    endpoint: 'vitalTypes',
                    method: 'GET',
                },
                add:{
                    endpoint: 'vitals',
                    method: 'POST',
                },
                update:{
                    endpoint: 'vitals',
                    method: 'PUT',
                }                
            },
            // provide other endpoints for the resident section and each of the forms within

            /* //////////////////////////////////////////////////////////////////
            // ADMIN 
            */
            /* //use main residents section
            residents: {
                get_list:{
                    endpoint: 'residents',
                    method: 'GET',
                },
                add:{
                    endpoint: 'residents',
                    method: 'POST',
                },
                update:{
                    endpoint: 'residents',
                    method: 'PUT',
                }   
            },
            */
            users: {
                get_list:{
                    endpoint: 'userList',
                    method: 'GET',
                },
                add:{
                    endpoint: 'users',
                    method: 'POST',
                },
                update:{
                    endpoint: 'users',
                    method: 'PUT',
                }   
            },
            clinicians: {
                get_list:{
                    endpoint: 'clinicianList',
                    method: 'GET',
                },
                add:{
                    endpoint: 'clinicians',
                    method: 'POST',
                },
                update:{
                    endpoint: 'clinicians',
                    method: 'PUT',
                }   
            },
            pharmacies: {
                get_list:{
                    endpoint: 'pharmacyList',
                    method: 'GET',
                },
                add:{
                    endpoint: 'pharmacies',
                    method: 'POST',
                },
                update:{
                    endpoint: 'pharmacies',
                    method: 'PUT',
                }   
            },
            prescriptions: {
                get_list:{
                    endpoint: 'getResidentPrescriptions',
                    method: 'POST',
                },
                add:{
                    endpoint: 'prescriptions',
                    method: 'POST',
                },
                update:{
                    endpoint: 'prescriptions',
                    method: 'PUT',
                }   
            },
            labs: {
                get_list:{
                    endpoint: 'getResidentLabs',
                    method: 'POST',
                },
                add:{
                    endpoint: 'labs',
                    method: 'POST',
                },
                update:{
                    endpoint: 'labs',
                    method: 'PUT',
                }   
            },
            activities: {
                get_list:{
                    endpoint: 'activities',
                    method: 'GET',
                },
                add:{
                    endpoint: 'activities',
                    method: 'POST',
                },
                update:{
                    endpoint: 'activities',
                    method: 'PUT',
                }   
            },
            // provide additional metadata about vital types
            /*
            vitals: {
                get_list:{
                    endpoint: 'vitals',
                    method: 'GET',
                },
                add:{
                    endpoint: 'vitals',
                    method: 'POST',
                },
                update:{
                    endpoint: 'vitals',
                    method: 'PUT',
                }   
            },
            */
        } 
    };