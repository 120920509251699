import React from "react";


const adminHome = props => {
    return (
      <div>
        <p>Hello <b>{props.name}</b> from adminHome</p>
      </div>
    );
  };

  export default adminHome;
