import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import {TreeView} from '@material-ui/lab';
import {TreeItem} from '@material-ui/lab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support

import {resident_data} from '../../../../data/resident_data';


/*
Careplan display is a window to display the careplan in a form type view 
- tree view for opening secitons and collapsing
- radio button for showing selected yes or no
- click to switch to print view and load printer function

- each section is a parent in the tree
- each question is a leaf / child
- each question has a true or false

- iterate over sections and questions

- select different carpelan configurations 

- use the tree view component to display the careplan

*/

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 700,
    textAlign:'left',
  },
  section:{
      //padding: '3px 0',
      '& > .MuiTreeItem-content .MuiTreeItem-label':{
        fontWeight:'bold !important',
        fontSize:'1.2em',
    }
  },
  question_row:{
    display:'flex',
    borderBottom: '1px solid #ccc',
},
  question:{
    flexGrow:1,
    margin: 'auto',
},
answer:{
    display:'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  true:{

  },
  false:{

  }
});

export default function CustomizedTreeView() {
  const classes = useStyles();

  // replace this with a call to api via ajax
  const data = resident_data['careplan']['careplan_info'];
  
    const DynamicTree = function(){
        let counter = 0;
        let tree = data.map((section) => {
            //const value = row[column.id];
            let questions = section['data'].map((question)=>{
                return (<StyledTreeItem className={classes.section} nodeID={counter++} label={`${question['question']} + ${question['answer']}`} ></StyledTreeItem>)
            })
            return (
                <StyledTreeItem nodeID={counter++} label={section['title']}>
                {questions}
                </StyledTreeItem>
            );
          })
          return tree;
    }

    const DynamicSections = function(){
        let counter = 0;
        let tree = data.map((section) => {
            
            return (
                <StyledTreeItem nodeID={counter++} label={section['title']}>
            
                </StyledTreeItem>
            );
          })
          return tree;
    }

    function SectionQuestions(data, counter){
        return  data.map((question)=>(
            <StyledTreeItem key={counter} label={`${question.question}`} />
        ))
    }

const [value, setValue] = React.useState('female');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

    let counter = 0;
  return (
    <div >
      {/*<p> {JSON.stringify(resident_data)} </p>*/}
    <TreeView
      className={classes.root}
      defaultExpanded={['1']}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
    >
    {data.map((section, index) => (
        <StyledTreeItem nodeId={counter++} label={section.title} className={classes.section}>
            {section.data.map((question)=>(
            <StyledTreeItem key={counter++} label={
                <div className={classes.question_row}>
                    <div className={classes.question}>{question.question}</div>
                    <div className={classes.answer}>
                    <RadioGroup className={classes.answer} aria-label="gender" name={question.question} value={value} onChange={handleChange}>
                        <FormControlLabel className={classes.true} value="true" control={<Radio />} label="True" />
                        <FormControlLabel className={classes.false} value="false" control={<Radio />} label="False" />
                    </RadioGroup>
                        
                    </div>
                </div>
                } />
            ))}
        </StyledTreeItem>
    ))}    
    </TreeView>
    </div>
  );
}

/**
 

<StyledTreeItem nodeId="1" label="Main">
        <StyledTreeItem nodeId="2" label="Hello" />
        <StyledTreeItem nodeId="3" label="Subtree with children">
          <StyledTreeItem nodeId="6" label="Hello" />
          <StyledTreeItem nodeId="7" label="Sub-subtree with children">
            <StyledTreeItem nodeId="9" label="Child 1" />
            <StyledTreeItem nodeId="10" label="Child 2" />
            <StyledTreeItem nodeId="11" label="Child 3" />
          </StyledTreeItem>
          <StyledTreeItem nodeId="8" label="Hello" />
        </StyledTreeItem>
        <StyledTreeItem nodeId="4" label="World" />
        <StyledTreeItem nodeId="5" label="Something something" />
      </StyledTreeItem>

 */