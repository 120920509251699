import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        background: '#dde6ec !important',
        color: '#999',
        paddingLeft: 10,
        padding: 4,
        '& a:link, a:visited, a:active': {
          color: '#3388cc',
          textDecoration: 'none',
        },
        '& .MuiBreadcrumbs-ol':{
            //background: '#dde6ec !important',
            textTransform: 'capitalize',
        },
    },
}));
    

export  function SimpleBreadcrumbs() {
    const classes = useStyles();
    
    return <Route>
    {({ location }) => {
      const pathnames = location.pathname.split('/').filter(x => x);
      return (
        <div className={classes.breadcrumbs}>
        <Breadcrumbs aria-label="Breadcrumb" separator={<NavigateNextIcon fontSize="small"  />}>
          <RouterLink color="inherit" to="/">
            Home
            </RouterLink>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return last ? (
              <Typography color="textPrimary" key={to}>
                {value}
              </Typography>
            ) : (
                <RouterLink color="inherit" to={to} key={to}>
                  {value}
                </RouterLink>
              );
          })}
        </Breadcrumbs>
        </div>
      );
    }}
  </Route>

}
/*
export default function App() {
  return <div>
    <Router>
      <SimpleBreadcrumbs />

      <Route path='/' exact component={Home}></Route>
      <Route path='/level1' exact component={Level1}></Route>
      <Route path='/level1/level2' exact component={Level2}></Route>

    </Router>
  </div>
}
*/