import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {BrowserRouter as Router , Switch, Route, Link} from 'react-router-dom';


import { Formik, Form } from "formik";
import * as yup from "yup";

let CliniciansSchema = yup.object().shape({
  ClinicianType: yup.string().required("This field is required."),
  FirstName: yup.string().required("This field is required."),
  MiddleName: yup.string().required("This field is required."),
  LastName: yup.string().required("This field is required."),
  Email: yup.string().required("This field is required."),
  ClinicianID:  yup.string().required("This field is required."),
  NPI: yup.string().required("This field is required."),
  DEA_NUMBER: yup.string().required("This field is required."),
  
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    '& h2, h3, h4':{
      textAlign: 'left !important',
      color: '#999 !important',
    }
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  
}));

export default function FormAddOxygen(props) {

    // pass in the params for building a dynamic url, 
    // pass params for form state and modal open close on submit
    // access controller api to execute the ajax call on submit
    const {residentID, section, residentForm, handler} = props;

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="lg">
      <Link to={'/residents/'}  >
            {/* pass in handle close */}
          <Button color="primary">Back to Resident Chart </Button>
      </Link>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Physician Form
        </Typography>
        <Formik
          initialValues={{
            ClinicianType: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            Email: "",
            ClinicianID:  "",
            NPI: "",
            DEA_NUMBER: "",
          }}
          validationSchema={CliniciansSchema}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
              <Grid item xs={12}><h3>Identity</h3></Grid>
                
              <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.FirstName && touched.FirstName}
                    autoComplete="FirstName"
                    name="FirstName"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="FirstName"
                    label="First Name"
                    autoFocus
                    helperText={
                      errors.FirstName && touched.FirstName
                        ? errors.FirstName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.MiddleName && touched.MiddleName}
                    autoComplete="mname"
                    name="MiddleName"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="MiddleName"
                    label="Middle Name"
                    autoFocus
                    helperText={
                      errors.MiddleName && touched.MiddleName
                        ? errors.MiddleName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={errors.LastName && touched.LastName}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="LastName"
                    label="Last Name"
                    name="LastName"
                    autoComplete="lname"
                    helperText={
                      errors.LastName && touched.LastName
                        ? errors.LastName
                        : null
                    }
                  />
                </Grid>
               
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    error={errors.Email && touched.Email}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="Email"
                    label="Email"
                    name="Email"
                    autoComplete="Email"
                    helperText={
                      errors.Email && touched.Email ? errors.Email : null
                    }
                  />
                </Grid>

                <Grid item xs={12}><h3>Clinical Info</h3></Grid>

                <Grid item xs={12} sm={4} md={3}>

                    <InputLabel id="demo-simple-select-label">Clinician Type</InputLabel>
                    <Select
                    variant="outlined"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="userstatus"
                    fullWidth
                    onChange={handleChange}
                    >
                    <MenuItem value={10}>Nurse</MenuItem>
                    <MenuItem value={10}>Doctor</MenuItem>
                    <MenuItem value={10}>Admin</MenuItem>
                    <MenuItem value={10}>CNA</MenuItem>
                    
                    </Select>
                </Grid>


                <Grid item xs={12} sm={4} md={3}>
                  <TextField
                    error={errors.ClinicianID && touched.ClinicianID}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="ClinicianID"
                    label="Clinician ID"
                    name="ClinicianID"
                    autoComplete="ClinicianID"
                    helperText={
                      errors.ClinicianID && touched.ClinicianID
                        ? errors.ClinicianID
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TextField
                    error={errors.NPI && touched.NPI}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="NPI"
                    label="NPI"
                    name="NPI"
                    autoComplete="NPI"
                    helperText={
                      errors.NPI && touched.NPI
                        ? errors.NPI
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <TextField
                    error={errors.DEA_NUMBER && touched.DEA_NUMBER}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="DEA_NUMBER"
                    label="DEA Number"
                    name="DEA_NUMBER"
                    autoComplete="DEA_NUMBER"
                    helperText={
                      errors.DEA_NUMBER && touched.DEA_NUMBER
                        ? errors.DEA_NUMBER
                        : null
                    }
                  />
                </Grid>



              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
