import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import DemoTable from './DemoTable'
import ImgMediaCard from '../../common/FeatureCard'
import Banner from '../../../images/backgrounds/office_lobby.jpg';
import BannerNurseDoctor from '../../../images/graphics/nurse_doctor_ipad.png';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import AccordionActions from '@material-ui/core/AccordionActions';
import WorkflowStepper from '../../home/WorkflowStepper';
import GifScreen from '../../../images/gifs/spectator_health_app_2020_07_12.gif'

import {
  Group, Assignment,  AccountCircle, AssignmentInd, Notes, LocalPharmacy, Colorize, LocalHospital, Fastfood, Hotel, Accessibility
} from '@material-ui/icons';

const admin_menu_data = [
  {
    title: 'Users',
    icon: AccountCircle,
    link:'',
    desc:'Provide platform users access to manage residents and administer the platform.',
    },
    {
      title: 'Residents',
      icon: Group,
      link:'',
      desc:'Add and manage resident information, medical, activity, and personal data.',
      data:[
      ]
      },
      {
    title: 'Clinicians',
    icon: AssignmentInd,
    link:'',
    desc:'Clinicians including Nurses and Doctors are recorded and stored for each resdient\'s list of specialists.',
    },
    {
    title:'Conditions',
    icon: Assignment,
    desc: 'Manage and assign medical conditions and diagnosis to residents.'
    },
    {
    title: 'Diet',
    icon: Fastfood,
    link:'',
    desc:'Clinicians can manage resident dietary intake, food, water, supplements to track consumption.',
    },
    {
    title: 'Activity',
    icon: Hotel,
    link:'',
    desc:'Resident activity such as sleep, bowl movements, and physical activity can be recorded and monitored.',
    },
    {
    title: 'Vitals',
    icon: Accessibility,
    link:'',
    desc:'Resident health vital recordings provide motioning for topics including blood pressure, heart rate, and glucose.',
    },
    {
    title:'Notes',
    icon: Notes,
    desc: 'Allow nurses and doctors to manage and author notes for residents.'
    },
    {
    title: 'Pharmacies',
    icon: LocalPharmacy,
    link:'',
    desc:'Pharmacy records allows adminstration of medication fulfilment providers and streamline the prescription process.',
    },
    {
    title: 'Prescriptions',
    icon: LocalHospital,
    link:'',
    desc:'Medication prescriptions are tracked for each resident with order / reorder actions available fromt he dashboard.',
    },
    {
    title: 'Labs',
    icon: Colorize,
    link:'',
    desc:'Resident labs are managed, to track tests and provide result information.',
    },
]

const cards_data = [
  {
    title: 'Residents',
    image_path: 'smart_health.png',
    text: 'Manage residents in assisted living w advanced care inforamtion. Residents total information view w realtime tracking.',
    link: '/residents',
  },
  {
    title: 'Facilities',
    image_path: 'living_facility.jpg',
    text: 'Supervise building utilities and other information for care facility to ensure all topics are to specification and running.',
    link: '/facility',
  },
  {
    title: 'Admin',
    image_path: 'health_data_network.jpg',
    text: 'Customize the platform to suite your specific needs, by modifying and extedning the platform with information from your organization.',
    link: '/admin',
  },
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    textAlign: 'left',
    '& header': {
      background: '#ffffff',
      background: '-moz-linear-gradient(top,  #ffffff 0%, #e6eff4 100%)',
      background: '-webkit-linear-gradient(top,  #ffffff 0%,#e6eff4 100%)',
      background: 'linear-gradient(to bottom,  #ffffff 0%,#e6eff4 100%)',
      filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#e6eff4",GradientType=0 )',
      color: '#8795a5',
    },
  },
  bar: {
    background: '#5592de',
  },
  tabpanel:{
      '& h2':{
        color: '#436f96',
        textAlign:'left',
      }
  },
  app_features:{
    padding: 10,
    margin: 10,
    backgroundColor: '#eef2f5',
  },
  intro:{
    padding: '20px 40px',
    color: '#8795a5',
    '& p': {
      padding: '10px 0',
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
    }
  },
  featureIcon:{
    fill: '#63a2e4',
    width: 50,
    height: 'auto',
    display:'block',
    margin: 'auto',
  },
  title:{
    borderBottom: '1px solid #999',
    margin: '4px 0',
    paddingBottom: 4,
  },
  desc:{
    marginTop: 4,
    color: '#5c788f',
    lineHeight: 1.6,
    padding: '0 4px',
  },
  feature_item:{
    borderRadius:4,
    '&:hover':{
      background:'#fff',
    },
  },
  banner:{
    width: '100%',
    height: 200,
    background:`url(${Banner}) ` ,
    backgroundSize: 'cover',
    backgroundPosition: ' center 55%',
  },

  heading1: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading1: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  accordionRoot: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  gifAnime:{
    maxWidth: '90%',
    height: 'auto',
    borderRadius: 4,
    margin: '10px auto',
    display:'block',

  },
}));

export default function SupportSection() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
/*
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
*/
  // card set
  const cardset = cards_data.map((item, index) =>
    //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
    <Grid item xs={12} md={4}>
      <ImgMediaCard {...item} />
    </Grid>
    )


    // icon set 
    const iconset = admin_menu_data.map((item, index) =>{
    //<Link to={'/admin/'+item.link}> <Tab key={index} value={index} label={item.title} {...a11yProps(index) }>{item.title}</Tab> </Link>
    let Icon = item.icon;
    // return the icon and description , link
    return(<Grid item xs={12} md={4} lg={3} className={classes.feature_item}>
        <Icon className={classes.featureIcon}/>
        <h4 className={classes.title}>{item.title}</h4>
        <p className={classes.desc}>{item.desc}</p>
      </Grid>)
    })


  return (
    <div className={classes.root}>
      <div className={classes.banner} />
      <div className={classes.intro}>
        <h3>Support Center</h3>
        <p>We are here to help you with any issues that come up.</p>
      </div>
      {
      <Paper variant="outlined" className={classes.app_features} >
      <Grid container spacing={2}>
      
      <Grid item xs={12} md={4} lg={4}>
      <h3>
          Quick Start FAQ
        </h3>
        <p>
          Spectator health app provides an intuitive interface for new and experienced clinicians alike to have ease of use when managing many residents and associated information.
        </p>
        <p>
          Review our knowledge base of frequent questions and solutions, providing screenshots and links to help you get started fast and remain oriented while using the app.
        </p>
        <p>
          View our library of screen capture tutorial clips to watch guided tours of various sections and workflow sequences common to users.
        </p>
        <img src={GifScreen} className={classes.gifAnime}/>
        <p>
          This screen capture tours the whole application, from resident section to admin, and demonstrates creation of a new resident, along with resident chart lookup and doctors details.
        </p>
        </Grid>
       
      <Grid item xs={12} md={8} lg={8} >
      <h3>
          Get Started
        </h3>
      <div className={classes.root}>
     
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading1}>Sign In</Typography>
          <Typography className={classes.secondaryHeading1}>Create a user account and sign in</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Go to the signup or login page and enter credentials. Click here.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading1}>View My Residents</Typography>
          <Typography className={classes.secondaryHeading1}>
            Provides list of residents to select and browse details.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          View list of residents and alerts, select and browse resident data.
            
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading1}>Create new resident</Typography>
          <Typography className={classes.secondaryHeading1}>
            Add a resident to the sytem for tracking details.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Go to the admin section and click on the add resident form button. click here.
          </Typography>
        </AccordionDetails>
      </Accordion>
     
    </div>
    <h3>
          Manage Residents
        </h3>

    <div className={classes.accordionRoot}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Resident List Dashboard</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>Resident summary list with alerts</Typography>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          
          <div className={classes.column} >
          Go to the admin section and click on the add resident form button. click here.
          </div>
          <div className={classes.column}>
            {/*
            <Chip label="Barbados" onDelete={() => {}} />
              */}
              (Thumbnail)
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Select to visit Resident List page
              <br />
              <a href="#secondary-heading-and-columns" className={classes.link}>
                Visit Residents List
              </a>
            </Typography>
          </div>
        </AccordionDetails>
        
        {/*<Divider />
        <AccordionActions>
          <Button size="small">Cancel</Button>
          <Button size="small" color="primary">
            Save
          </Button>
        </AccordionActions>*/}
        
      </Accordion>
        
        <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel2c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Resident Chart</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>Explore selected resident chart information</Typography>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          
          <div className={classes.column} >
          Go to the resident list section and select a resident from the list, which will automatically bring you to that residents' chart page.
          </div>
          <div className={classes.column}>
            {/*
            <Chip label="Barbados" onDelete={() => {}} />
              */}
              (Thumbnail)
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Select to visit Resident Chart 
              <br />
              <a href="#secondary-heading-and-columns" className={classes.link}>
                Visit Resident chart
              </a>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel2c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Resident Medication</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>Explore current medication for resident, reorder, or order new prescriptions.</Typography>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          
          <div className={classes.column} >
          Select a target resident, got to either chart page and medication sub-panel, or resident medication page, to view active current prescriptions and to order or reorder via the web form.
          </div>
          <div className={classes.column}>
            {/*
            <Chip label="Barbados" onDelete={() => {}} />
              */}
              (Thumbnail)
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Select to visit Resident Medications 
              <br />
              <a href="#secondary-heading-and-columns" className={classes.link}>
                Visit Resident Medications
              </a>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
    <h3>
          Administer Platform
        </h3>
        <div className={classes.accordionRoot}>


        <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Manage Prescriptions</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>Track all prescriptions</Typography>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          
          <div className={classes.column} >
          Go to the admin section and click on the add resident form button. click here.
          </div>
          <div className={classes.column}>
            {/*
            <Chip label="Barbados" onDelete={() => {}} />
              */}
              (Thumbnail)
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Select to visit Resident List page
              <br />
              <a href="#secondary-heading-and-columns" className={classes.link}>
                Visit Residents List
              </a>
            </Typography>
          </div>
        </AccordionDetails>
        
        {/*<Divider />
        <AccordionActions>
          <Button size="small">Cancel</Button>
          <Button size="small" color="primary">
            Save
          </Button>
        </AccordionActions>*/}
        
      </Accordion>
        
        <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel2c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Create and Manage Pharmacies</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>Add new pharmacies for prescription fullfillment.</Typography>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          
          <div className={classes.column} >
          Go to the resident list section and select a resident from the list, which will automatically bring you to that residents' chart page.
          </div>
          <div className={classes.column}>
            {/*
            <Chip label="Barbados" onDelete={() => {}} />
              */}
              (Thumbnail)
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Select to visit Resident Chart 
              <br />
              <a href="#secondary-heading-and-columns" className={classes.link}>
                Visit Resident chart
              </a>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel2c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Manage CLinicians / Doctors</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>Create new doctors or explore clinician dataset.</Typography>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          
          <div className={classes.column} >
          Select a target resident, got to either chart page and medication sub-panel, or resident medication page, to view active current prescriptions and to order or reorder via the web form.
          </div>
          <div className={classes.column}>
            {/*
            <Chip label="Barbados" onDelete={() => {}} />
              */}
              (Thumbnail)
          </div>
          <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
              Select to visit Resident Medications 
              <br />
              <a href="#secondary-heading-and-columns" className={classes.link}>
                Visit Resident Medications
              </a>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      </div>
        </Grid>

     

        </Grid>
     </Paper>
     }
    </div>
  );
}