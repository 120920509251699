/*
Diet table
Date time
food %, food total, fluid mg, supplement mg, actions

diet charts

- food 
- fluid 
- supplements
*/
/*

This file will receive a resident ID and load all of the vitals from API

Sub sections

1
- vitals summary : table of vitals list
 -> select vitals type from list
2
- vital plot and hist : table of history of records with charting
 -> reset  chart, edit record, add record

*/


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from "@material-ui/core/Grid";

import ResidentDietCharts from './resident_diet/ResidentDietCharts'
import ResidentDietTable from './resident_diet/ResidentDietTable'

/*

This file will receive a resident ID and load all of the chart info from API

Sub sections

1
- active conditions
 -> view all conditions
2
- dagnosis
 -> view all
3
- alergies
 -> view all
4
- notes
 -> view all
5
- active prescriptions
 -> view all
6
- doctors
 -> view all
7
- alerts
 -> view all

*/

const useStyles = makeStyles({
  root: {
    //margin: 'auto',
    '& a':{
        textDecoration: 'none',
        color: '#FFF',
    }
  },
  buttonStyle:{
      textDecoration:'none',
      '& button':{
        boxShadow: '2px',
        border:'none',
        backgroundColor: '#64b7ed',
      },
      '& button > span':{
          color: '#fff',
      }
  }
});

export default function ResidentVitals(props) {
  const classes = useStyles();
  const {residentID,handleOpen} = props;
  return (
    <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} md={12}>
            <ResidentDietTable/>
            < Link  className={classes.buttonStyle} to={`/residents/${residentID}/diet/AddDietRecord/form`}  >
                    <Button onClick={(e) => props.handleOpen(e,'AddDietRecord')}  variant="contained"  size="small" > 
                    Add Diet Record
                    </Button>
                </Link>
        </Grid>
        <Grid item xs={12} md={12}>
            <ResidentDietCharts/>
        </Grid>
    </Grid>
  );
}