export const app_data = {
    activities: [
        {ActivityType: "ACT001", ActivityDesc: "Sitting", icon: "icon-Sitting"},
        {ActivityType: "ACT002", ActivityDesc: "Walking", icon: "icon-Walking"},
        {ActivityType: "ACT003", ActivityDesc: "Sleeping", icon: "icon-Sleeping"},
        {ActivityType: "ACT004", ActivityDesc: "Resting", icon: "icon-Resting"},
        {ActivityType: "ACT005", ActivityDesc: "Bathroom", icon: "icon-Bathroom"},
        {ActivityType: "ACT007", ActivityDesc: "Eating", icon: "icon-Eating"},
        {ActivityType: "ACT008", ActivityDesc: "Leisure", icon: "icon-Leisure"},
        {ActivityType: "ACT009", ActivityDesc: "Wheelchair", icon: "icon-Wheelchair"},
    ],
    clinicians: [
        {key: "CLN001", ClinicianId: "CLN001", ClinicianType: "MD", NPI: "1234567", DEA_NUMBER: "", },
        {key: "CLN002", ClinicianId: "CLN002", ClinicianType: "HN", NPI: "7890123", DEA_NUMBER: "", },
        {key: "CLN003", ClinicianId: "CLN003", ClinicianType: "MT", NPI: "9998725", DEA_NUMBER: "", },
        {key: "CLN004", ClinicianId: "CLN004", ClinicianType: "CNA", NPI: "5552121", DEA_NUMBER: "", },
        {key: "CLN005", ClinicianId: "CLN005", ClinicianType: "CNA", NPI: "6669200", DEA_NUMBER: "", },
    ],
    labs: [
        {ResidentId: "R100", OrderingClinicianId: "CLN001", LabOrderNo: "1065", LOINC: "2093-3", LOINDesc: "Cholesterol,Total", },
        {ResidentId: "R100", OrderingClinicianId: "CLN001", LabOrderNo: "1453", LOINC: "4548-4", LOINDesc: "Hemaglobin A1C", },
        {ResidentId: "R100", OrderingClinicianId: "CLN001", LabOrderNo: "505321", LOINC: "65759-3", LOINDesc: "Lymphocyte Act. Profile", },
        {ResidentId: "R100", OrderingClinicianId: "CLN001", LabOrderNo: "3004", LOINC: "2160-0", LOINDesc: "Creatinine clearance", },
        {ResidentId: "R100", OrderingClinicianId: "CLN001", LabOrderNo: "1172", LOINC: "2571-8", LOINDesc: "Triglycerides", },
        {ResidentId: "R100", OrderingClinicianId: "CLN001", LabOrderNo: "1032", LOINC: "2345-7", LOINDesc: "Glucose", },
    ],
    pharmacies: [
        {name: "Wallgreens", group: "group 1", contact: "bob smith", status: "active"},
        {name: "CVS", group: "group 1", contact: "Jennifer Lao", status: "active"},
        {name: "Wegmans", group: "group 2", contact: "Vital Manerah", status: "active"},
    ],
    prescriptions: [
        {ResidentId: "R001", ClinicianId: "CLN001", NDC: "65162110105", MEDID: 250621, MedDesc: "ibuprofen 400 mg", },
        {ResidentId: "R001", ClinicianId: "CLN001", NDC: "72124000201", MEDID: 574777, MedDesc: "Tudorza Pressair 400 mcg/actuation breath activated", },
        {ResidentId: "R001", ClinicianId: "CLN001", NDC: "16714068401", MEDID: 209275, MedDesc: "simvaststain 40 mg tablet", },
        {ResidentId: "R001", ClinicianId: "CLN001", NDC: "59212056210", MEDID: 163111, MedDesc: "Plaquenil 200 mg tablet", },
        {ResidentId: "R001", ClinicianId: "CLN001", NDC: "63629147202", MEDID: 227625, MedDesc: "methotrexate sodium 2.5 mg tablet", },
    ],
    residents: [
        {key: "R100", ResidentId: "R100", FirstName: "Meena", MiddleName: "", LastName: "Sugavanam",},
        {key: "R101", ResidentId: "R101", FirstName: "Imogene", MiddleName: "Kay", LastName: "Coca",},
        {key: "R102", ResidentId: "R102", FirstName: "Emily", MiddleName: "Susan", LastName: "Schroeder",},
        {key: "R103", ResidentId: "R103", FirstName: "Smrithi ", MiddleName: "", LastName: "Mandhana",},
        {key: "R104", ResidentId: "R104", FirstName: "Gilda", MiddleName: "Helen", LastName: "Radner",},
        {key: "R105", ResidentId: "R105", FirstName: "Janet ", MiddleName: "Edith", LastName: "Marigold",},
        {key: "R106", ResidentId: "R106", FirstName: "Erika", MiddleName: "Martha", LastName: "De Jeong",},
    ],
    users: [
        {username: "Raghu", name: "Raghu", user_type: "test", roles: "admin", created_date: "2020-04-01",},
        {username: "Ajit", name: "Ajit", user_type: "test", roles: "admin", created_date: "2020-04-01",},
        {username: "JPBoston", name: "Josh", user_type: "test", roles: "admin", created_date: "2020-04-01",},
        {username: "Samira", name: "Samira", user_type: "CNA", roles: "CNA", created_date: "2020-04-01",},
    ],
    vitals: [
        {VitalType: "VT100", VitalDesc: "HeartRate", icon: "icon-HeartRate"},
        {VitalType: "VT200", VitalDesc: "Blood Pressure", icon: "icon-BloodPressure"},
        {VitalType: "VT300", VitalDesc: "Blood Oxygen Level", icon: "icon-BloodOxygen"},
        {VitalType: "VT400", VitalDesc: "Respiration Rate", icon: "icon-RespirationRate"},
        {VitalType: "VT500", VitalDesc: "Blood Sugar", icon: "icon-BloodSugar"},
    ],
    notes: [
        {
            "note": "Discomfort from lack of BM - food intake down",
            "priority": "critical",
            "category": "doctor",
            "file attach": [
              "file1.png"
            ],
            "photos": [
              "image1.png",
              "image2.png"
            ],
            "datetime": "10:34am 03-20-2020",
            "created by": 100378021
          },
          {
            "note": "Applied ice to knee swelling",
            "priority": "normal",
            "category": "general",
            "file attach": [],
            "photos": [],
            "datetime": "10:34am 03-20-2020",
            "created by": 100378021
          },
          {
            "note": "Lorem ipsum..",
            "priority": "normal",
            "category": "reminder",
            "file attach": [],
            "photos": [],
            "datetime": "10:34am 03-20-2020",
            "created by": 100378021
          },
          {
            "note": "Lorem Ipsum..",
            "priority": "normal",
            "category": "checkup",
            "file attach": [
              "file1.png"
            ],
            "photos": [
              "image1.png"
            ],
            "datetime": "10:34am 03-20-2020",
            "created by": 100378021
          }
      
    ],
    residentData:{
        conditions:[
            {
                "Condition": "knee swollen from bang on desk",
                "Date Created": "08:36 08-23-2020",
                "Created By": 100378021
              },
              {
                "Condition": "Constipation for 2 days",
                "Date Created": "12:38 08-16-2020",
                "Created By": 100378021
              },
              {
                "Condition": "Poor eye sight",
                "Date Created": "08:36 08-03-2020",
                "Created By": 100378021
              }
        ],
        diagnosis:[],
        allergies:[],
        notes:[
            {
                "Note": "Loem Ipsum..",
                "Created By": 100378021,
                "Priority": 1,
                "Date/Time": "14:28 03-22-2020"
              },
              {
                "Note": "Loem Ipsum..",
                "Created By": 100378021,
                "Priority": 1,
                "Date/Time": "14:28 03-22-2020"
              },
              {
                "Note": "Loem Ipsum..",
                "Created By": 100378021,
                "Priority": 1,
                "Date/Time": "14:28 03-22-2020"
              },
              {
                "Note": "Loem Ipsum..",
                "Created By": 100378021,
                "Priority": 1,
                "Date/Time": "14:28 03-22-2020"
              }
        ],
        prescriptions:[
            {
                "Drug Name": "Vicodin",
                "Date Ordered": "02-19-2020",
                "Day's Supply": "65mgs",
                "Regimen": "Take 2 pills w water in morning"
              },
              {
                "Drug Name": "Simvastatin",
                "Date Ordered": "02-19-2020",
                "Day's Supply": "65mgs",
                "Regimen": "Take 2 pills w water in morning"
              },
              {
                "Drug Name": "Lisinopril",
                "Date Ordered": "02-19-2020",
                "Day's Supply": "65mgs",
                "Regimen": "Take 2 pills w water in morning"
              }
        ],
        doctors:[
            {
                "Name": "Dr. Paula Eisenhart, MD",
                "Type": "Primary Care physician, internal medicine",
                "Hostpital": "Scripss Green Hospital",
                "Phone1": "(760)-321-8782",
                "Phone2": "(760)-321-8888",
                "Address": "7565 Mission Valley Rd, Ste 200|San Diego, CA|92108"
              },
              {
                "Name": "Dr. Sanjay Gupta, MD",
                "Type": "Surgeon, internal medicine",
                "Hostpital": "Scripss Green Hospital",
                "Phone1": "(760)-321-8782",
                "Phone2": "(760)-321-8888",
                "Address": "7565 Mission Valley Rd, Ste 200|San Diego, CA|92108"
              }          
        ],
        alerts:[
            {
                "Alert Type": "Fall Alert",
                "Alert Time": "1:18pm",
                "Date": "yesterday"
              },
              {
                "Alert Type": "Standing Up",
                "Alert Time": "11:47am",
                "Date": "4 days ago"
              },
              {
                "Alert Type": "Left Building",
                "Alert Time": "11:16am",
                "Date": "last week"
              },
              {
                "Alert Type": "Left Room",
                "Alert Time": "10:28am",
                "Date": "last month"
              },
              {
                "Alert Type": "Help Call",
                "Alert Time": "9:02am",
                "Date": "last year"
              }
        ],
        careplan:{},
        vital_types:[
            {"icon": "hearticon", "title": "heart rate", "reading": '72', "measurement": "bpm avg", "record_time":"6:41 am"},
            {"icon": "bloodpressure", "title": "blood pressure", "reading": '121/91', "measurement": "mmHg", "record_time":"6:42 am"},
            {"icon": "oxygen", "title": "oxygen saturation", "reading": '94.6', "measurement": "%", "record_time":"6:30 am"},
            {"icon": "respiration", "title": "respiration", "reading": '13', "measurement": "rpm", "record_time":"6:58 am"},
            {"icon": "glucose", "title": "glucose", "reading": '8.2', "measurement": "mmoI/L", "record_time":"6:47 am"},

        ],
        vital_data:[
            {
                "vital": "heart rate",
                "bpm": 98,
                "datetime": "12:00 02-04-2020"
              },
              {
                "vital": "heart rate",
                "bpm": 101,
                "datetime": "12:00 02-04-2020"
              },
              {
                "vital": "heart rate",
                "bpm": 89,
                "datetime": "12:00 02-04-2020"
              }
        ],
        activity_types:[
            {
                "Activity": "bathroom",
                "Value": "6",
                "Measure": "average visit",
                "Type": "system",
                "Date": "Thursday, Jan 23"
              },
              {
                "Activity": "Sleep",
                "Value": "11:35",
                "Measure": "min",
                "Type": "system",
                "Date": "Thursday, Jan 23"
              },
              {
                "Activity": "meals",
                "Value": "4",
                "Measure": "hours",
                "Type": "system",
                "Date": "Thursday, Jan 23"
              },
              {
                "Activity": "physical activity",
                "Value": "37",
                "Measure": "steps",
                "Type": "system",
                "Date": "Thursday, Jan 23"
              }
        ],
        activity_data:[
            {
                "visits": 6,
                "quality": "soft",
                "date": "02-02-2020"
              },
              {
                "visits": 2,
                "quality": "medium",
                "date": "02-02-2020"
              },
              {
                "visits": 4,
                "quality": "medium",
                "date": "02-02-2020"
              },
              {
                "visits": 3,
                "quality": "hard",
                "date": "02-02-2020"
              }
        ],
        diet: [
            {
                "date time recorded": 60,
                "food % total": 900,
                "fluid mg": 200
              },
              {
                "date time recorded": 50,
                "food % total": 1200,
                "fluid mg": 150
              },
              {
                "date time recorded": 80,
                "food % total": 700,
                "fluid mg": 0
              },
              {
                "date time recorded": 40,
                "food % total": 900,
                "fluid mg": 0
              },
              {
                "date time recorded": 60,
                "food % total": 860,
                "fluid mg": 200
              }
        ],
        meds:[
            {
                "drug name": "Vicodin 10 mg",
                "date ordered": "02-19-2020",
                "date filled": "02-19-2020",
                "day's supply": "30mgs",
                "Quantity Filled": "50x 30mgs",
                "Regimen": "Take 2 pills w water in morning 65 mg",
                "Day's Left": "7 days"
              },
              {
                "drug name": "Simvastatin",
                "date ordered": "02-19-2020",
                "date filled": "02-19-2020",
                "day's supply": "30mgs",
                "Quantity Filled": "50x 30mgs",
                "Regimen": "Take 2 pills w water in morning 65 mg",
                "Day's Left": "7 days"
              },
              {
                "drug name": "Lisinopril",
                "date ordered": "02-19-2020",
                "date filled": "02-19-2020",
                "day's supply": "30mgs",
                "Quantity Filled": "50x 30mgs",
                "Regimen": "Take 2 pills w water in morning 65 mg",
                "Day's Left": "7 days"
              },
              {
                "drug name": "Levothyroxine",
                "date ordered": "02-19-2020",
                "date filled": "02-19-2020",
                "day's supply": "30mgs",
                "Quantity Filled": "50x 30mgs",
                "Regimen": "Take 2 pills w water in morning 65 mg",
                "Day's Left": "7 days"
              },
              {
                "drug name": "Azithromycin",
                "date ordered": "02-19-2020",
                "date filled": "02-19-2020",
                "day's supply": "30mgs",
                "Quantity Filled": "50x 30mgs",
                "Regimen": "Take 2 pills w water in morning 65 mg",
                "Day's Left": "7 days"
              },
              {
                "drug name": "Metformin",
                "date ordered": "02-19-2020",
                "date filled": "02-19-2020",
                "day's supply": "30mgs",
                "Quantity Filled": "50x 30mgs",
                "Regimen": "Take 2 pills w water in morning 65 mg",
                "Day's Left": "7 days"
              },
              {
                "drug name": "Lipitor",
                "date ordered": "02-19-2020",
                "date filled": "02-19-2020",
                "day's supply": "30mgs",
                "Quantity Filled": "50x 30mgs",
                "Regimen": "Take 2 pills w water in morning 65 mg",
                "Day's Left": "7 days"
              }
        ],
        labs:[
            {
                "LOINC": "1234 Blood Analysis",
                "lab id": "002",
                "date of service": "02-19-2020",
                "lab results": "7.7",
                "units": "Na",
                "range": "0.12",
                "status": "Normal",
                "typetag": "Blood"
              },
              {
                "LOINC": "0282 Stool Analysis",
                "lab id": "002",
                "date of service": "02-19-2020",
                "lab results": "7.7",
                "units": "Na",
                "range": "0.12",
                "status": "Abnormal",
                "typetag": "Infection"
              },
              {
                "LOINC": "0734 Urine Analysis",
                "lab id": "002",
                "date of service": "02-19-2020",
                "lab results": "7.7",
                "units": "Na",
                "range": "0.12",
                "status": "Normal",
                "typetag": "Diagnosis"
              }
        ],
        personal:[],
    }
}
