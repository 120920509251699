import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MUIDataTable from "mui-datatables";
import {BrowserRouter as Router , Switch, Route, Link, withRouter} from 'react-router-dom';
import {LiveHelp,TransferWithinAStation,SportsHandball,AccessibilityNew,SmsFailed,DirectionsRun} from '@material-ui/icons/'
import Paper  from '@material-ui/core/Paper';
import Plot from 'react-plotly.js';
import Grid from "@material-ui/core/Grid";
import  { Fragment } from 'react'

import {resident_data} from '../../../../data/resident_data';

/*
Resdient Notes has a table of the resident notes summary
*/

// style the component
const useStyles = makeStyles({
  root: {
    margin: 'auto',
    padding: 10,
    '& a':{
        textDecoration: 'none',
        color: '#000',
    },
    '& tr:hover .AlertIconWrapper':{
        color: '#f00',
        borderRadius:6,
        border:'1px solid #c00',
        backgroundColor: 'rgba(230,200,200, .3)',
    },
    '& .svg-container':{
        margin: '0 auto 10px !important',
    },
    '& > div > div':{
        margin: 'auto',
    }
  },
  AlertIconWrapper:{
    padding: '2px 5px',
    border:'1px solid #ccc',
    borderRadius:6,
    
  },
  AlertIcon:{
    fill: '#c00',
    verticalAlign: 'middle',
    minWidth: 36,
    minHeight: 36,
    width: 36,
    height: 36,
    padding: 2,
    marginLeft: 8,
    marginRight: 10,
  },
  AlertIconTitle:{
    fontWeight: 'bold',
    color: '#c00',
    display: 'inline-block',
    margin: '0 3px',
    verticalAlign: 'middle',
    fontSize: '1.2em',
  }
});

// provide table interface for active alerts
export default function ResidentVitalsDetails(props) {
  const classes = useStyles();
  const selectedVital = {props};
  

  function ConvertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr)
  
    let result =  array.map(it => {
      return Object.values(it)
    })

	return result
  }
  
  // load the data from the chart sub section
  // replace this with a call to api via ajax
  const data = resident_data.vitals.vital_details.table[props.selectedVital].data;
  
  

  // list of columns for datatable
   const columns = resident_data.vitals.vital_details.table[props.selectedVital].headers;

    // set options for datatable
   const options = {
    filterType: 'checkbox',
  };

  // details section
  const DetailsHeartRate = () =>{
        return (
          <Fragment>
            <Grid item xs={12} md={6}>
              Heart Rate 98 BPM
            </Grid>
            <Grid item xs={12} md={6}>
              <div>Latest: 98bpm</div>
              <div>Time Taken: 12:00am</div>
              <div>Date: 02-04-2020</div>
            </Grid>
        </Fragment>
        )
      }
  // details section
  const DetailsBloodPressure = () =>{
    return (
      <Fragment>
        <Grid item xs={12} md={6}>
          Blood Pressure 90/60mmHg
        </Grid>
        <Grid item xs={12} md={6}>
          <div>Latest: 90/60mmHg</div>
          <div>Time Taken: 12:00am</div>
          <div>Date: 02-04-2020</div>
        </Grid>
    </Fragment>
    )
      }
  // details section
  const DetailsOxygen = () =>{
    return (
      <Fragment>
        <Grid item xs={12} md={6}>
          Blood Oxygen Level 80 mm Hg
        </Grid>
        <Grid item xs={12} md={6}>
          <div>Latest: 80 mm Hg</div>
          <div>Time Taken: 12:00am</div>
          <div>Date: 02-04-2020</div>
        </Grid>
    </Fragment>
    )
      }
  // details section
  const DetailsRespiration = () =>{
    return (
      <Fragment>
        <Grid item xs={12} md={6}>
          Respiration Rate 16 breaths per minute.
        </Grid>
        <Grid item xs={12} md={6}>
          <div>Latest: 16bpm</div>
          <div>Time Taken: 12:00am</div>
          <div>Date: 02-04-2020</div>
        </Grid>
    </Fragment>
    )
      }
  // details section
  const DetailsGlucose = () =>{
    return (
      <Fragment>
        <Grid item xs={12} md={6}>
        Glucose 140 mg/dL (7.8 mmol/L)
        </Grid>
        <Grid item xs={12} md={6}>
          <div>Latest: 140 mg/dL (7.8 mmol/L)</div>
          <div>Time Taken: 12:00am</div>
          <div>Date: 02-04-2020</div>
        </Grid>
    </Fragment>
    )
      }
    
      const detail_component_map = {
        'heart rate': DetailsHeartRate,
        'blood pressure': DetailsBloodPressure,
        'oxygen saturation': DetailsOxygen,
        'respiration': DetailsRespiration,
        'glucose': DetailsGlucose,
      }
    
    const DetailComp =  detail_component_map[props.selectedVital]

  // render the component
  return (
   <div className={classes.root}>

    <h2>Vitals Details</h2>
    <hr/>
    <Grid container spacing={2} >
      <DetailComp/>
    </Grid>
    <hr/>
    <Plot style={{margin:'auto'}}
        data={[
          {
            x: [1, 2, 3,4,5],
            y: [2, 6, 3,2,4],
            type: 'scatter',
            mode: 'lines+markers',
            marker: {color: '#6c7'},
          },
          {type: 'bar', x: [1, 2, 3,4,5], y: [2, 6, 3,2,4], marker: {color: '#8ac'}},
        ]}
        layout={ {width: 650, height: 300} }
      />

    <MUIDataTable
    title={"Vitals Details"}
    data={data}
    columns={columns}
    options={options}
    /> 
   </div>
  );
}



/*

   // format name column data
  function ResidentName(value){
    
  let result =( <Link to='/residents/chart' > <h3>{value}</h3> </Link> );

    return result
  }

  // provide dynamic icon for placement based on icon name
   function DynamicIcon(icon){
    const icons = {
      'Leave Room': TransferWithinAStation,
      'Leave Building': DirectionsRun,
      'Call Help': SmsFailed,
      'Standing Up': AccessibilityNew,
      'Fall': SportsHandball,
    };

    //let  DynamicIcon = icons[{icon}]
    let DynamicIcon = icons[icon];
    let result = ( 
        <React.Fragment>
            <Link to='/residents/chart'>
            <div className={classes.AlertIconWrapper + ' AlertIconWrapper '}>
                <DynamicIcon className={classes.AlertIcon}/>
                <span className={classes.AlertIconTitle}>
                    {icon}
                </span>
            </div>
            </Link>
        </React.Fragment>
    )
    return result
  }

*/

